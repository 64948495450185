import React, { Component } from 'react'
import { View, StyleSheet, Image } from 'react-native'

import Colors from '../../Themes/Colors'
import arrows from '../../Images/icon-arrows.png'
import whiteArrows from '../../Images/icon-white-arrows.png'

class Select extends Component {
  render () {
    return (
      <View style={[styles.selectContainer, this.props.styleContainer]}>
        <select
          name={this.props.name}
          className={`custom-select${this.props.white ? ' white' : ''}`}
          onChange={this.props.onChange}
          value={this.props.value}
          disabled={this.props.disabled}
        >
          {this.props.defaultText && <option value="">{this.props.defaultText}</option>}
          {this.props.options &&
          this.props.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.text}
              </option>
            )
          })}
        </select>
        <Image source={this.props.white ? whiteArrows : arrows} resizeMode="contain" style={styles.arrows}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  selectContainer: {
    marginBottom: 15,
    borderWidth: 1,
    borderColor: Colors.purple,
    position: 'relative'
  },
  arrows: {
    position: 'absolute',
    right: 6,
    top: 6,
    width: 10,
    height: 22
  }
})

export default Select
