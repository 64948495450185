import axios from "axios";
import { auth } from "../Model";

let endpointsInstance = null;

class Endpoints {
  constructor() {
    if (!endpointsInstance) {
      endpointsInstance = this;
    }

    this.api = axios.create({
      baseURL: "https://crfnbuzy62.execute-api.us-west-2.amazonaws.com",
      headers: {
        "Content-Type": "application/json"
      }
    });

    return endpointsInstance;
  }

  getKeywords = () =>
    this.api.post("/production/topics", {
      Authorization: `${auth.getToken()}`
    });

  getAlerts = (topic) =>
    this.api.post("/production/alerts", {
      Authorization: `${auth.getToken()}`,
      topic
    });

  getAlertsByArgs = (payload) => this.api.post("/production/alerts", payload);

  giveFeedback = (payload) =>
    this.api.post("/production/feedback", {
      Authorization: `${auth.getToken()}`,
      ...payload
    });

  getFeeds = (topic) =>
    this.api.post("/production/feeds", {
      Authorization: `${auth.getToken()}`,
      topic
    });

  getFeedsByArgs = (payload) => this.api.post("/production/feeds", payload);

  getReports = (topic) =>
    this.api.post("/production/reports", {
      Authorization: `${auth.getToken()}`,
      topic
    });
  getReportsByArgs = (payload) => this.api.post("/production/reports", payload);

  getInsights = (topic) =>
    this.api.post("/production/insights", {
      Authorization: `${auth.getToken()}`,
      topic
    });

  getInsightsByArgs = (payload) =>
    this.api.post("/production/insights", payload);

  registerUpload = (payload) => this.api.post("/production/uploads", payload);

  getAuthorization = () =>
    this.api
      .post("/production/topics", { Authorization: `${auth.getToken()}` })
      .then(({ data }) => {
        return data;
      });
}

export default Endpoints;
