import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  Image
} from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import Loading from "../../Components/Loading";
import Confirm from "../../Components/Confirm";
import Button from "../../Components/Button";
import Select from "../../Components/Select";
import Checkbox from "../../Components/Checkbox";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import close from "../../Images/icon-close.png";

import { operation, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class Keywords extends Component {
  clear = () => {};
  onConfirm = () => {
    operation.delete();
    operation.showConfirm = false;
  };
  showConfirm = (index) => {
    operation.id = index;
    operation.showConfirm = true;
  };
  onCancel = () => {
    operation.id = 0;
    operation.showConfirm = false;
  };

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.keywordsContainer}>
        <View style={[styles.step, styles.stepOne]}>
          <View style={[styles.content, styles.space]}>
            <Button
              upper
              title={langProps.clear || ""}
              background={Colors.yellow}
              onPress={this.clear}
            />
          </View>
          <View style={[styles.row, styles.alignBottom]}>
            <View style={styles.row3}>
              <Text style={[styles.text, styles.label]}>
                {langProps.add_keyword || ""}
              </Text>
              <TextInput
                style={styles.input}
                value={operation.value}
                onChangeText={(value) => operation.setValue(value)}
                placeholder={langProps.enter_keywords || ""}
              />
            </View>
            <View
              style={[
                styles.row,
                styles.row3,
                styles.justifyCenter,
                styles.alignCenter
              ]}
            >
              <Button
                style={[
                  styles.btn,
                  operation.strict === "strict"
                    ? { opacity: 1 }
                    : { opacity: 0.5 }
                ]}
                fontStyle={styles.vsFontBtn}
                title={langProps.strict || ""}
                background={Colors.border}
                onPress={() => operation.setStrict("strict")}
              />
              <Text style={styles.text}>vs.</Text>
              <Button
                style={[
                  styles.btn,
                  operation.strict === "fuzzy"
                    ? { opacity: 1 }
                    : { opacity: 0.5 }
                ]}
                fontStyle={styles.vsFontBtn}
                title={langProps.fuzzy || ""}
                background={Colors.border}
                onPress={() => operation.setStrict("fuzzy")}
              />
            </View>
            <View style={[styles.row, styles.row3, styles.justifyRight]}>
              <Button
                upper
                style={styles.btn}
                title={
                  operation.advanced
                    ? langProps.simple || ""
                    : langProps.advanced || ""
                }
                background={Colors.green}
                onPress={() => (operation.advanced = !operation.advanced)}
              />
              <Button
                upper
                disabled={!operation.hasKeyword}
                style={styles.btn}
                title={langProps.add || ""}
                background={Colors.pink}
                onPress={operation.add}
              />
            </View>
          </View>
        </View>
        {operation.advanced && (
          <View style={[styles.step, styles.stepTwo]}>
            <View style={[styles.row, styles.alignBottom, styles.space2x]}>
              <View style={styles.row3}>
                <Text style={[styles.text, styles.label]}>
                  {langProps.add_similar_keywords || ""}
                </Text>
                <Select
                  defaultText={langProps.select_similar || ""}
                  styleContainer={styles.select}
                  options={operation.similars}
                  value={operation.similar}
                  onChange={(event) => (operation.similar = event.target.value)}
                  white
                />
              </View>
              <View
                style={[
                  styles.row3,
                  !operation.manyWords ? { opacity: 0.5 } : { opacity: 1 }
                ]}
              >
                <Text style={[styles.text, styles.label]}>
                  {langProps.distance_between_keywords || ""}
                </Text>
                <Select
                  defaultText={langProps.select_distance || ""}
                  styleContainer={styles.select}
                  options={operation.distances}
                  value={operation.distance}
                  onChange={(event) =>
                    (operation.distance = event.target.value)
                  }
                  white
                  disabled={!operation.manyWords}
                />
              </View>
              <View style={[styles.row, styles.row3, styles.alignCenter]}>
                <View style={[styles.row2, styles.checkbox]}>
                  <Checkbox
                    label={langProps.send_alert || ""}
                    checked={operation.sendAlert}
                    onChange={(event) =>
                      (operation.sendAlert = !operation.sendAlert)
                    }
                  />
                </View>
                <View
                  style={[
                    styles.row2,
                    !operation.sendAlert ? { opacity: 0.5 } : { opacity: 1 }
                  ]}
                >
                  <Select
                    defaultText={langProps.select_alert || ""}
                    styleContainer={styles.select}
                    options={operation.alerts}
                    value={operation.alert}
                    onChange={(event) => (operation.alert = event.target.value)}
                    white
                    disabled={!operation.sendAlert}
                  />
                </View>
              </View>
            </View>
            <View style={[styles.row, styles.alignBottom, styles.space3x]}>
              <View style={styles.row3} />
              <View style={styles.row3}>
                <Text style={[styles.text, styles.label]}>
                  {langProps.should_not_contain || ""}
                </Text>
                <TextInput
                  style={styles.input}
                  value={operation.noContain}
                  onChangeText={(value) => (operation.noContain = value)}
                  placeholder={langProps.enter_keywords || ""}
                />
              </View>
              <View style={styles.row3}>
                <Text style={[styles.text, styles.label]}>priority</Text>
                <View style={[styles.row, styles.justifyBetween]}>
                  <Button
                    style={[
                      styles.btnFull,
                      operation.priority === "high"
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    ]}
                    fontStyle={styles.btnFullFont}
                    title={langProps.high || ""}
                    background={Colors.border}
                    onPress={() => operation.setPriority("high")}
                  />
                  <Button
                    style={[
                      styles.btnFull,
                      operation.priority === "medium"
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    ]}
                    fontStyle={styles.btnFullFont}
                    title={langProps.medium || ""}
                    background={Colors.border}
                    onPress={() => operation.setPriority("medium")}
                  />
                  <Button
                    style={[
                      styles.btnFull,
                      operation.priority === "low"
                        ? { opacity: 1 }
                        : { opacity: 0.5 }
                    ]}
                    fontStyle={styles.btnFullFont}
                    title={langProps.low || ""}
                    background={Colors.border}
                    onPress={() => operation.setPriority("low")}
                  />
                </View>
              </View>
            </View>
            <View
              style={[
                styles.row,
                styles.content,
                styles.alignCenter,
                styles.justifyBetween
              ]}
            >
              <TouchableOpacity
                style={styles.linkContainer}
                onPress={() =>
                  (operation.yourKeywords = !operation.yourKeywords)
                }
              >
                <Text style={styles.link}>
                  {operation.yourKeywords
                    ? langProps.hide_keywords || ""
                    : langProps.view_keywords || ""}
                </Text>
              </TouchableOpacity>
              <Button
                upper
                disabled={!operation.hasKeyword}
                title={langProps.add || ""}
                background={Colors.green}
                onPress={operation.addAdvanced}
              />
            </View>
          </View>
        )}
        {operation.keywords.length > 0 && operation.yourKeywords && (
          <View style={[styles.step, styles.stepThree]}>
            <View style={[styles.row, styles.alignCenter, styles.space2x]}>
              <Text style={[styles.headerText, styles.first, styles.columnLg]}>
                your keywords
              </Text>
              <View style={[styles.row, styles.columnData]}>
                <Text
                  style={[
                    styles.headerText,
                    styles.textCenter,
                    styles.columnSm
                  ]}
                >
                  {langProps._strict || ""}
                </Text>
                <Text style={[styles.headerText, styles.columnMd]}>
                  {langProps._distance_between_keywords || ""}
                </Text>
                <Text style={[styles.headerText, styles.columnMd]}>
                  {langProps._should_not_contain || ""}
                </Text>
                <Text style={[styles.headerText, styles.columnMd]}>
                  {langProps.similar || ""}
                </Text>
                <Text style={[styles.headerText, styles.columnSm]}>
                  {langProps.alert || ""}
                </Text>
                <Text style={[styles.headerText, styles.columnSm]}>
                  {langProps.priority || ""}
                </Text>
              </View>
            </View>
            {operation.keywords.map((item, index) => (
              <View
                style={[styles.row, styles.alignCenter, styles.space]}
                key={index}
              >
                <View style={styles.columnLg}>
                  <View style={styles.tag}>
                    <Text style={styles.tagText}>{item.value}</Text>
                    <TouchableOpacity
                      style={styles.close}
                      onPress={() => this.showConfirm(index)}
                    >
                      <Image
                        accessibilityLabel="Close"
                        source={close}
                        resizeMode="contain"
                        style={styles.closeIcon}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={[styles.row, styles.colBg, styles.columnData]}>
                  <Text
                    style={[styles.colText, styles.textCenter, styles.columnSm]}
                  >
                    {item.settings.strict}
                  </Text>
                  <Text style={[styles.colText, styles.columnMd]}>
                    {item.settings.distance !== ""
                      ? item.settings.distance
                      : `n/a`}
                  </Text>
                  <Text style={[styles.colText, styles.columnMd]}>
                    {item.settings.noContain}
                  </Text>
                  <Text style={[styles.colText, styles.columnMd]}>
                    {item.settings.similar}
                  </Text>
                  <Text style={[styles.colText, styles.columnSm]}>
                    {item.settings.sendAlert ? item.settings.alert : `none`}
                  </Text>
                  <Text style={[styles.colText, styles.columnSm]}>
                    {item.settings.priority}
                  </Text>
                </View>
              </View>
            ))}
            <Confirm
              show={operation.showConfirm}
              title={langProps.delete_keyword || ""}
              message={langProps.delete_keyword_message || ""}
              onConfirm={this.onConfirm}
              onCancel={this.onCancel}
            />
          </View>
        )}
        {!operation.loading &&
          operation.keywords.length === 0 &&
          operation.yourKeywords && (
            <View style={[styles.step, styles.stepThree]}>
              <Text style={[styles.empty, styles.textCenter]}>
                {langProps.no_keywords || ""}
              </Text>
            </View>
          )}
        {operation.loading && <Loading />}
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  keywordsContainer: {
    paddingTop: 76,
    paddingBottom: 60
  },
  content: {
    paddingHorizontal: 15
  },
  step: {
    paddingTop: 75,
    paddingBottom: 40,
    paddingHorizontal: 30
  },
  stepOne: {
    backgroundColor: Colors.keywordsPurplePrimaryBg
  },
  stepTwo: {
    backgroundColor: Colors.keywordsPurpleSecondaryBg
  },
  stepThree: {
    backgroundColor: Colors.keywordsGreenPrimaryBg
  },
  space: {
    marginBottom: 20
  },
  space2x: {
    marginBottom: 40
  },
  space3x: {
    marginBottom: 60
  },
  row: {
    flexDirection: "row"
  },
  justifyBetween: {
    justifyContent: "space-between"
  },
  justifyCenter: {
    justifyContent: "center"
  },
  justifyRight: {
    justifyContent: "flex-end"
  },
  alignCenter: {
    alignItems: "center"
  },
  alignBottom: {
    alignItems: "flex-end"
  },
  row3: {
    flex: 0.33333,
    paddingHorizontal: 15
  },
  row2: {
    flex: 0.5
  },
  text: {
    ...Fonts.style.labelTitle,
    color: Colors.white
  },
  label: {
    marginBottom: 5
  },
  empty: {
    ...Fonts.style.labelTitle,
    fontSize: 24,
    color: Colors.white
  },
  input: {
    ...Fonts.style.input,
    color: Colors.white,
    borderWidth: 1,
    borderColor: Colors.white,
    height: 34,
    paddingHorizontal: 15
  },
  btn: {
    marginHorizontal: 10
  },
  btnFull: {
    flex: 0.3,
    alignSelf: "center"
  },
  btnFullFont: {
    fontFamily: Fonts.family.arvo
  },
  vsFontBtn: {
    ...Fonts.style.labelTitle,
    color: Colors.white
  },
  select: {
    marginBottom: 0,
    borderColor: Colors.white
  },
  checkbox: {
    marginRight: 30
  },
  linkContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.green
  },
  link: {
    color: Colors.green,
    borderBottomWidth: 1,
    borderBottomColor: Colors.green
  },
  columnLg: {
    flex: 0.25
  },
  columnMd: {
    flex: 0.2
  },
  columnSm: {
    flex: 0.133333
  },
  headerText: {
    ...Fonts.style.text,
    color: Colors.white,
    fontWeight: "600"
  },
  first: {
    fontFamily: Fonts.family.arvo,
    fontWeight: "700"
  },
  tag: {
    backgroundColor: Colors.green,
    position: "relative",
    alignSelf: "flex-start",
    paddingVertical: 5,
    paddingLeft: 10,
    paddingRight: 30
  },
  tagText: {
    ...Fonts.style.text,
    color: Colors.white
  },
  colText: {
    ...Fonts.style.text,
    color: Colors.white,
    fontWeight: "400"
  },
  colBg: {
    backgroundColor: Colors.keywordsGreenSecondaryBg,
    paddingVertical: 5
  },
  columnData: {
    flex: 0.75
  },
  close: {
    position: "absolute",
    top: 6,
    right: 3
  },
  closeIcon: {
    width: 18,
    height: 18
  },
  textCenter: {
    textAlign: "center"
  }
});

export default withLang(languagesHome)(observer(Keywords));
