import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import Recaptcha from "react-recaptcha";

class RecaptchaComponent extends Component {
  render() {
    return (
      <View style={styles.container}>
        <Recaptcha
          sitekey="6LcY8oAUAAAAAMEpr7fIFu3IbvCVC3mqQqzt3oF6"
          render="explicit"
          size="normal"
          verifyCallback={this.props.onVerify}
          onloadCallback={this.callback}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 100
  }
});

export default RecaptchaComponent;
