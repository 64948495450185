import axios from "axios";
import { auth } from "../Model";

let instance = null;

class AlbaAPI {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.api = axios.create({
      baseURL: "https://alotespuwk.execute-api.us-west-2.amazonaws.com",
      headers: {
        "Content-Type": "application/json"
      }
    });

    return instance;
  }

  getAlertModal = ({ date, doc_id }) =>
    this.api.post("/prod/single-alert", {
      Authorization: `${auth.getToken()}`,
      date,
      doc_id
    });
  getData = (topic) =>
    this.api.post("/prod/retrievedocs", {
      Authorization: `${auth.getToken()}`,
      topic
    });

  getDataByArgs = (payload) => this.api.post("/prod/retrievedocs", payload);

  getKeywords = () =>
    this.api.post("/prod/user-operations", {
      Authorization: `${auth.getToken()}`
    });

  getSimilars = (keyword) =>
    this.api.post("/prod/similarkeywords", {
      Authorization: `${auth.getToken()}`,
      keyword
    });

  getAuthorization = () =>
    this.api
      .post("/prod/user-operations", { Authorization: `${auth.getToken()}` })
      .then(({ data }) => {
        return data;
      });

  setKeyword = (payload) => this.api.post("/prod/modifykeywords", payload);

  setFavorite = (payload) => this.api.post("/prod/isFavorite", payload);
}

export default AlbaAPI;
