import React, { Component } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import { Navigate } from "react-router-dom";

import Button from "../../Components/Button";
import Colors from "../../Themes/Colors";

import { auth } from "../../Model";
import { withRouter } from "../../Navigation/withRouter";
export class Callback extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }

  goHome = () => {
    this.props.navigate("/");
  };

  renderContent() {
    if (this.state.loading) {
      return (
        <View style={styles.content}>
          <ActivityIndicator
            animating={true}
            color={Colors.pink}
            size="large"
          />
        </View>
      );
    } else if (auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/inicio" }} />;
    }
    return (
      <View style={styles.content}>
        <Text style={styles.text}>
          Access denied, please contact the administrator.
        </Text>
        <Button
          center
          title={`Login`}
          background={Colors.pink}
          onPress={this.goHome}
        />
      </View>
    );
  }

  render() {
    return <View style={styles.container}>{this.renderContent()}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: "100%",
    backgroundColor: Colors.sidebar,
    paddingTop: 50,
    marginTop: 0,
    paddingBottom: 50
  },
  content: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    textAlign: "center",
    marginBottom: 30
  }
});

export default withRouter(Callback);
