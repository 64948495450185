import React, { Component } from "react";
import { Dimensions, View } from "react-native";
import { observer } from "mobx-react";
import { Navigate } from "react-router-dom";
import debounce from "lodash/debounce";

import { withRouter } from "../../Navigation/withRouter";

import SidebarContainer from "../../Components/SidebarContainer";
import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import HomeSections from "../../Components/HomeSections";

import { auth, alba, operation } from "../../Model";

const HomeAdmin = observer(
  class HomeAdmin extends Component {
    constructor(props) {
      super(props);

      this.state = {
        screen: {
          w: 0,
          h: 0
        },
        sidebarOpen: false
      };
    }

    updateDimensions = () => {
      const { height, width } = Dimensions.get("window");
      this.setState({ screen: { w: width, h: height } });
    };

    UNSAFE_componentWillMount() {
      this.updateDimensions();
    }

    componentDidMount() {
      auth.isAuthorized().then((isAuth) => {
        if (isAuth) {
          operation.getData().then(() => {
            console.log("operation.getData()");
            alba.getData();
          });
          window.addEventListener(
            "resize",
            debounce(this.updateDimensions, 300)
          );
        } else {
          this.logout();
        }
      });
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
    }

    toggleSidebar = () => {
      this.setState({
        sidebarOpen: !this.state.sidebarOpen
      });
    };

    logout = () => {
      auth.logout();
      this.props.navigate("/");
    };

    render() {
      if (!auth.loggedIn()) {
        return <Navigate replace to={{ pathname: "/" }} />;
      }

      return (
        <View>
          <Wrapper>
            <Header
              screen={this.state.screen}
              showSidebar={this.toggleSidebar}
              onLogout={this.logout}
              showTopics
            />
            <HomeSections screen={this.state.screen} />
          </Wrapper>
          <SidebarContainer />
        </View>
      );
    }
  }
);

export default withRouter(HomeAdmin);
