import Auth0Lock from "auth0-lock";
import logo from "../Images/logo-auth.png";
import Colors from "../Themes/Colors";

import { api, alba } from "../Model";

class AuthService {
  constructor() {
    this.lock = new Auth0Lock(
      process.env.REACT_APP_AUTH0_CLIENT_ID,
      process.env.REACT_APP_AUTH0_DOMAIN,
      {
        theme: {
          primaryColor: Colors.purple,
          logo
        },
        languageDictionary: {
          title: "El Alba"
        },
        closable: true,
        auth: {
          redirectUrl: process.env.REACT_APP_AUTH_CALLBACK,
          responseType: "token"
        }
      }
    );
    this.lock.on("authenticated", this.doAuthentication);
  }

  doAuthentication = (authResult) => {
    this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
      localStorage.setItem("profile", JSON.stringify(profile));
      window.location.replace("/dashboard");
    });
    this.setToken(authResult.idToken);
    this.lock.hide();
  };

  getProfile = () => JSON.parse(localStorage.getItem("profile"));

  login = (language) => {
    this.lock.show({ language });
  };

  loggedIn = () => !!this.getToken();

  setToken = (idToken) => {
    localStorage.setItem("id_token", idToken);
  };

  getToken = () => localStorage.getItem("id_token");

  logout = () => {
    localStorage.removeItem("id_token");
  };

  isAuthorized = async () => {
    alba.loading = true;
    const data = await api.getAuthorization();
    alba.loading = false;
    if (data && data.statusCode === "400") return false;

    return true;
  };
}

export default AuthService;
