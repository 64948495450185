import React from 'react'
import Select from '../Components/Select'

export default ({ fromMonth, toMonth, date, localeUtils, onChange }) => {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = function handleChange (e) {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <form className='DayPicker-Caption'>
      <Select name='month' value={date.getMonth()} options={months} onChange={handleChange} valueIndex/>
      <Select name='year' value={date.getFullYear()} options={years} onChange={handleChange}/>
    </form>
  )
}
