import React, { Component } from "react";
import { Dimensions, View } from "react-native";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";

import Loading from "../../Components/Loading";
import Wrapper from "../../Components/Wrapper";
import { withRouter } from "../../Navigation/withRouter";

import { alba, auth, home, operation } from "../../Model";
import Header from "../../Components/Header";
import HomeSections from "../../Components/HomeSections";
import SidebarContainer from "../../Components/SidebarContainer";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isInbox: false,
      screen: {
        w: 0,
        h: 0
      },
      sidebarOpen: false
    };
  }

  logout = () => {
    auth.logout();
    this.props.navigate("/");
  };

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    this.resetHomeVars();
    operation.getData().then((isAuth) => {
      if (isAuth) {
        localStorage.setItem("Topic", operation.topic);
        if (operation.startOn === "inbox") this.setState({ isInbox: true });
        else {
          alba.getData();
          window.addEventListener(
            "resize",
            debounce(this.updateDimensions, 300)
          );
        }
      } else {
        this.logout();
      }
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  resetHomeVars = () => {
    home.searched = false;
    home.reset();
  };

  renderContent = () => {
    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.isInbox) {
      return <Navigate replace to={{ pathname: "/inbox" }} />;
    }

    return (
      <View>
        <Wrapper>
          <Header
            screen={this.state.screen}
            onLogout={() => {
              auth.logout();
              this.props.navigate("/");
            }}
            showTopics
          />
          <HomeSections screen={this.state.screen} />
        </Wrapper>
        <SidebarContainer />
      </View>
    );
  };

  render() {
    if (!auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/" }} />;
    }

    return <Wrapper>{this.renderContent()}</Wrapper>;
  }
}

export default withRouter(observer(Dashboard));
