import React, { Component } from "react";
import { observer } from "mobx-react";

import SidebarContent from "../SidebarContent";

class SidebarContainer extends Component {
  render() {
    return (
      <SidebarContent
        onApply={this.props.onApply}
        onExportExcel={this.props.onExportExcel}
      />
    );
  }
}

export default observer(SidebarContainer);
