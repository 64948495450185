import { AppRegistry } from "react-native";

import React from "react";
import { configurePersistable } from "mobx-persist-store";

import { mobxPersistStore } from "./Services/Mobx";
import RouterRoot from "./Navigation/Router";
configurePersistable(mobxPersistStore.configration, mobxPersistStore.options);

const App = () => <RouterRoot />;

AppRegistry.registerComponent("Alba", () => App);

AppRegistry.runApplication("Alba", {
  rootTag: document.getElementById("root")
});

export default App;
