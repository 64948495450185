import React, { Component } from "react";
import { observer } from "mobx-react";
import { View, StyleSheet, Text, TouchableOpacity } from "react-native";
import { Link } from "react-router-dom";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

class HeaderDropdown extends Component {
  onPress = (value) => this.props.onSelected && this.props.onSelected(value);

  renderDropdown = () => {
    const styleLinkMobile = this.props.isMobile ? styles.mobileLink : null;
    const styleContentMobile = this.props.isMobile
      ? styles.mobileContent
      : null;

    if (this.props.list) {
      return (
        <View style={styles.list}>
          {this.props.items.map((item, index) => (
            <View
              key={index}
              style={[styles.content, styleContentMobile]}
              className="menu-item"
            >
              <Link to={item.to}>
                <Text style={[styles.link, styleLinkMobile]}>{item.text}</Text>
              </Link>
            </View>
          ))}
        </View>
      );
    }

    if (this.props.select) {
      return (
        <View style={styles.list}>
          {this.props.items.map((item, index) => (
            <View
              key={index}
              style={[styles.content, styleContentMobile]}
              className="menu-item"
            >
              <TouchableOpacity onPress={() => this.onPress(item.key)}>
                <Text
                  style={[
                    styles.link,
                    this.props.selected === item.key ? styles.selected : {},
                    styleLinkMobile
                  ]}
                >
                  {item.text}
                </Text>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      );
    }

    return (
      <View style={styles.list}>
        {this.props.items.map((item, index) => (
          <View
            key={index}
            style={[styles.content, styleContentMobile]}
            className="menu-item"
          >
            <TouchableOpacity onPress={() => this.onPress(item)}>
              <Text
                style={[
                  styles.link,
                  this.props.selected === item ? styles.selected : {},
                  styleLinkMobile
                ]}
              >
                {item}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    );
  };

  renderContent = () => {
    if (!this.props.show) return null;

    return this.renderDropdown();
  };

  render() {
    const styleMobile = this.props.isMobile ? styles.mobileContainer : null;
    return (
      <View
        style={[
          this.props.list
            ? styles.footerContainer
            : this.props.left
            ? styles.leftContainer
            : styles.rightContainer,
          styleMobile
        ]}
      >
        {this.renderContent()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  footerContainer: {
    position: "absolute",
    bottom: 45,
    left: 0
  },
  rightContainer: {
    position: "absolute",
    top: 47,
    right: -20
  },
  leftContainer: {
    position: "absolute",
    top: 47,
    left: -20
  },

  mobileContainer: {
    position: "relative",
    top: 0
  },
  list: {
    boxShadow: "0px 0px 10px rgba(0,0,0,0.6)",
    borderRadius: 5,
    padding: 15,
    paddingBottom: 5,
    backgroundColor: Colors.sidebar
  },
  link: {
    ...Fonts.style.text,
    letterSpacing: 2,
    textAlign: "left",
    whiteSpace: "nowrap",
    textTransform: "uppercase"
  },
  mobileLink: {
    textAlign: "center"
  },
  selected: {
    fontWeight: "800"
  },
  content: {
    marginBottom: 10
  },
  mobileContent: {
    alignItems: "center"
  }
});

export default observer(HeaderDropdown);
