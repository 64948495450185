import React, { Component } from "react";
import Popup from "reactjs-popup";
import { View, Text, StyleSheet, ActivityIndicator } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";
import Slider from "react-slick";

import Button from "../../Components/Button";
import Checkbox from "../../Components/Checkbox";
import Select from "../../Components/SelectCustom";
import Loading from "../../Components/Loading";
import Container from "../../Components/Container";
import Paginator from "../../Components/Paginator";
import UnderlinedText from "../../Components/UnderlinedText";
import AlertItem from "../../Components/AlertItem";
import ArrowSlider from "../ArrowSlider";
import { withRouter } from "../../Navigation/withRouter";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import rightArrow from "../../Images/icon-right-arrow.png";
import leftArrow from "../../Images/icon-left-arrow.png";
import { alba, feedback, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

var settings = {
  customPaging: function (i) {
    return <button>{i}</button>;
  },
  arrows: true,
  dots: false,
  infinite: false,
  swipeToSlide: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: "",
  prevArrow: "",
  adaptiveHeight: true
};

class AlertSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docId: "",
      status: "",
      comment: "",
      amount: 0,
      estimate: true,
      showPopup: false
    };
  }

  UNSAFE_componentWillMount() {
    const _this = this;
    const nextArrow = (
      <ArrowSlider
        class="slick-arrow slick-next"
        onPress={_this.onNext}
        source={rightArrow}
      />
    );
    const prevArrow = (
      <ArrowSlider
        class="slick-arrow slick-prev disabled"
        onPress={_this.onPrev}
        source={leftArrow}
      />
    );
    settings.nextArrow = nextArrow;
    settings.prevArrow = prevArrow;
    settings.beforeChange = function (current, next) {
      alba.config.alert.page = next;
      _this.updateArrows();
    };
  }

  getWraptStyle = () => {
    if (this.props.screen.w <= 768) return styles.wrapSm;
    else if (this.props.screen.w <= 1024) return styles.wrapMd;
    else return {};
  };
  onPrev = () => {
    this.slider.slickPrev();
  };

  onNext = () => {
    this.slider.slickNext();
  };

  onLoadAlerts = (isMore) => {
    alba.loadMoreAlerts(isMore);
  };

  onPage = (page) => {
    this.slider.slickGoTo(page);
  };

  updateArrows = () => {
    const nextArrow = (
      <ArrowSlider
        class={`slick-arrow slick-next ${
          alba.config.alert.page >= alba.alerts.length - 1 ? "disabled" : ""
        }`}
        onPress={this.onNext}
        source={rightArrow}
      />
    );
    const prevArrow = (
      <ArrowSlider
        class={`slick-arrow slick-prev ${
          alba.config.alert.page <= 0 ? "disabled" : ""
        }`}
        onPress={this.onPrev}
        source={leftArrow}
      />
    );
    settings.nextArrow = nextArrow;
    settings.prevArrow = prevArrow;
  };

  handleGiveFeedbackPopup = async () => {
    feedback.processing = true;
    await feedback.giveFeedback({
      doc_id: this.state.docId,
      status_change: this.state.status.replace(" ", "_"),
      comment: this.state.comment,
      saved_amount: this.state.estimate ? this.state.amount : -1
    });
    this.setState({ showPopup: false, status: "yes", comment: "" });
    alba.getData();
  };

  handleGiveFeedback = (doc_id, comment, read, on_review, outcome, amount) => {
    let status = "";
    if (read) {
      if (on_review) {
        status = "on review";
      } else {
        if (outcome) {
          status = "yes";
        } else {
          status = "no";
        }
      }
    }
    this.setState({ showPopup: true, docId: doc_id, status, amount });
  };

  closeModal = () => {
    this.setState({ showPopup: false });
  };

  renderContent = () => {
    const { langProps } = this.props;
    const options = [
      { text: langProps.opt_true_alert || "", value: "yes" },
      { text: langProps.opt_false_alert || "", value: "no" },
      { text: langProps.opt_further_review || "", value: "on review" }
    ];

    const { status, amount } = this.state;
    const disableFeedback =
      (status === "" || status === "yes") &&
      (parseFloat(amount) === 0 || amount === "" || amount === null);

    if (alba.alerts.length > 0) {
      return (
        <View style={styles.container} id={`alertSection`}>
          <UnderlinedText
            upper
            center
            style={styles.title}
            text={langProps.alerts || ""}
            place={1}
            color={Colors.text}
          />
          <Container style={this.getWraptStyle()}>
            {alba.alerts.length > 0 && (
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                {alba.alerts.map((item, index) => (
                  <div key={index}>
                    <AlertItem
                      data={item}
                      screen={this.props.screen}
                      giveFeedback={this.handleGiveFeedback}
                      lang={langProps}
                    />
                  </div>
                ))}
              </Slider>
            )}
            <Paginator
              style={styles.paginator}
              initPage={alba.config.alert.initPage}
              page={alba.config.alert.page}
              step={alba.config.alert.step}
              onPrev={this.onPrev}
              onNext={this.onNext}
              onPage={this.onPage}
              items={alba.alerts}
              total={alba.totalAlerts}
              loadMore={() => this.onLoadAlerts(true)}
              loadLess={() => this.onLoadAlerts(false)}
            />
          </Container>
          <View style={styles.btnContainer}>
            <View style={styles.btnContent}>
              <Button
                upper
                title={langProps.view_all || ""}
                background={Colors.pink}
                onPress={() => this.props.navigate("/inbox")}
              />
            </View>
          </View>
          <Popup
            contentStyle={{ width: 380, borderRadius: 5, padding: 15 }}
            open={this.state.showPopup}
            closeOnDocumentClick
            onClose={this.closeModal}
          >
            <div>
              <Text style={styles.labelBold}>{langProps.status || ""}</Text>
              <Select
                defaultText=" "
                value={this.state.status}
                options={options}
                onChange={(event) =>
                  this.setState({ status: event.target.value })
                }
              />
              <Text style={styles.labelBold}>{langProps.comment || ""}</Text>
              <textarea
                className="feedback-textarea"
                value={this.state.comment}
                onChange={(event) =>
                  this.setState({ comment: event.target.value })
                }
                style={StyleSheet.flatten(styles.textarea)}
              />
              <View style={styles.estimateContainer}>
                <View style={styles.checkboxContainer}>
                  <Checkbox
                    label=""
                    className="purple"
                    checked={this.state.estimate}
                    onChange={(event) =>
                      this.setState({ estimate: !this.state.estimate })
                    }
                  />
                </View>
                <Text style={styles.labelBold}>
                  {langProps.can_estimate || ""}
                </Text>
              </View>
              <Text style={styles.labelBold}>
                {langProps.saving_amount || ""}{" "}
                {status === "yes" && (
                  <Text style={styles.labelSmall}>
                    ({langProps.required || ""})
                  </Text>
                )}
              </Text>
              <input
                type="number"
                value={this.state.amount}
                onChange={(event) =>
                  this.setState({ amount: event.target.value })
                }
                style={StyleSheet.flatten(styles.number)}
              />
              <View style={styles.feedbackActions}>
                <Button
                  upper
                  title={langProps.cancel || ""}
                  background={Colors.yellow}
                  onPress={this.closeModal}
                />
                <View style={{ width: 5 }} />
                <Button
                  upper
                  title={langProps.give_feedback || ""}
                  background={Colors.purple}
                  onPress={this.handleGiveFeedbackPopup}
                  disabled={disableFeedback}
                />
                {feedback.processing && (
                  <ActivityIndicator
                    animating={true}
                    color={Colors.purple}
                    size="small"
                  />
                )}
              </View>
            </div>
          </Popup>
          <MultiLang lang={home.language} />
        </View>
      );
    }

    return null;
  };

  render() {
    if (alba.loadingAlerts) {
      return (
        <View style={styles.loader}>
          <Loading small />
        </View>
      );
    }

    return this.renderContent();
  }
}

const styles = StyleSheet.create({
  loader: {
    minHeight: 528
  },
  container: {
    backgroundColor: Colors.flow
  },
  wrapMd: {
    paddingHorizontal: 45
  },
  wrapSm: {
    paddingHorizontal: 15
  },
  title: {
    ...Fonts.style.underline,
    fontSize: 26
  },
  paginator: {
    position: "absolute",
    bottom: 15,
    right: 15
  },
  btnContainer: {
    display: "block"
  },
  btnContent: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1024,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15
  },
  textarea: {
    height: 90,
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: 90,
    maxHeight: 180,
    boxSizing: "border-box",
    color: "#3d2e56",
    borderStyle: "solid",
    borderColor: "#3d2e56",
    borderWidth: 1,
    padding: 15,
    fontFamily: "Montserrat",
    fontSize: 14,
    marginBottom: 15
  },
  number: {
    height: 34,
    minWidth: "100%",
    maxWidth: "100%",
    boxSizing: "border-box",
    color: "#3d2e56",
    borderStyle: "solid",
    borderColor: "#3d2e56",
    borderWidth: 1,
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: "Montserrat",
    fontSize: 14,
    marginBottom: 15
  },
  feedbackActions: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  labelBold: {
    ...Fonts.style.labelTitle,
    marginBottom: 3
  },
  labelSmall: {
    ...Fonts.style.labelTitle,
    marginBottom: 3,
    fontSize: 12,
    fontWeight: "400",
    color: Colors.pink
  },
  estimateContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10
  },
  checkboxContainer: {
    marginRight: 10
  }
});

export default withRouter(withLang(languagesHome)(observer(AlertSlider)));
