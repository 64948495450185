import React, { Component } from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { MultiLang, withLang } from "react-multi-language";

import HeaderDropdown from "../HeaderDropdown";
import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import logo from "../../Images/logo.png";
import arrowUp from "../../Images/icon-up-arrow.png";
import arrowDown from "../../Images/icon-down-arrow.png";
import menu from "../../Images/icon-menu.png";
import { withRouter } from "../../Navigation/withRouter";
import { auth, alba, home, operation, languages } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class Header extends Component {
  componentDidMount() {
    runInAction(() => {
      home.themeDrop = false;
      home.mobileHeader = false;
    });
  }

  componentWillUnmount() {
    if (this.disposeReaction) this.disposeReaction();
  }

  getSmallStyles = (type) => {
    if (this.props.screen.w <= 600) {
      return styles[type];
    } else {
      return {};
    }
  };

  reduceFontText = () => {
    if (this.props.screen.w <= 768) return styles.navItemTextSm;
    else return {};
  };

  selectLanguage = (lang) => {
    localStorage.setItem("Lang", lang);
    runInAction(() => {
      home.language = lang;
      home.languageDrop = false;
    });
  };

  selectTheme = (value) => {
    localStorage.setItem("Topic", value);
    runInAction(() => {
      operation.topic = value;
      home.themeDrop = false;
    });
    if (this.props.onChangeTopic) {
      this.props.onChangeTopic();
    } else {
      alba.getData();
    }
  };

  scrollToSection = (to) => {
    document
      .getElementById(to)
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };

  renderNav(isMobile) {
    const { langProps } = this.props;
    const currentLang = localStorage.getItem("Lang") || "es";
    const currentLangText = languages.filter(
      (lang) => lang.key === currentLang
    );
    const profile = auth.getProfile();
    const isLogged = auth.loggedIn();
    const styleItemVertical = isMobile ? styles.navItemVertical : null;
    const languageArrow = home.languageDrop ? arrowUp : arrowDown;

    return (
      <View style={[styles.nav, isMobile ? styles.navVertical : null]}>
        <View
          style={[styles.navItem, styleItemVertical]}
          id={"languageDropdown"}
        >
          <View style={styles.topicButton}>
            <TouchableOpacity onPress={home.toggleLanguageDrop}>
              <Text style={styles.navItemText}>{currentLangText[0].text}</Text>
            </TouchableOpacity>
            {currentLang && currentLang.length > 0 ? (
              <Image source={languageArrow} style={styles.topicArrow} />
            ) : null}
          </View>
          <HeaderDropdown
            isMobile={isMobile}
            show={home.languageDrop}
            items={home.languages}
            selected={currentLang}
            onSelected={(lang) => this.selectLanguage(lang)}
            select
          />
        </View>

        {!isLogged && (
          <View style={[styles.navItem, styleItemVertical]}>
            <TouchableOpacity
              onPress={() => this.scrollToSection("aboutUsSection")}
            >
              <Text style={styles.navItemText}>{langProps.about_us || ""}</Text>
            </TouchableOpacity>
          </View>
        )}
        {!isLogged && (
          <View
            style={[styles.navItem, styles.navItemCenter, styleItemVertical]}
          >
            <TouchableOpacity onPress={() => this.props.onLogin(home.language)}>
              <Text style={styles.navItemText}>{langProps.login || ""}</Text>
            </TouchableOpacity>
          </View>
        )}
        {isLogged && (
          <View
            style={[styles.navItem, styles.navItemCenter, styleItemVertical]}
          >
            <Image
              accessibilityLabel={profile.name}
              source={profile.picture}
              resizeMode="contain"
              style={styles.user}
            />
            <TouchableOpacity onPress={this.props.onLogout}>
              <Text style={styles.navItemText}>{langProps.logout || ""}</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }

  render() {
    const currentTopic = localStorage.getItem("Topic");
    const isLogged = auth.loggedIn();
    const topicArrow = home.themeDrop ? arrowUp : arrowDown;

    return (
      <>
        <MultiLang lang={home.language} />
        <View style={styles.container}>
          <View style={styles.left}>
            <Link to="/dashboard">
              <Image
                accessibilityLabel="El alba"
                source={logo}
                resizeMode="cover"
                style={styles.logo}
              />
            </Link>
            {isLogged && this.props.showTopics && (
              <View style={[styles.navItem]} id={"topicDropdown"}>
                <View style={styles.topicButton}>
                  <TouchableOpacity
                    onPress={() =>
                      runInAction(() => (home.themeDrop = !home.themeDrop))
                    }
                  >
                    <Text style={[styles.navItemText, styles.topicText]}>
                      {currentTopic}
                    </Text>
                  </TouchableOpacity>
                  {currentTopic && currentTopic.length > 0 ? (
                    <Image source={topicArrow} style={styles.topicArrow} />
                  ) : null}
                </View>
                <HeaderDropdown
                  show={home.themeDrop}
                  items={operation.topics}
                  selected={currentTopic}
                  onSelected={(theme) => this.selectTheme(theme)}
                  left
                />
              </View>
            )}
          </View>
          <View style={styles.right}>
            {this.props.screen.w > 768 && this.renderNav(false)}
            {this.props.screen.w <= 768 && (
              <View style={styles.nav}>
                <View style={styles.navItem}>
                  <TouchableOpacity
                    onPress={() => (home.mobileHeader = !home.mobileHeader)}
                  >
                    <Image
                      accessibilityLabel="Open menu"
                      source={menu}
                      resizeMode="contain"
                      style={styles.settings}
                    />
                  </TouchableOpacity>
                  {home.mobileHeader && (
                    <View style={styles.mobileHeader}>
                      {this.renderNav(true)}
                    </View>
                  )}
                </View>
              </View>
            )}
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: Colors.navBar,
    zIndex: 9,
    height: 76,
    flexDirection: "row",
    justifyContent: "space-between",
    maxWidth: 1440,
    marginLeft: "auto",
    marginRight: "auto",

    boxShadow: "0px 0px 10px rgba(0,0,0,0.6)"
  },
  containerSm: {
    flexDirection: "column",
    alignItems: "center"
  },
  rowSm: {
    flex: 1
  },
  left: {
    flexDirection: "row",
    height: 76,
    alignItems: "center",
    justifyContent: "flex-start"
  },
  right: {},
  logo: {
    width: 95,
    height: 76
  },
  user: {
    width: 30,
    height: 30,
    borderRadius: 50,
    marginRight: 10
  },
  settings: {
    width: 30,
    height: 30
  },
  upload: {
    width: 26,
    height: 26
  },
  logoSm: {
    width: 95,
    height: 76
  },
  menu: {
    width: 28,
    height: 28
  },
  nav: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  navVertical: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  navItemCenter: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  navItem: {
    marginLeft: 20,
    marginRight: 15,
    position: "relative"
  },
  navItemVertical: {
    marginLeft: 0,
    marginRight: 0,
    marginVertical: 10,
    position: "relative",
    zIndex: -1,
    alignItems: "center"
  },
  navItemText: {
    ...Fonts.style.navBar,
    textTransform: "uppercase"
  },
  navItemTextSm: {
    fontSize: 12
  },
  topicText: {
    fontWeight: "bold"
  },
  mobileHeader: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 76,
    backgroundColor: Colors.navBar
  },
  topicButton: {
    flexDirection: "row",
    alignItems: "center"
  },
  topicArrow: {
    width: 12,
    height: 12,
    marginLeft: 7
  }
});

export default withRouter(withLang(languagesHome)(observer(Header)));
