import React, { Component } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native'
import moment from 'moment'

import ImageResponsive from '../../Components/ImageResponsive'
import Fonts from '../../Themes/Fonts'
import Colors from '../../Themes/Colors'

import star from '../../Images/icon-star.png'
import envelop from '../../Images/icon-envelop.png'
import printer from '../../Images/icon-printer.png'
import trash from '../../Images/icon-trash.png'

class ReportItem extends Component {
  subString = (text, length) => {
    if (text.length > length) return text.substring(0, length) + '...'
    return text.substring(0, length)
  }

  openSource = (url) => {
    window.open(url, '_blank')
  }

  confirm = () => {
    window.confirm('¿Está seguro de borrar éste reporte?')
  }

  existField = (key) => {
    const data = this.props.data
    return data[key] && data[key].length > 0
  }

  render () {
    const data = this.props.data
    return (
      <View style={styles.container}>
        <View style={styles.content}>
          <ImageResponsive source={data.image} label={data.name} style={{ resizeMode: 'cover' }}/>
          <Text style={styles.title}>{data.name}</Text>
          {this.existField('author') && <Text style={styles.yellowText}>| {data.author}</Text>}
          <Text style={styles.description} numberOfLines={20}>
            {this.subString(data.abstract, 575)}
          </Text>
          <Text style={styles.date}>{moment(data.date).format('YYYY-MM-DD')}</Text>
          <View style={styles.actions}>
            <View style={styles.actionsLeft}>
              <TouchableOpacity style={styles.action} onPress={() => this.props.handleFavorite(data)}>
                <Image accessibilityLabel="Star" source={star} resizeMode="contain" style={styles.icon}/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.action} onPress={() => this.props.onSendEmail(data)}>
                <Image accessibilityLabel="Envelop" source={envelop} resizeMode="contain" style={styles.icon}/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.action} onPress={() => this.openSource(data.url)}>
                <Image accessibilityLabel="Printer" source={printer} resizeMode="contain" style={styles.icon}/>
              </TouchableOpacity>
            </View>
            {/* <View style={styles.actionsRight}>
              <TouchableOpacity onPress={this.confirm}>
                <Image accessibilityLabel="Delete" source={trash} resizeMode="contain" style={styles.icon} />
              </TouchableOpacity>
            </View> */}
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingVertical: 60,
    backgroundColor: Colors.flow
  },
  flow: {
    ...Fonts.style.navBar,
    color: Colors.text,
    fontSize: 18
  },
  title: {
    ...Fonts.style.subtitle,
    color: Colors.purple,
    minHeight: 44,
    marginTop: 20,
    marginBottom: 15
  },
  date: {
    ...Fonts.style.text,
    marginBottom: 20
  },
  yellowText: {
    ...Fonts.style.textYellow
  },
  bold: {
    fontWeight: '700'
  },
  description: {
    ...Fonts.style.text,
    marginTop: 20,
    marginBottom: 30,
    minHeight: 270,
    textAlign: 'justify'
  },
  actions: {
    flex: 1,
    flexDirection: 'row'
  },
  actionsLeft: {
    flex: 0.6,
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  actionsRight: {
    flex: 0.4,
    alignItems: 'flex-end'
  },
  icon: {
    width: 24,
    height: 24
  },
  action: {
    marginRight: 10
  }
})

export default ReportItem
