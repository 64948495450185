import React, { Component } from "react";
import { View, Dimensions } from "react-native";
import { Navigate } from "react-router-dom";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";

import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import Management from "../../Components/Management";
import { withRouter } from "../../Navigation/withRouter";
import { auth, management, operation, alba } from "../../Model";

class ManagementContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: {
        w: 0,
        h: 0
      }
    };
  }

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    runInAction(() => {
      management.loading = true;
    });
    operation.getData().then(() => {
      alba.getData().then(() => {
        runInAction(() => {
          management.loadTabs();
        });
      });
    });
    window.addEventListener("resize", debounce(this.updateDimensions, 300));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  reloadTabs = async () => {
    runInAction(() => {
      management.loading = true;
      management.resetLists();
      management.resetConfigPages();
    });
    this.initTabs();
  };

  initTabs = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await alba.getData();
        management.loadTabs();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };

  logout = () => {
    auth.logout();
    this.props.navigate("/");
  };

  render() {
    if (!auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/" }} />;
    }

    return (
      <View>
        <Wrapper>
          <Header
            screen={this.state.screen}
            onLogout={this.logout}
            showTopics
            onChangeTopic={this.reloadTabs}
          />
          <Management screen={this.state.screen} reloadTabs={this.reloadTabs} />
        </Wrapper>
      </View>
    );
  }
}

export default withRouter(observer(ManagementContainer));
