import React, { Component } from "react";
import { View, Dimensions } from "react-native";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";

import SidebarContainer from "../../Components/SidebarContainer";
import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import FeedbackComponent from "../../Components/Feedback";
import { withRouter } from "../../Navigation/withRouter";
import "../../CSS/alba.css";

import { auth, operation, feedback } from "../../Model";

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: {
        w: 0,
        h: 0
      },
      sidebarOpen: false,
      isLogged: false
    };
  }

  componentDidMount() {
    feedback.loading = true;
    if (operation.topic === "") {
      operation.getData().then((isAuth) => {
        if (!isAuth) {
          this.logout();
        }
      });
    }
    this.initTabs();
    window.addEventListener("resize", debounce(this.updateDimensions, 300));
  }

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  reloadTabs = async () => {
    feedback.loading = true;
    feedback.resetLists();
    feedback.resetConfigPages();
    await this.initTabs();
  };

  applyFilters = () => {
    feedback.apply();
    feedback.resetConfigPages();
  };

  exportExcel = () => {
    window.open(feedback.urlExcel, "_blank");
  };

  initTabs = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await feedback.fetchAlerts("read", false, null);
        // await feedback.fetchAlerts('read', true, null)
        await feedback.fetchAlerts("on_review", true, null);
        await feedback.fetchAlerts("outcome", "all", null);
        feedback.loading = false;
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  };

  logout = () => {
    auth.logout();
    this.props.navigate("/");
  };

  render() {
    if (!auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/" }} />;
    }

    return (
      <View>
        <div id={"hola"}>
          <Wrapper>
            <Header
              screen={this.state.screen}
              onLogout={this.logout}
              showTopics
              onChangeTopic={this.reloadTabs}
            />
            <FeedbackComponent
              screen={this.state.screen}
              reloadTabs={this.reloadTabs}
            />
            <SidebarContainer
              onApply={this.applyFilters}
              onExportExcel={this.exportExcel}
            />
          </Wrapper>
        </div>
      </View>
    );
  }
}

export default withRouter(observer(Feedback));
