import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import Loading from "../Loading";
import Button from "../../Components/Button";
import UnderlinedText from "../../Components/UnderlinedText";
import ManagementTab from "../../Components/ManagementTab";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { management, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class ManagementComponent extends Component {
  getSelectedTabStyle = (index) => {
    if (management.tabSelected === index) return styles.tabButtonSelected;
    return null;
  };

  setSelectesTab = (index) => (management.tabSelected = index);

  renderTabContent = () => {
    const { langProps } = this.props;
    if (management.loading) return <Loading />;

    return (
      <ManagementTab
        tabSelected={management.tabSelected}
        screen={this.props.screen}
        lang={langProps}
      />
    );
  };

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.managementContainer}>
        <View style={styles.managementContent}>
          <View style={[styles.bgGreen, styles.actionsContainer]}>
            <Text style={styles.magementText}>
              {langProps.management || ""}
            </Text>
            <View style={styles.actions}>
              <Button
                upper
                title={langProps.send || ""}
                background={Colors.violet}
                onPress={management.onSend}
              />
              <View style={{ width: 10 }} />
              <Button
                upper
                title={langProps.delete || ""}
                background={Colors.text}
                onPress={management.onDelete}
              />
            </View>
          </View>
          <View
            style={[styles.tabs, styles.bgGreen]}
            className="tabsManagement"
          >
            <TouchableOpacity
              className="tabButtonManagement"
              style={[
                styles.tabButton,
                { backgroundColor: Colors.pink },
                this.getSelectedTabStyle(0)
              ]}
              onPress={() => this.setSelectesTab(0)}
            >
              <UnderlinedText
                upper
                center
                style={styles.tabTitle}
                text={langProps.alerts || ""}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
            </TouchableOpacity>
            <TouchableOpacity
              className="tabButtonManagement"
              style={[
                styles.tabButton,
                { backgroundColor: Colors.yellow },
                this.getSelectedTabStyle(1)
              ]}
              onPress={() => this.setSelectesTab(1)}
            >
              <UnderlinedText
                upper
                center
                style={styles.tabTitle}
                text={langProps.reports || ""}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
            </TouchableOpacity>
            <TouchableOpacity
              className="tabButtonManagement"
              style={[
                styles.tabButton,
                { backgroundColor: Colors.purple },
                this.getSelectedTabStyle(2)
              ]}
              onPress={() => this.setSelectesTab(2)}
            >
              <UnderlinedText
                upper
                center
                style={styles.tabTitle}
                text={langProps.feeds || ""}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
            </TouchableOpacity>
          </View>
          <View style={styles.tabContent}>{this.renderTabContent()}</View>
        </View>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  managementContainer: {
    paddingTop: 76,
    paddingBottom: 60
  },
  managementContent: {
    backgroundColor: Colors.white
  },
  bgGreen: {
    backgroundColor: Colors.green
  },
  magementText: {
    ...Fonts.style.text,
    color: Colors.white,
    fontSize: 18,
    marginRight: 30,
    textTransform: "uppercase"
  },
  tabs: {
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 50
  },
  tabButton: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    minWidth: 172
  },
  tabButtonSelected: {
    /*boxShadow: '2px 0px 10px -2px #777',*/
    boxShadow: "2px 0 10px -2px #e2e2e2",
    marginTop: -1,
    zIndex: 999
  },
  tabTitle: {
    ...Fonts.style.underline,
    color: Colors.white,
    fontSize: 20
  },
  actionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: 60,
    paddingHorizontal: 30
  },
  actions: {
    flexDirection: "row",
    justifyContent: "flex-end"
  }
});

export default withLang(languagesHome)(observer(ManagementComponent));
