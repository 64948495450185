import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable, hydrateStore } from "mobx-persist-store";
import { api, endpoints, auth } from "../../Model";

export default class AlbaModel {
  loading = false;
  loadingInBg = false;
  loadingAlerts = false;
  loadingFeeds = false;
  loadingReports = false;
  loadingInsights = false;
  totalFeed = 0;
  offsetAlerts = 0;
  offsetFeeds = 0;
  offsetReports = 0;
  totalAlerts = 0;
  totalFeeds = 0;
  totalReports = 0;
  totalInsights = 0;
  excel = "";
  config = {
    alert: {
      initPage: 0,
      page: 0,
      step: 1
    },
    report: {
      page: 0,
      step: 3
    },
    feed: {
      initPage: 0,
      page: 0,
      step: 1
    }
  };
  alert = {};
  insights_raw = [];
  alerts = [];
  reports = [];
  feeds = [];
  insights = [];
  ddls = [];
  highlights = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AlbaModel",
      properties: [
        "insights_raw",
        "alerts",
        "reports",
        "feeds",
        "insights",
        "ddls",
        "highlights"
      ],
      storage: localStorage
    }).then(() => {
      return hydrateStore(this);
    });
  }

  exportAllExcel = async (payload) => {
    try {
      await endpoints.getAlertsByArgs(payload);
    } catch (error) {
      console.log(error);
    }
  };

  getData = async () => {
    try {
      const currentTopic = localStorage.getItem("Topic");

      this.loadingAlerts = true;
      this.loadingFeeds = true;
      this.loadingReports = true;
      this.loadingInsights = true;
      this.resetLists();

      const dataAlert = await endpoints.getAlerts(currentTopic);

      this.alerts = this.generateDataList(dataAlert.data.body.results, true);
      this.offsetAlerts = dataAlert.data.body.next_offset;
      this.totalAlerts = dataAlert.data.body.total;
      this.config.alert.initPage = this.offsetAlerts - 50;
      this.loadingAlerts = false;
      this.excel = dataAlert.data.body.link;

      const dataFeed = await endpoints.getFeeds(currentTopic);

      this.feeds = this.generateDataList(dataFeed.data.body.results, true);
      this.offsetFeeds = dataFeed.data.body.next_offset;
      this.totalFeeds = dataFeed.data.body.total;
      this.config.feed.initPage = this.offsetFeeds - 50;
      this.loadingFeeds = false;

      const dataReport = await endpoints.getReports(currentTopic);

      this.reports = this.generateDataList(dataReport.data.body.results, true);
      this.offsetReports = dataReport.data.body.next_offset;
      this.totalReports = dataReport.data.body.total;
      this.config.report.initPage = this.offsetReports - 50;
      this.loadingReports = false;

      const dataInsights = await endpoints.getInsights(currentTopic);

      this.insights = this.generateInsights(
        dataInsights.data.body.results,
        true
      );
      this.totalInsights = dataInsights.data.body.total;
      //this.insights_raw = data.body.Insight
      //this.totalFeed = data.body.total_feed
      this.loadingInsights = false;
    } catch (error) {
      this.loadingAlerts = false;
      this.loadingFeeds = false;
      this.loadingReports = false;
      this.loadingInsights = false;
      console.log(error);
    }
  };

  loadMoreAlerts = async (isMore) => {
    try {
      const currentTopic = localStorage.getItem("Topic");
      this.loadingAlerts = true;
      this.alerts = [];

      const newOffset = isMore
        ? this.offsetAlerts
        : this.config.alert.initPage - 50;
      const dataAlert = await endpoints.getAlertsByArgs({
        Authorization: `${auth.getToken()}`,
        topic: currentTopic,
        offset: newOffset
      });

      runInAction(() => {
        this.alerts = this.generateDataList(dataAlert.data.body.results, true);
        this.offsetAlerts = dataAlert.data.body.next_offset;
        this.config.alert.initPage = this.offsetAlerts - 50;
        this.config.alert.page = 0;
        this.loadingAlerts = false;
      });
    } catch (error) {
      this.loadingAlerts = false;
      console.log(error);
    }
  };

  loadMoreFeeds = async (isMore) => {
    try {
      const currentTopic = localStorage.getItem("Topic");
      this.loadingFeeds = true;
      this.feeds = [];

      const newOffset = isMore
        ? this.offsetFeeds
        : this.config.feed.initPage - 50;
      const dataFeed = await endpoints.getFeedsByArgs({
        Authorization: `${auth.getToken()}`,
        topic: currentTopic,
        offset: newOffset
      });

      runInAction(() => {
        this.feeds = this.generateDataList(dataFeed.data.body.results, true);
        this.offsetFeeds = dataFeed.data.body.next_offset;
        this.config.feed.initPage = this.offsetFeeds - 50;
        this.config.feed.page = 0;
        this.loadingFeeds = false;
      });
    } catch (error) {
      this.loadingFeeds = false;
      console.log(error);
    }
  };

  getDataByArgs = async (payload) => {
    try {
      const currentTopic = localStorage.getItem("Topic");
      this.loadingAlerts = true;
      this.loadingFeeds = true;
      this.loadingInsights = true;
      this.resetLists();
      this.resetConfig();

      const dataAlert = await endpoints.getAlertsByArgs({
        ...payload,
        topic: currentTopic
      });

      runInAction(() => {
        this.alerts = this.generateDataList(dataAlert.data.body.results, true);
        this.offsetAlerts = dataAlert.data.body.next_offset;
        this.totalAlerts = dataAlert.data.body.total;
        this.config.alert.initPage = this.offsetAlerts - 50;
        this.excel = dataAlert.data.body.link;
        this.loadingAlerts = false;
      });

      const dataFeed = await endpoints.getFeedsByArgs({
        ...payload,
        topic: currentTopic
      });

      runInAction(() => {
        this.feeds = this.generateDataList(dataFeed.data.body.results, true);
        this.offsetFeeds = dataFeed.data.body.next_offset;
        this.totalFeeds = dataFeed.data.body.total;
        this.config.feed.initPage = this.offsetFeeds - 50;
        this.loadingFeeds = false;
      });

      const dataReport = await endpoints.getReportsByArgs({
        ...payload,
        topic: currentTopic
      });

      runInAction(() => {
        this.reports = this.generateDataList(
          dataReport.data.body.results,
          true
        );
        this.offsetReports = dataReport.data.body.next_offset;
        this.totalReports = dataReport.data.body.total;
        this.config.report.initPage = this.offsetReports - 50;
        this.loadingReports = false;
      });

      const dataInsights = await endpoints.getInsightsByArgs({
        ...payload,
        topic: currentTopic
      });

      runInAction(() => {
        this.insights = this.generateInsights(
          dataInsights.data.body.results,
          true
        );
        this.totalInsights = dataInsights.data.body.total;
        //this.insights_raw = data.body.Insight
        //this.excel = data.body.export_excel
        //this.totalFeed = data.body.total_feed
        this.loadingInsights = false;
      });
    } catch (error) {
      this.loadingAlerts = false;
      this.loadingFeeds = false;
      this.loadingInsights = false;
      console.log(error);
    }
  };

  getAlertsData = async () => {
    try {
      this.loading = true;
      const { data } = await api.getData();
      runInAction(() => {
        if (data && data.body) {
          this.alerts = this.generateList(data.body.Alert);
          this.loading = false;
        }
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  getAlertsPopup = async ({ date, doc_id }) => {
    try {
      this.loadingInBg = true;
      const { data } = await api.getAlertModal({ date, doc_id });
      runInAction(() => {
        if (data && data.body) {
          this.alert = data.body.Item !== null ? data.body.Item.data : null;
          this.loadingInBg = false;
        }
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  getList = (type) => this[type];

  resetLists = () => {
    this.alerts = [];
    this.reports = [];
    this.insights = [];
    this.feeds = [];
  };

  resetConfig = () => {
    this.config.feed.initPage = 0;
    this.config.feed.page = 0;
    this.config.alert.initPage = 0;
    this.config.alert.page = 0;

    this.offsetAlerts = 0;
    this.offsetFeeds = 0;
    this.offsetReports = 0;
  };

  generateDataList = (results, index) => {
    const list = results.map((item, i) => {
      item["key"] = index ? parseInt(i) + 1 : i;
      item.date =
        item.date && item.date !== undefined ? new Date(item.date * 1000) : "";
      item.title = item.title && item.title !== undefined ? item.title : "";
      item.image = item.image && item.image !== undefined ? item.image : "";
      item.text = item.text && item.text !== undefined ? item.text : "";
      item.url = item.url && item.url !== undefined ? item.url : null;

      return item;
    });

    return list;
  };

  generateList = (data, index) => {
    let list = [];

    for (var i in data) {
      data[i].data["key"] = index ? parseInt(i) + 1 : i;
      data[i].data["date"] = new Date(data[i].date * 1000);
      if (data[i].source_) {
        data[i].data["source_"] = data[i].source_;
      }
      list.push(data[i].data);
    }

    return list;
  };

  generateInsights = (data, index) => {
    const list = data.map((item, i) => {
      return item.data;
    });

    return list;
  };

  generateFeeds = (data) => {
    for (var i = 0; i < data.length; i++) {
      if (i === 0 || i === 1) {
        this.smallFeeds.push(data[i].data);
      } else {
        this.largeFeeds.push(data[i].data);
      }
    }
  };

  getInsight = (index) =>
    this.insights.filter((insight) => {
      return parseInt(insight.key) === index;
    });

  generateFeedHighlights = () => {
    for (var i = 0; i <= 100; i++) {
      this.highlights.push(3 * i + 2);
    }
  };

  inFeedHighlights = (index) => {
    const value = index + 1;
    return this.highlights.indexOf(value) === -1 ? false : true;
  };

  axisFormatter = (decimal, format) => {
    if (format === "0.0a") {
      return `${(decimal / 1000000).toFixed(1)}m`;
    } else if (format === "($ 0.00 a)") {
      return `$ ${(decimal / 1000000).toFixed(2)}m`;
    } else if (format === "$0,0.00") {
      return `$ ${Number(decimal).toLocaleString()}.00`;
    } else if (format === "0,0.0000") {
      return `${Number(decimal).toLocaleString()}.00`;
    } else if (format === "0,0.00") {
      return `${Number(decimal).toLocaleString()}.00`;
    } else if (format === "0%") {
      return `${decimal * 100}%`;
    }

    return decimal;
  };
}
