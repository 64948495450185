import React, { Component } from 'react'
import { View } from 'react-native'
import ReactConfirmAlert from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

class Confirm extends Component {
  render () {
    return (
      <View>
        {this.props.show && (
          <ReactConfirmAlert
            title={this.props.title}
            message={this.props.message}
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            onConfirm={this.props.onConfirm}
            onCancel={this.props.onCancel}
          />
        )}
      </View>
    )
  }
}

export default Confirm
