import React, { Component } from "react";
import { View, StyleSheet, Text, TextInput } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import Recaptcha from "../Recaptcha";
import Map from "../GoogleMap";
import Container from "../Container";
import Button from "../Button";
import UnderlinedText from "../UnderlinedText";
import Colors from "../../Themes/Colors";
import Fonts from "../../Themes/Fonts";

import { home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class HomeContact extends Component {
  getSectionStyle = () => {
    if (this.props.screen.w <= 768) return styles.rowSectionSm;
    else if (this.props.screen.w <= 980) return styles.rowSectionMd;
    else return {};
  };

  getRowStyle = () => {
    if (this.props.screen.w <= 768) return styles.rowSm;
    else return {};
  };

  getInputStyle = () => {
    if (this.props.screen.w <= 768) return styles.inputSm;
    else return {};
  };

  verifyCaptcha = (response) => {};

  submit = () => {};

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.container}>
        <Container>
          <View style={[styles.row, this.getRowStyle()]}>
            <View style={[styles.rowSectionL, this.getSectionStyle()]}>
              <UnderlinedText
                upper
                style={styles.title}
                text={langProps.contact_us || ""}
                place={1}
                color={Colors.text}
              />
              <View style={styles.space2x} />
              <View style={styles.space2x} />
              <Text style={styles.label}>m[at]dive.ai</Text>
              <Text style={styles.label}>+52 (55) 7505 5922</Text>
              <View style={styles.space2x} />
              <Text style={[styles.pinkText, styles.bold]}>
                Manuel Aragonés
              </Text>
              <Text style={[styles.pinkText, styles.normal]}>
                CEO Managing | ext. 101 |{" "}
              </Text>
              <View style={styles.space} />
              <Map />
              <View style={styles.space} />
              <Text style={styles.text}>Ensenada 75D, Hipódromo Condesa</Text>
              <Text style={styles.text}>CP 06100, Ciudad de México, CDMX</Text>
            </View>
            <View style={[styles.rowSectionR, this.getSectionStyle()]}>
              <View style={styles.space3x} />
              <View style={styles.space3x} />
              <View style={styles.space2x} />
              <Text style={styles.label}>{langProps.drop_line || ""}</Text>
              <View style={styles.space2x} />
              <View>
                <Text style={styles.labelForm}>{langProps.name || ""}</Text>
                <TextInput style={[styles.input, this.getInputStyle()]} />
                <Text style={styles.labelForm}>{langProps.email || ""}</Text>
                <TextInput style={[styles.input, this.getInputStyle()]} />
                <Text style={styles.labelForm}>{langProps.company || ""}</Text>
                <TextInput style={[styles.input, this.getInputStyle()]} />
                <Text style={styles.labelForm}>{langProps.message || ""}</Text>
                <textarea className={`contact-home-textarea`} />
                <View style={styles.space} />
                <Recaptcha onVerify={this.verifyCaptcha} />
                <Button
                  upper
                  title={langProps.submit || ""}
                  background={Colors.pink}
                  onPress={() => this.submit}
                />
              </View>
            </View>
          </View>
        </Container>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 100,
    backgroundColor: Colors.purple
  },
  wrap: {
    paddingHorizontal: 15
  },
  row: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  rowSm: {
    flexDirection: "column"
  },
  rowSectionL: {
    flex: 0.35
  },
  rowSectionR: {
    flex: 0.3
  },
  rowSectionMd: {
    flex: 0.45
  },
  rowSectionSm: {
    flex: 1
  },
  title: {
    ...Fonts.style.navBar,
    color: Colors.text,
    fontSize: 18
  },
  label: {
    ...Fonts.style.labelTitle,
    fontSize: 18,
    color: Colors.white,
    marginBottom: 2
  },
  labelForm: {
    ...Fonts.style.textPink,
    marginBottom: 8
  },
  input: {
    ...Fonts.style.input,
    color: Colors.text,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.pink,
    width: 300,
    height: 28,
    marginBottom: 8,
    paddingHorizontal: 15
  },
  inputSm: {
    width: "100%"
  },
  text: {
    ...Fonts.style.text
  },
  pinkText: {
    ...Fonts.style.textPink
  },
  bold: {
    fontWeight: "700"
  },
  normal: {
    fontWeight: "400"
  },
  space: {
    height: 1,
    marginBottom: 9
  },
  space2x: {
    height: 1,
    marginBottom: 19
  },
  space3x: {
    height: 1,
    marginBottom: 29
  },
  map: {
    width: 320,
    height: 240
  }
});

export default withLang(languagesHome)(observer(HomeContact));
