import React, { Component } from "react";
import { View, ImageBackground, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import SectionsListItem from "../../Components/SectionsListItem";
import background from "../../Images/bg-sections.jpg";
import Colors from "../../Themes/Colors";

import iconInsight from "../../Images/icon-insights.png";
import iconAlert from "../../Images/icon-alerts.png";
import iconReports from "../../Images/icon-reports.png";
import iconFlow from "../../Images/icon-flow.png";

import { alba, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class SectionsList extends Component {
  getContainerStyle = () => {
    if (this.props.screen.w <= 768) return styles.containerSm;
    else return {};
  };

  getListStyle = () => {
    if (this.props.screen.w <= 768) return styles.listSm;
    else return {};
  };

  getSeparatorStyle = () => {
    if (this.props.screen.w <= 768) return styles.separatorSm;
    else return {};
  };

  scrollToSection = (to) => {
    document
      .getElementById(to)
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.container}>
        <ImageBackground
          source={background}
          resizeMode="cover"
          style={styles.background}
        >
          <View style={styles.innerContainer}>
            <View style={[styles.list, this.getListStyle()]} id="listContainer">
              <SectionsListItem
                onPress={() => this.scrollToSection("alertSection")}
                screen={this.props.screen}
                text={langProps.alerts || ""}
                underline={1}
                value={alba.totalAlerts}
                icon={iconAlert}
              />
              <View style={[styles.separator, this.getSeparatorStyle()]} />
              <SectionsListItem
                onPress={() => this.scrollToSection("reportSection")}
                screen={this.props.screen}
                text={langProps.reports || ""}
                underline={1}
                value={alba.reports.length}
                icon={iconReports}
              />
              <View style={[styles.separator, this.getSeparatorStyle()]} />
              <SectionsListItem
                onPress={() => this.scrollToSection("feedSection")}
                screen={this.props.screen}
                text={langProps.feeds || ""}
                underline={1}
                value={alba.totalFeeds}
                icon={iconFlow}
              />
              <View style={[styles.separator, this.getSeparatorStyle()]} />
              <SectionsListItem
                onPress={() => this.scrollToSection("insightSection")}
                screen={this.props.screen}
                text={langProps.insights || ""}
                underline={2}
                value={alba.insights_raw.length || alba.totalInsights}
                icon={iconInsight}
              />
            </View>
          </View>
        </ImageBackground>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 0.3,
    minHeight: 200,
    flexDirection: "row",
    marginTop: 76,
    backgroundColor: "#b8785d"
  },
  background: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  innerContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center"
  },
  list: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  listSm: {
    flexDirection: "column"
  },
  separator: {
    width: 1,
    minHeight: 70,
    backgroundColor: Colors.white
  },
  separatorSm: {
    width: 150,
    minHeight: 1
  }
});

export default withLang(languagesHome)(observer(SectionsList));
