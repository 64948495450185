import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback
} from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";
import decode from "decode-html";

import Button from "../../Components/Button";
import ImageResponsive from "../../Components/ImageResponsive";
import Loading from "../../Components/Loading";
import BarChart from "../../Components/BarChart";
import BarChartSimple from "../../Components/BarChartSimple";
import BarChartTabs from "../../Components/BarChartTabs";
import AreaChart from "../../Components/AreaChart";
import LineChart from "../../Components/LineChart";
import ScatterChart from "../../Components/ScatterChart";
import UnderlinedText from "../../Components/UnderlinedText";
import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import { alba, home } from "../../Model";

class Insights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popup: false
    };
  }

  getListStyle = () => {
    if (this.props.screen.w <= 768) return styles.listSm;
    else return {};
  };

  getItemStyle = () => {
    if (this.props.screen.w <= 768) return styles.itemSm;
    else return {};
  };

  togglePopup = () => {
    this.setState({ popup: !this.state.popup });
  };

  openModal = (props) => {
    const { payload } = props;
    alba.getAlertsPopup({ date: payload.call_date, doc_id: payload.call_id });
    this.togglePopup();
  };

  openSource = (url) => {
    window.open(url, "_blank");
  };

  renderChart(data) {
    if (data.vis_type === "calls_chart_data") {
      return <BarChart data={data} onClickAlert={this.openModal} />;
    } else if (data.vis_type === "stacked_area") {
      return <AreaChart data={data} />;
    } else if (data.vis_type === "line") {
      return <LineChart data={data} />;
    } else if (data.vis_type === "bar") {
      return <BarChartSimple data={data} />;
    } else if (data.vis_type === "scatter") {
      return <ScatterChart data={data} />;
    } else if (data.vis_type === "barplot_tabs") {
      return <BarChartTabs data={data} />;
    }

    return (
      <View style={styles.iframe}>
        <Text style={styles.subtitle}>{data.title}</Text>
        <iframe
          className="alertIframe"
          src={data.data_endpoint}
          style={{ flex: 1, width: "100%", height: "100%", borderWidth: 0 }}
        />
      </View>
    );
  }

  renderContent = () => {
    const { langProps } = this.props;
    if (alba.insights.length > 0) {
      return (
        <View>
          <View style={styles.title}>
            <UnderlinedText
              upper
              center
              style={styles.titleText}
              text={langProps.insights || ""}
              place={1}
              color={Colors.text}
            />
          </View>
          <View style={[styles.list, styles.borderBottom, this.getListStyle()]}>
            {alba.insights.map((data, index) => {
              const isRechart =
                data.vis_type === "barplot_tabs" ||
                data.vis_type === "line" ||
                data.vis_type === "bar" ||
                data.vis_type === "stacked_area" ||
                data.vis_type === "scatter";
              return (
                <View
                  key={index}
                  style={[
                    styles.item,
                    isRechart && styles.itemRechart,
                    data.vis_type === "html-wide" || data.wide
                      ? styles.oneColumn
                      : styles.twoColumns,
                    this.getItemStyle()
                  ]}
                >
                  {this.renderChart(data)}
                </View>
              );
            })}
          </View>
          {this.state.popup && (
            <TouchableOpacity
              style={styles.popupContainer}
              onPress={(evt) => this.togglePopup()}
            >
              {!alba.loadingInBg ? (
                <View style={styles.popup}>
                  <TouchableWithoutFeedback>
                    {alba.alert !== null ? (
                      <View style={styles.popupInner}>
                        <View style={styles.popupTitleContainer}>
                          <Text style={styles.popupTitle}>
                            {alba.alert.title}
                          </Text>
                        </View>
                        <ImageResponsive
                          source={alba.alert.image}
                          label={alba.alert.title}
                          minHeight={200}
                        />
                        <View style={styles.popupContent}>
                          <div
                            className="htmlText"
                            dangerouslySetInnerHTML={{
                              __html: decode(alba.alert.text)
                            }}
                          />
                        </View>
                        <View style={styles.spaceBtn} />
                        <Button
                          upper
                          title={langProps.source || ""}
                          background={Colors.pink}
                          onPress={() => this.openSource(alba.alert.url)}
                        />
                      </View>
                    ) : (
                      <Text style={styles.popupTitle}>
                        {langProps.no_data || ""}
                      </Text>
                    )}
                  </TouchableWithoutFeedback>
                </View>
              ) : (
                <Loading />
              )}
            </TouchableOpacity>
          )}
          <MultiLang lang={home.language} />
        </View>
      );
    }

    return null;
  };

  render() {
    if (alba.loadingInsights) {
      return (
        <View style={styles.loader}>
          <Loading small />
        </View>
      );
    }

    return (
      <View style={styles.container} id={`insightSection`}>
        {this.renderContent()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  loader: {
    minHeight: 528
  },
  iframe: {
    flex: 1,
    width: "100%",
    height: "100%",
    borderWidth: 0
  },
  popupContainer: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.35)",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center"
  },
  popup: {
    width: 400,
    borderRadius: 3,
    padding: 15,
    backgroundColor: "#fff",
    boxShadow: "0 0 10px 2px #989898"
  },
  popupInner: {
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: 500
  },
  popupTitle: {
    ...Fonts.style.subtitle
  },
  popupTitleContainer: {
    marginBottom: 10
  },
  popupText: {
    ...Fonts.style.text
  },
  popupContent: {
    marginTop: 15
  },
  container: {
    backgroundColor: Colors.white,
    paddingBottom: 150,
    position: "relative"
  },
  title: {
    marginBottom: 30
  },
  titleText: {
    ...Fonts.style.underline,
    fontSize: 26
  },
  subtitle: {
    ...Fonts.style.labelTitle,
    marginBottom: 40
  },
  list: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  listSm: {
    flexDirection: "column",
    alignItems: "center",
    borderBottomWidth: 0,
    borderBottomColor: "transparent"
  },
  twoColumns: {
    width: "50%"
  },
  oneColumn: {
    width: "100%"
  },
  item: {
    alignItems: "center",
    paddingHorizontal: 30,
    paddingVertical: 30,
    minHeight: 800,
    overflowY: "hidden",
    position: "relative"
  },
  itemRechart: {
    minHeight: 500
  },
  itemSm: {
    width: "100%",
    flex: 1,
    paddingHorizontal: 15,
    borderRightWidth: 0,
    borderRightColor: "transparent",
    borderBottomWidth: 1
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: Colors.border
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.border
  },
  spaceBtn: {
    marginBottom: 20
  }
});

export default withLang({
  es: {
    insights: "dashboard",
    source: "fuente",
    no_data: "No se encontraron datos."
  },
  en: {
    insights: "insights",
    source: "source",
    no_data: "No data found."
  }
})(observer(Insights));
