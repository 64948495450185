import React, { Component } from 'react'
import { View } from 'react-native'

export default class Checkbox extends Component {
  render () {
    return (
      <View>
        <label className={`checkbox-container ${this.props.className}`}>
          {this.props.label}
          <input
            type='checkbox'
            checked={this.props.checked}
            value={this.props.checked}
            onChange={this.props.onChange}
          />
          <span className='checkmark'/>
        </label>
      </View>
    )
  }
}
