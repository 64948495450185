const languagesHome = {
  es: {
    welcome: 'Bienvenido',
    alba_provides:
      'Alba proporciona a nuestros socios \ninformación estratégica que les permite \ncomprometerse mejor con sus clientes \ny su entorno.',
    about_title: 'Tecnología + Rigor Cuantitativo + Visualización + Diseño',
    about_desc:
      'Alba es una tecnología de vanguardia que proporciona soluciones a través de Data Science. Creamos sistemas altamente eficientes que se adaptan y se adaptan al entorno de nuestros clientes. Nuestra tecnología permite la descripción, predicción y detección de anomalías, al tiempo que puede influir en su comportamiento.',
    about_desc_2:
      'Proporcionamos información relevante y concisa, permitiendo la toma de decisiones basada en datos de nuestros clientes.',
    insights: 'dashboard',
    alerts: 'alertas',
    reports: 'reportes',
    feeds: 'interacciones',
    contact_us: 'contáctanos',
    drop_line: '¡tira una línea!',
    name: 'nombre',
    email: 'correo',
    company: 'compañía',
    message: 'mensaje',
    submit: 'enviar',
    about_us: 'Nosotros',
    logout: 'Salir',
    login: 'Entrar',
    keywords: 'palabras clave',
    home: 'inicio',
    management: 'administración',
    unread: 'No leído',
    outcome: 'De salida',
    further_review: 'En Revisión',
    cancel: 'Cancelar',
    feedback: 'Opinión',
    give_feedback: 'Dar Opinión',
    status: 'estado',
    comment: 'comentario',
    saving_amount: 'monto de ahorro',
    required: 'requerido',
    opt_true_alert: 'Alerta verdadera',
    opt_false_alert: 'Alerta falsa',
    opt_further_review: 'En revisión',
    view_all: 'ver todos',
    folio: 'No. Folio',
    date: 'Fecha',
    alert: 'alerta',
    source: 'fuente',
    author: 'autor',
    theme: 'tema',
    reforms: 'reformas',
    links: 'vínculos',
    tables: 'tablas',
    comments: 'comentarios',
    body: 'cuerpo',
    speakers: 'participantes',
    type: 'tipo',
    subtitle: 'subtítulo',
    description: 'descripción',
    feeling: 'sentimiento',
    gender: 'género',
    reporter: 'persona que reporta',
    argument: 'argumento',
    relevant_actors: 'actores relevantes',
    no_data: 'No se encontraron datos.',
    title: 'título',
    text: 'texto',
    url: 'url',
    action: 'acción',
    view: 'ver',
    send: 'enviar',
    delete: 'borrar',
    clear: 'limpiar',
    add_keyword: 'agregar palabra',
    hide_keywords: 'esconder palabra',
    view_keywords: 'ver keywords',
    no_keywords: 'No se encontraron palabras.',
    enter_keywords: 'agregar palabras',
    add_similar_keywords: 'agregar palabras similares',
    distance_between_keywords: 'distancia entre palabras',
    should_not_contain: 'no debe contener',
    advanced: 'avanzado',
    simple: 'simple',
    add: 'agregar',
    strict: 'estricto',
    fuzzy: 'borroso',
    send_alert: 'enviar alerta',
    select_similar: 'seleccionar similar',
    select_alert: 'seleccionar alerta',
    select_distance: 'seleccionar distancia',
    priority: 'prioridad',
    high: 'alto',
    medium: 'medio',
    low: 'bajo',
    order_by: 'ordenar por',
    search: 'buscar',
    independent_words: 'palabras independientes',
    filter_by_date: 'filtrar por fecha',
    filter_by_amount: 'filtrar por cantidad',
    filter_by_impact: 'filtrar por impacto',
    filter_by_source: 'filtrar por fuente',
    filter_by_author: 'filtrar por autor',
    all_impacts: 'todos los impactos',
    all_sources: 'todos las fuentes',
    all_authors: 'todos loa autores',
    from: 'desde',
    to: 'hasta',
    apply: 'aplicar',
    filters: 'filtros',
    export_to_excel: 'exportar a excel',
    export_all_to_excel: 'exportar todo a excel',
    on_review: 'en revisión',
    delete_keyword: 'Borrar palabra',
    delete_keyword_message: 'Estás seguro de borrar la palabra?.',
    _strict: 'estricto \nvs. \nborroso',
    _distance_between_keywords: 'distancia \nentre \npalabras',
    _should_not_contain: 'no debe \ncontener',
    can_estimate: '¿se puede estimar el monto?',


    overview: 'overview'
  },
  en: {
    welcome: 'Welcome',
    alba_provides:
      'Alba provides our partners\nstrategic information that allows them\nto better engage with their clients\nand their environment.',
    about_title: 'Technology + Quantitative Rigor + Visualization + Design',
    about_desc:
      'Alba is a cutting edge tech that provides solutions through Data Science. We create highly efficient systems that adapt and scale to the environment of our customers. Our technology empowers the description, predictions and detection og anomalies while being able to influence their behavior.',
    about_desc_2: 'We provide relevant and concise information, enabling our client´s data driven decision making.',
    insights: 'insights',
    alerts: 'alerts',
    reports: 'reports',
    feeds: 'feeds',
    contact_us: 'contact us',
    drop_line: 'drop a line!',
    name: 'name',
    email: 'email',
    company: 'company',
    message: 'message',
    submit: 'submit',
    about_us: 'About us',
    logout: 'Log out',
    login: 'Log in',
    keywords: 'keywords',
    home: 'home',
    management: 'management',
    unread: 'Unread',
    outcome: 'Outcome',
    further_review: 'Further Review',
    cancel: 'Cancel',
    feedback: 'Feedback',
    give_feedback: 'Give Feedback',
    status: 'status',
    comment: 'comment',
    saving_amount: 'saving amount',
    required: 'required',
    opt_true_alert: 'True alert',
    opt_false_alert: 'False alert',
    opt_further_review: 'Further review',
    view_all: 'view all',
    folio: 'Folio',
    date: 'Date',
    alert: 'alert',
    source: 'source',
    author: 'author',
    theme: 'theme',
    reforms: 'reforms',
    links: 'links',
    tables: 'tables',
    comments: 'comments',
    body: 'body',
    speakers: 'speakers',
    type: 'type',
    subtitle: 'subtitle',
    description: 'description',
    feeling: 'feeling',
    gender: 'gender',
    reporter: 'reporter',
    argument: 'argument',
    relevant_actors: 'relevant actors',
    no_data: 'No data found.',
    title: 'title',
    text: 'text',
    url: 'url',
    action: 'action',
    view: 'view',
    send: 'send',
    delete: 'delete',
    clear: 'clear',
    add_keyword: 'add keyword',
    hide_keywords: 'hide keywords',
    view_keywords: 'view keywords',
    no_keywords: 'No keywords found.',
    enter_keywords: 'enter keywords',
    add_similar_keywords: 'add similar keyword',
    distance_between_keywords: 'distance between keywords',
    should_not_contain: 'should not contain',
    advanced: 'advanced',
    simple: 'simple',
    add: 'add',
    strict: 'strict',
    fuzzy: 'fuzzy',
    send_alert: 'send alert',
    select_similar: 'select similar',
    select_alert: 'select alert',
    select_distance: 'select distance',
    priority: 'priority',
    high: 'high',
    medium: 'medium',
    low: 'low',
    order_by: 'order by',
    search: 'search',
    independent_words: 'independent words',
    filter_by_date: 'filter by date',
    filter_by_amount: 'filter by amount',
    filter_by_impact: 'filter by impact',
    filter_by_source: 'filter by source',
    filter_by_author: 'filter by author',
    all_impacts: 'all impacts',
    all_sources: 'all sources',
    all_authors: 'all authors',
    from: 'from',
    to: 'to',
    apply: 'apply',
    filters: 'filters',
    export_to_excel: 'export to excel',
    export_all_to_excel: 'export all to excel',
    on_review: 'on review',
    delete_keyword: 'Delete keyword',
    delete_keyword_message: 'Are you sure to delete this keyword?.',
    _strict: 'strict \nvs. \nfuzzy',
    _distance_between_keywords: 'distance \nbetween \nkeyword',
    _should_not_contain: 'should not \ncontain',
    can_estimate: 'can you estimate the amount?',


    overview: 'overview'
  }
}

export default languagesHome
