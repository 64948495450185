import React from 'react'

import { View, Text, StyleSheet } from 'react-native'

import Table from './Table'

class Tables extends React.Component {
  render () {
    const list = this.props.data
    console.log(list)
    return (
      <View style={styles.box}>
        <View style={styles.title}>
          <Text style={styles.texTitle}>{this.props.title}</Text>
        </View>
        <View style={styles.subTitle}>
          <Text style={styles.texSubTitle}>{this.props.subTitle}</Text>
        </View>
        <View style={styles.table}>
          {list.map((item, index) => (
            <Table data={item.data} textTitle={'TITULO'} title={'Lorem ipsum dolor sit amet,'}/>
          ))}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  box: {
    margin: 5,
    padding: 40,
    paddingBottom: 50,
    flex: 1,
    borderWidth: 1,
    borderColor: '#7E15E7'
  },
  title: {
    flex: 1
  },
  texTitle: {
    fontSize: 25,
    color: '#72687C'
  },
  subTitle: {
    alignItems: 'center',
    padding: 10
  },
  texSubTitle: {
    fontSize: 20,
    color: '#72687C'
  },

  table: {
    flex: 1,
    flexDirection: 'row'
  }
})

export default Tables
