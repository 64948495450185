const colors = {
  primary: '#31B4B5',
  text: '#918592',
  purple: '#3f2e56',
  purpleTrans: 'rgba(63, 46, 86, 0.7)',
  pink: '#ec8086',
  pinkTrans: 'rgba(236, 128, 134, 0.7)',
  yellow: '#e4a60e',
  yellowTrans: 'rgba(228, 166, 14, 0.7)',
  blue: '#207cca',
  green: '#44797B',
  red: '#F91313',
  violet: '#9F1898',
  disabled: '#AAAAAA',
  border: '#968694',
  placeholder: '#DDDDDD',
  shadow: 'rgba(0,0,0, 0.29)',
  flow: '#f3f3f4',
  sidebar: 'rgba(243, 243, 244, 0.9)',
  navBar: 'rgba(255, 255, 255, 0.9)',
  loading: 'rgba(243, 243, 244, 0.8)',
  keywordsPurplePrimaryBg: 'rgba(63, 46, 86, 0.93)',
  keywordsPurpleSecondaryBg: 'rgba(63, 46, 86, 0.98)',
  keywordsGreenPrimaryBg: 'rgba(68, 121, 123, 0.90)',
  keywordsGreenSecondaryBg: '#659193',
  white: '#FFFFFF'
}

export default colors
