import AuthService from "../Services/AuthService";
import AlbaModel from "../Containers/HomeAdmin/alba";
import { home, languages } from "../Containers/HomeAdmin/model";
import AlbaAPI from "../Services/AlbaAPI";
import OperationModel from "../Containers/Keywords/model";
import ManagementModel from "../Containers/Management/model";
import Endpoints from "../Services/Endpoints";
import Feedback from "../Containers/Feedback/model";

const auth = new AuthService();
const api = new AlbaAPI();
const alba = new AlbaModel();
const operation = new OperationModel();
const management = new ManagementModel();
const endpoints = new Endpoints();
const feedback = new Feedback();

export {
  auth,
  home,
  languages,
  api,
  alba,
  operation,
  management,
  endpoints,
  feedback
};
