import { makeAutoObservable, runInAction } from "mobx";
import { home, endpoints, auth } from "../../Model";

export default class ManagementModel {
  loading = false;
  processing = false;
  tabSelected = 0;
  readAlerts = [];
  unreadAlerts = [];
  onReviewAlerts = [];
  outcomeAlerts = [];
  unreadAlertsTotal = 0;
  onReviewAlertsTotal = 0;
  outcomeAlertsTotal = 0;
  unreadAlertsOffset = 0;
  onReviewAlertsOffset = 0;
  outcomeAlertsOffset = 0;
  perPage = 3;
  urlExcel = "";
  outcomeFilter = "all";

  config = {
    unread: {
      initPage: 0,
      page: 0,
      step: 50
    },
    onreview: {
      initPage: 0,
      page: 0,
      step: 50
    },
    outcome: {
      initPage: 0,
      page: 0,
      step: 50
    }
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetConfigPages = () => {
    this.config.unread.page = 0;
    this.config.onreview.page = 0;
    this.config.outcome.page = 0;
  };

  resetLists = () => {
    this.readAlerts = [];
    this.unreadAlerts = [];
    this.onReviewAlerts = [];
    this.outcomeAlerts = [];
  };

  getPaginatedItems(items, offset) {
    return items.slice(offset, offset + this.perPage);
  }

  apply = async () => {
    this.loading = true;

    const payload = this.getFiltersPayload();

    home.filtering = true;
    home.searched = true;

    try {
      await this.fetchAlerts("read", false, payload);
      // await this.fetchAlerts('read', true, payload)
      await this.fetchAlerts("on_review", true, payload);
      await this.fetchAlerts("outcome", "all", payload);

      this.loading = false;
      this.filtering = false;
    } catch (e) {
      this.loading = false;
      this.filtering = false;
    }
  };

  getFiltersPayload = () => {
    const currentTopic = localStorage.getItem("Topic");
    var payload = {
      Authorization: `${auth.getToken()}`,
      topic: currentTopic,
      independent_words: home.independents,
      excel: true
    };

    if (home.search.length > 0) payload["search"] = home.search;
    if (home.filterByDate)
      payload["from"] = home.getUnixTimestamp(home.dateFrom);
    if (home.filterByDate) payload["to"] = home.getUnixTimestamp(home.dateTo);
    if (home.filterByAmount)
      payload["range_filter"] = {
        by: "amount",
        range: [parseFloat(home.amountFrom), parseFloat(home.amountTo)]
      };
    if (home.keywords.length > 0)
      payload["keywords"] = home.getKeywordsToPayload(home.keywords);
    if (home.impact.length > 0) payload["impact"] = home.impact;
    if (home.source.length > 0) payload["source"] = home.source;
    if (home.author.length > 0) payload["author"] = home.author;
    if (home.orderBy.length > 0) payload["order_by"] = home.orderBy;

    return payload;
  };

  giveFeedback = async (payload) => {
    try {
      const currentTopic = localStorage.getItem("Topic");
      await endpoints.giveFeedback({
        topic: currentTopic,
        ...payload
      });

      runInAction(() => {
        this.processing = false;
      });
    } catch (e) {
      this.processing = false;
      console.log(e);
    }
  };

  fetchAlerts = async (filter, value, extraData) => {
    try {
      const currentTopic = localStorage.getItem("Topic");
      const payload = {
        Authorization: `${auth.getToken()}`,
        topic: currentTopic,
        [filter]: value,
        ...extraData
      };
      const data = await endpoints.getAlertsByArgs(payload);

      runInAction(() => {
        this.urlExcel = data.data.body.link;
        if (filter === "read") {
          if (value)
            this.readAlerts = this.generateDataList(
              data.data.body.results,
              true
            );
          else {
            this.unreadAlerts = this.generateDataList(
              data.data.body.results,
              true
            );
            this.unreadAlertsOffset = data.data.body.next_offset;
            this.unreadAlertsTotal = data.data.body.total;
            this.config.unread.page = this.unreadAlertsOffset / 50 - 1;
          }
        } else if (filter === "on_review") {
          this.onReviewAlerts = this.generateDataList(
            data.data.body.results,
            true
          );
          this.onReviewAlertsOffset = data.data.body.next_offset;
          this.onReviewAlertsTotal = data.data.body.total;
          this.config.onreview.page = this.onReviewAlertsOffset / 50 - 1;
        } else {
          this.outcomeAlerts = this.generateDataList(
            data.data.body.results,
            true
          );
          this.outcomeAlertsOffset = data.data.body.next_offset;
          this.outcomeAlertsTotal = data.data.body.total;
          this.config.outcome.page = this.outcomeAlertsOffset / 50 - 1;
        }
      });
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  };

  getAllAlerts = () => {
    return [
      ...this.readAlerts,
      ...this.unreadAlerts,
      ...this.onReviewAlerts,
      ...this.outcomeAlerts
    ];
  };

  generateDataList = (results, index) => {
    const list = results.map((item, i) => {
      item["key"] = index ? parseInt(i) + 1 : i;
      item.date =
        item.date && item.date !== undefined ? new Date(item.date * 1000) : "";
      item.title = item.title && item.title !== undefined ? item.title : "";
      item.image = item.image && item.image !== undefined ? item.image : "";
      item.text = item.text && item.text !== undefined ? item.text : "";
      item.url = item.url && item.url !== undefined ? item.url : null;

      return item;
    });

    return list;
  };

  getAlerts = (selected) => {
    if (selected === 0) return this.unreadAlerts;
    else if (selected === 1) return this.readAlerts;
    else if (selected === 2) return this.onReviewAlerts;
    else return this.outcomeAlerts;
  };

  loadMoreAlerts = async (currentPage) => {
    try {
      this.loading = true;
      let newOffset = 0;

      if (this.tabSelected === 0) {
        this.unreadAlerts = [];
        this.config.unread.page = currentPage;
        newOffset = this.config.unread.page * 50;
      }

      if (this.tabSelected === 2) {
        this.onReviewAlerts = [];
        this.config.onreview.page = currentPage;
        newOffset = this.config.onreview.page * 50;
      }

      if (this.tabSelected === 3) {
        this.outcomeAlerts = [];
        this.config.outcome.page = currentPage;
        newOffset = this.config.outcome.page * 50;
      }

      const payload = this.getFiltersPayload();
      let dataAlert = {};

      if (this.tabSelected === 0) {
        dataAlert = await endpoints.getAlertsByArgs({
          read: false,
          offset: newOffset,
          ...payload
        });
      }

      if (this.tabSelected === 2) {
        dataAlert = await endpoints.getAlertsByArgs({
          on_review: true,
          offset: newOffset,
          ...payload
        });
      }

      if (this.tabSelected === 3) {
        dataAlert = await endpoints.getAlertsByArgs({
          outcome: "all",
          offset: newOffset,
          ...payload
        });
      }

      runInAction(() => {
        if (this.tabSelected === 0) {
          this.unreadAlerts = this.generateDataList(
            dataAlert.data.body.results,
            true
          );
          this.unreadAlertsOffset = dataAlert.data.body.next_offset;
          this.unreadAlertsTotal = dataAlert.data.body.total;
          this.config.unread.page = this.unreadAlertsOffset / 50 - 1;
        }

        if (this.tabSelected === 2) {
          this.onReviewAlerts = this.generateDataList(
            dataAlert.data.body.results,
            true
          );
          this.onReviewAlertsOffset = dataAlert.data.body.next_offset;
          this.onReviewAlertsTotal = dataAlert.data.body.total;
          this.config.onreview.page = this.onReviewAlertsOffset / 50 - 1;
        }

        if (this.tabSelected === 3) {
          this.outcomeAlerts = this.generateDataList(
            dataAlert.data.body.results,
            true
          );
          this.outcomeAlertsOffset = dataAlert.data.body.next_offset;
          this.outcomeAlertsTotal = dataAlert.data.body.total;
          this.config.outcome.page = this.outcomeAlertsOffset / 50 - 1;
        }

        // this.alerts = this.generateDataList(dataAlert.data.body.results, true)
        // this.offsetAlerts = dataAlert.data.body.next_offset
        // this.config.alert.initPage = this.offsetAlerts - 50
        // this.config.alert.page = 0
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  getAlertsConfig = (selected) => {
    if (selected === 0) {
      return {
        initPage: this.config.unread.initPage,
        page: this.config.unread.page,
        step: this.config.unread.step,
        items: this.unreadAlerts,
        total: this.unreadAlertsTotal,
        tab: "unread"
      };
    } else if (selected === 1) return {};
    else if (selected === 2) {
      return {
        initPage: this.config.onreview.initPage,
        page: this.config.onreview.page,
        step: this.config.onreview.step,
        items: this.onReviewAlerts,
        total: this.onReviewAlertsTotal,
        tab: "onreview"
      };
    } else {
      return {
        initPage: this.config.outcome.initPage,
        page: this.config.outcome.page,
        step: this.config.outcome.step,
        items: this.outcomeAlerts,
        total: this.outcomeAlertsTotal,
        tab: "outcome"
      };
    }
  };
}
