import React, { Component } from "react";
import { Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import { NumericFormat } from "react-number-format";

import UnderlinedText from "../../Components/UnderlinedText";
import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

class SectionsListItem extends Component {
  getContainerStyle = () => {
    if (this.props.screen.w <= 768) return styles.containerSm;
    else return {};
  };

  render() {
    return (
      <TouchableOpacity
        style={[styles.container, this.getContainerStyle()]}
        onPress={this.props.onPress}
        disabled={this.props.value === 0}
      >
        <Image source={this.props.icon} style={styles.icon} />
        <UnderlinedText
          upper
          center
          style={[
            styles.title,
            this.props.color ? { color: this.props.color } : null
          ]}
          text={this.props.text}
          place={this.props.underline}
          color={this.props.color ? this.props.color : Colors.white}
        />
        <NumericFormat
          value={this.props.value}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => <Text style={styles.text}>{value}</Text>}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    marginHorizontal: 50,
    alignItems: "center"
  },
  containerSm: {
    marginHorizontal: 15,
    marginVertical: 15
  },
  title: {
    ...Fonts.style.text,
    color: Colors.white,
    fontSize: 14,
    letterSpacing: 1,
    textAlign: "center"
  },

  text: {
    ...Fonts.style.boldText,
    color: Colors.white,
    fontSize: 28,
    textAlign: "center",
    marginTop: 10
  },
  icon: {
    width: 70,
    height: 70,
    marginBottom: 10
  }
});

export default SectionsListItem;
