const configuration = () => ({
  storage: window.localStorage,
  expireIn: 86400000,
  removeOnExpiration: true,
  stringify: false,
  debugMode: true
});

const options = () => ({
  delay: 200,
  fireImmediately: false
});

export const mobxPersistStore = {
  configration: configuration(),
  options: options()
};
