import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react";

import FeedbackTabItem from "./tabItem";

class FeedbackTab extends Component {
  render() {
    return (
      <View style={styles.tabContainer}>
        <FeedbackTabItem
          tabSelected={this.props.tabSelected}
          screen={this.props.screen}
          handleGiveFeedback={this.props.handleGiveFeedback}
          viewAlert={this.props.viewAlert}
          lang={this.props.lang}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tabContainer: {
    paddingTop: 0
  }
});

export default observer(FeedbackTab);
