import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  Label,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { alba } from "../../Model";

const chartStyles = {
  container: {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  },
  label: {
    ...Fonts.style.labelTitle,
    textAnchor: "middle"
  }
};

class LineChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLines: {}
    };
  }

  componentDidMount() {
    const plotData = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;
    var selectedLines = {};

    plotData.points_config.map((config, i) => {
      selectedLines[config.key] = true;
    });

    this.setState({ selectedLines });
  }

  formatter = (decimal, fixed = 0) => {
    const data = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;
    const format = data.y_axis_format ? data.y_axis_format : false;
    return alba.axisFormatter(decimal, format);
  };

  handleClickLegend = (event) => {
    let selectedLines = this.state.selectedLines;
    selectedLines[event.dataKey.trim()] = !selectedLines[event.dataKey.trim()];
    this.setState({ selectedLines });
  };

  renderContent() {
    if (alba.loading) {
      return <View />;
    }

    const data = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;

    return (
      <ResponsiveContainer className="bar-chart-component">
        <LineChart
          data={data.points_data.toJS()}
          margin={chartStyles.container}
        >
          <XAxis tick={{ fontSize: 12 }} dataKey="x_axis_val">
            <Label position="bottom" style={chartStyles.label}>
              {data.xaxislabel}
            </Label>
          </XAxis>
          <YAxis tick={{ fontSize: 12 }} tickFormatter={this.formatter}>
            <Label angle={270} position="left" style={chartStyles.label}>
              {data.yaxislabel}
            </Label>
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ zIndex: 100 }}
          />
          <Legend
            align="right"
            verticalAlign="top"
            layout="vertical"
            onClick={this.handleClickLegend}
          />
          {data.points_config.map((line, index) => (
            <Line
              connectNulls={true}
              type="monotone"
              dataKey={
                this.state.selectedLines[line.key] ? line.key : `${line.key} `
              }
              key={index}
              stroke={line.color}
              activeDot={{ r: 8 }}
            />
          ))}
          <Brush />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.subtitle}>{this.props.data.title}</Text>
        <View style={styles.content}>{this.renderContent()}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%"
  },
  content: {
    flex: 1,
    height: 306
  },
  subtitle: {
    ...Fonts.style.labelTitle,
    marginBottom: 45
  },
  tooltip: {
    backgroundColor: Colors.white,
    borderColor: "#999",
    borderWidth: 1,
    margin: 0,
    padding: 10,
    maxWidth: 200,
    zIndex: 9999
  }
});

export default observer(LineChartComponent);
