import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import { withLang } from "react-multi-language";

import languagesHome from "../../Languages/languagesHome";
import AlertsTab from "./AlertsTab";
import ReportsTab from "./ReportsTab";
import FeedsTab from "./FeedsTab";

class ManagementTab extends Component {
  renderContent = () => {
    if (this.props.tabSelected === 0)
      return (
        <AlertsTab
          tabSelected={this.props.tabSelected}
          screen={this.props.screen}
          lang={this.props.lang}
        />
      );
    else if (this.props.tabSelected === 1)
      return (
        <ReportsTab
          tabSelected={this.props.tabSelected}
          screen={this.props.screen}
          lang={this.props.lang}
        />
      );
    else
      return (
        <FeedsTab
          tabSelected={this.props.tabSelected}
          screen={this.props.screen}
          lang={this.props.lang}
        />
      );
  };

  render() {
    return <View style={styles.tabContainer}>{this.renderContent()}</View>;
  }
}

const styles = StyleSheet.create({
  tabContainer: {
    paddingTop: 0
  }
});

export default withLang(languagesHome)(observer(ManagementTab));
