import { makeAutoObservable } from "mobx";
import { alba, auth, operation } from "../../Model";

export const languages = [
  { key: "es", text: "Español" },
  { key: "en", text: "English" }
];

class HomeAdminModel {
  dateFrom = new Date();
  dateTo = new Date();
  search = "";
  keywords = [];
  impact = "";
  source = "";
  author = "";
  searched = false;
  filtering = false;
  theme = "TESLA";
  themes = ["TESLA", "SPACEX", "BORING COMPANY", "COMPETITORS"];
  themeDrop = false;
  sidebarOpen = false;
  mobileHeader = false;
  filterByDate = false;
  filterByAmount = false;
  independents = false;
  menus = [
    { text: "keywords", to: "/keywords" },
    { text: "management", to: "/management" }
  ];
  subtopics = [
    { text: "overview", to: "/dashboard" },
    { text: "", to: "" },
    {
      text: "alerts",
      to: "/inbox"
    },
    { text: "reports", to: "/inbox" },
    { text: "feeds", to: "/inbox" },
    { text: "insights", to: "/inbox" }
  ];
  menuDrop = false;
  orderBy = "";
  amountFrom = "";
  amountTo = "";
  languageDrop = false;
  subtopicDrop = false;
  language = localStorage.getItem("Lang") || "es";
  languages = languages;

  constructor() {
    makeAutoObservable(this);
  }

  get getShowFilters() {
    return operation.showFiltersDdl[operation.topic];
  }

  get getOrders() {
    return operation.ordersDdl[operation.topic];
  }

  get isComplete() {
    return this.dateFrom && this.dateTo && this.keywords && this.impact;
  }

  reset = () => {
    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.search = "";
    this.keywords = [];
    this.impact = "";
    this.source = "";
    this.author = "";
    this.orderBy = "";
    this.amountFrom = "";
    this.amountTo = "";
    this.filterByDate = false;
    this.filterByAmount = false;
    this.independents = false;
  };

  toggleLanguageDrop = () => {
    this.languageDrop = !this.languageDrop;
  };

  apply = () => {
    const currentTopic = localStorage.getItem("Topic");
    var payload = {
      Authorization: `${auth.getToken()}`,
      topic: currentTopic,
      independent_words: this.independents,
      excel: true
    };

    if (this.search.length > 0) payload["search"] = this.search;
    if (this.filterByDate)
      payload["from"] = this.getUnixTimestamp(this.dateFrom);
    if (this.filterByDate) payload["to"] = this.getUnixTimestamp(this.dateTo);
    if (this.filterByAmount)
      payload["range_filter"] = {
        by: "amount",
        range: [parseFloat(this.amountFrom), parseFloat(this.amountTo)]
      };
    if (this.keywords.length > 0)
      payload["keywords"] = this.getKeywordsToPayload(this.keywords);
    if (this.impact.length > 0) payload["impact"] = this.impact;
    if (this.source.length > 0) payload["source"] = this.source;
    if (this.author.length > 0) payload["author"] = this.author;
    if (this.orderBy.length > 0) payload["order_by"] = this.orderBy;

    this.filtering = true;
    this.searched = true;
    alba.getDataByArgs(payload).then(() => {
      this.filtering = false;
    });
  };

  exportAllExcel = () => {
    const currentTopic = localStorage.getItem("Topic");
    const user = auth.getProfile();
    var payload = {
      Authorization: `${auth.getToken()}`,
      user: {
        name: user.name,
        email: user.email,
        picture: user.picture
      },
      topic: currentTopic,
      independent_words: this.independents,
      excel: true,
      complete_excel: true
    };

    if (this.search.length > 0) payload["search"] = this.search;
    if (this.filterByDate)
      payload["from"] = this.getUnixTimestamp(this.dateFrom);
    if (this.filterByDate) payload["to"] = this.getUnixTimestamp(this.dateTo);
    if (this.filterByAmount)
      payload["range_filter"] = {
        by: "amount",
        range: [parseFloat(this.amountFrom), parseFloat(this.amountTo)]
      };
    if (this.keywords.length > 0)
      payload["keywords"] = this.getKeywordsToPayload(this.keywords);
    if (this.impact.length > 0) payload["impact"] = this.impact;
    if (this.source.length > 0) payload["source"] = this.source;
    if (this.author.length > 0) payload["author"] = this.author;
    if (this.orderBy.length > 0) payload["order_by"] = this.orderBy;

    alba.exportAllExcel(payload);
  };

  get showExport() {
    return this.search.length > 0 && this.searched;
  }

  get urlExcel() {
    return alba.excel;
  }

  getKeywordsToPayload = (keywords) => {
    var list = [];
    keywords.map((key, i) => {
      list.push(key.name);
      return null;
    });

    return list;
  };
  setFavorite = () => {};

  addDays = (date, days) => {
    let dat = date;
    return new Date(dat.setDate(dat.getDate() + days));
  };

  getUnixTimestamp = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
      date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    return Math.round(date.getTime() / 1000);
  };
}

const home = new HomeAdminModel();

export { home };
