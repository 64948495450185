import React, { Component } from "react";
import { Dimensions, StyleSheet } from "react-native";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";

import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import Keywords from "../../Components/Keywords";
import { withRouter } from "../../Navigation/withRouter";

import { auth, operation } from "../../Model";
import Footer from "../../Components/Footer";

class KeywordsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: {
        w: 0,
        h: 0
      }
    };
  }

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    operation.getData();
    window.addEventListener("resize", debounce(this.updateDimensions, 300));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  logout = () => {
    auth.logout();
    this.props.navigate("/");
  };

  render() {
    if (!auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/" }} />;
    }

    return (
      <Wrapper>
        <Header
          screen={this.state.screen}
          isLogged={auth.loggedIn()}
          onLogout={this.logout}
          noSidebar
        />
        <Keywords screen={this.state.screen} />
        <Footer style={styles.footer} simple />
      </Wrapper>
    );
  }
}

const styles = StyleSheet.create({
  footer: {
    position: "fixed",
    bottom: 0,
    padding: 15,
    backgroundColor: "#fff",
    left: 0,
    right: 0,
    boxShadow: "-5px 0px 10px #999"
  }
});

export default withRouter(observer(KeywordsContainer));
