import React, { Component } from "react";
import { View, ImageBackground, StyleSheet, Text } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import Container from "../../Components/Container";
import background from "../../Images/bg-degraded.jpg";
import Colors from "../../Themes/Colors";
import Fonts from "../../Themes/Fonts";

import { home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

/**
 * This components serves as About-information at the login page.
 */

class HomeAbout extends Component {
  reduceTitle = () => {
    if (this.props.screen.w <= 768) return styles.titleSm;
    else return {};
  };

  reduceText = () => {
    if (this.props.screen.w <= 768) return styles.textSm;
    else return {};
  };

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.container} id={`aboutUsSection`}>
        <ImageBackground
          source={background}
          resizeMode="cover"
          style={styles.background}
        >
          <View style={styles.innerContainer}>
            <Container>
              <Text style={[styles.title, this.reduceTitle()]}>
                {langProps.about_title || ""}
              </Text>
              <View style={styles.textContainer}>
                <Text style={[styles.text, this.reduceText()]}>
                  {langProps.about_desc || ""}
                </Text>
                <Text
                  style={[styles.text, { marginBottom: 0 }, this.reduceText()]}
                >
                  {langProps.about_desc_2 || ""}
                </Text>
              </View>
            </Container>
          </View>
        </ImageBackground>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minHeight: 540
  },
  background: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  innerContainer: {
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    ...Fonts.style.titleArvo,
    color: Colors.white
  },
  textContainer: {
    marginTop: 60
  },
  text: {
    ...Fonts.style.text,
    color: Colors.white,
    fontSize: 18,
    marginBottom: 30,
    lineHeight: 22,
    textAlign: "justify"
  },
  titleSm: {
    fontSize: 18
  },
  textSm: {
    fontSize: 14
  }
});

export default withLang(languagesHome)(observer(HomeAbout));
