import Colors from "./Colors"

const type = {
  light: "300",
  base: "400",
  semi: "600",
  bold: "700"
}

const family = {
  montse: "Montserrat",
  arvo: "Arvo"
}

const style = {
  title: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 20,
    color: Colors.text,
    letterSpacing: 3
  },
  titleArvo: {
    backgroundColor: "transparent",
    fontWeight: type.bold,
    fontFamily: family.arvo,
    fontSize: 30,
    color: Colors.text,
    letterSpacing: 1
  },
  subtitle: {
    backgroundColor: "transparent",
    fontWeight: type.bold,
    fontFamily: family.montse,
    fontSize: 18,
    color: Colors.text
  },
  underline: {
    backgroundColor: "transparent",
    fontFamily: family.montse,
    fontWeight: type.base,
    fontSize: 14,
    color: Colors.text,
    letterSpacing: 2
  },
  text: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 14,
    lineHeight: 18,
    color: Colors.text,
    letterSpacing: 0.5
  },
  smallText: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 12,
    lineHeight: 16,
    color: Colors.text,
    letterSpacing: 0.5
  },
  textPink: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 14,
    color: Colors.pink
  },
  textYellow: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 14,
    color: Colors.yellow
  },
  boldText: {
    backgroundColor: "transparent",
    fontWeight: type.bold,
    fontFamily: family.montse,
    fontSize: 14,
    color: Colors.text
  },
  smallBoldText: {
    backgroundColor: "transparent",
    fontWeight: type.bold,
    fontFamily: family.montse,
    fontSize: 12,
    color: Colors.text
  },
  label: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 14,
    lineHeight: 18,
    color: Colors.purple,
    letterSpacing: 0.5
  },
  labelTitle: {
    backgroundColor: "transparent",
    fontWeight: type.bold,
    fontFamily: family.arvo,
    fontSize: 14,
    lineHeight: 18,
    color: Colors.purple,
    letterSpacing: 0.5
  },
  input: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 14,
    color: Colors.purple,
    letterSpacing: 0.5
  },
  navBar: {
    backgroundColor: "transparent",
    fontWeight: type.base,
    fontFamily: family.montse,
    fontSize: 14,
    color: Colors.text,
    letterSpacing: 0.5
  }
}

export default {
  type,
  family,
  style
}
