import React, { Component } from "react";
import { Dimensions } from "react-native";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import Sidebar from "react-sidebar";
import debounce from "lodash/debounce";

import SidebarContent from "../../Components/SidebarContent";
import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import ReportList from "../../Components/ReportList";
import { withRouter } from "../../Navigation/withRouter";

import { auth } from "../../Model";

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: {
        w: 0,
        h: 0
      },
      sidebarOpen: false,
      isLogged: false
    };
  }

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", debounce(this.updateDimensions, 300));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  logout = () => {
    auth.logout();
    this.props.navigate("/");
  };

  render() {
    if (!auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/" }} />;
    }

    return (
      <Sidebar
        sidebar={<SidebarContent />}
        open={this.state.sidebarOpen}
        onSetOpen={this.toggleSidebar}
        pullRight={true}
        styles={{ content: { overflowY: "auto" } }}
      >
        <Wrapper isLogged={auth.loggedIn()}>
          <Header
            screen={this.state.screen}
            showSidebar={this.toggleSidebar}
            isLogged={auth.loggedIn()}
            onLogout={this.logout}
          />
          <ReportList all screen={this.state.screen} />
        </Wrapper>
      </Sidebar>
    );
  }
}

export default withRouter(observer(Reports));
