import React, { Component } from "react";
import { View, Text, Dimensions, StyleSheet } from "react-native";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import { uploadFile } from "./react-s3/lib/ReactS3";
import debounce from "lodash/debounce";
import Dropzone from "react-dropzone";

import Loading from "../../Components/Loading";
import Wrapper from "../../Components/Wrapper";
import Header from "../../Components/Header";
import Fonts from "../../Themes/Fonts";

import { auth, operation, endpoints } from "../../Model";
import Footer from "../../Components/Footer";

const config = {
  bucketName: "arrival-puts",
  dirName: "arrivals",
  region: "us-west-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
};

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: {
        w: 0,
        h: 0
      },
      sidebarOpen: false,
      isLogged: false,
      loading: false,
      message: "",
      error: ""
    };
  }

  componentDidMount() {
    operation.getData().then((isAuth) => {
      if (!isAuth) {
        this.logout();
      }
    });
    window.addEventListener("resize", debounce(this.updateDimensions, 300));
  }

  logout = () => {
    auth.logout();
    this.props.navigate("/");
  };

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  onDrop(files, _this) {
    _this.setState({ loading: true });
    uploadFile(files[0], config)
      .then(async (data) => {
        if (data.location && data.result && data.result.ok) {
          _this.setState({
            message: "The file has been successfully uploaded!",
            error: "",
            loading: false
          });
          await endpoints.registerUpload({
            Authorization: `${auth.getToken()}`,
            topic: localStorage.getItem("Topic"),
            filename: files[0].name
          });
        } else {
          _this.setState({
            message: "",
            error: "An error has occurred",
            loading: false
          });
        }
        setTimeout(() => {
          _this.setState({ message: "", error: "" });
        }, 10000);
      })
      .catch((err) => {
        _this.setState({
          message: "",
          error: "An error has occurred",
          loading: false
        });
        setTimeout(() => {
          _this.setState({ message: "", error: "" });
        }, 10000);
      });
  }

  render() {
    if (!auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/" }} />;
    }

    const baseStyle = {
      width: "100%",
      height: 200,
      borderWidth: 2,
      borderColor: "#ccc",
      borderStyle: "dashed",
      borderRadius: 5,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      cursor: "pointer"
    };

    const textStyle = {
      ...Fonts.style.text,
      lineHeight: "18px",
      fontSize: 24,
      color: "#ddd"
    };

    return (
      <View>
        <Wrapper>
          <Header
            screen={this.state.screen}
            showSidebar={this.toggleSidebar}
            onLogout={this.logout}
            showTopics
          />
          <View style={styles.content}>
            <View style={styles.inner}>
              <View style={styles.form}>
                <Dropzone onDrop={(files) => this.onDrop(files, this)}>
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <div {...getRootProps()} style={baseStyle}>
                        <input {...getInputProps()} />
                        <p style={textStyle}>
                          Drop files here, or click to select files
                        </p>
                      </div>
                    );
                  }}
                </Dropzone>
                {this.state.loading && (
                  <View style={styles.loader} className="upload-loading">
                    <Loading small fluid />
                  </View>
                )}
                {this.state.message.length > 0 && (
                  <Text style={styles.message}>{this.state.message}</Text>
                )}
                {this.state.error.length > 0 && (
                  <Text style={[styles.message, styles.error]}>
                    {this.state.error}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <Footer style={styles.footer} simple />
        </Wrapper>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    paddingTop: 76
  },
  inner: {
    padding: 30,
    borderStyle: "solid",
    borderTopColor: "#ddd",
    borderTopWidth: 1
  },
  form: {},
  message: {
    ...Fonts.style.text,
    color: "#4eab05",
    lineHeight: "18px",
    fontSize: 20,
    padding: 30,
    textAlign: "center"
  },
  messageError: {
    color: "#ec1616"
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "default",
    zIndex: 99999
  },
  footer: {
    position: "fixed",
    bottom: 0,
    padding: 15,
    backgroundColor: "#fff",
    left: 0,
    right: 0,
    boxShadow: "-5px 0px 10px #999"
  }
});

export default observer(Upload);
