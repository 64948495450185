import React, { Component } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import noImage from '../../Images/no-image.jpg'

var picture = noImage

class ImageResponsive extends Component {
  handleError = () => {
    picture = noImage
  }

  render () {
    picture = this.props.source ? this.props.source : noImage
    return (
      <View
        style={[
          styles.container,
          this.props.style && this.props.style.container,
          this.props.minHeight && { minHeight: this.props.minHeight }
        ]}
      >
        <View style={[styles.mask, this.props.style && this.props.style.mask]}/>
        <View
          style={styles.content}
          className={this.props.style && this.props.style.className ? this.props.style.className : ''}
        >
          <View style={styles.inner}/>
          <Image
            onError={this.handleError}
            source={picture}
            resizeMode={this.props.style && this.props.style.resizeMode ? this.props.style.resizeMode : 'contain'}
            style={styles.image}
            accessibilityLabel={this.props.label}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  mask: {
    paddingBottom: '56.25%',
    width: '100%'
  },
  content: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0
  },
  inner: {
    flex: 1,
    width: '100%',
    height: '100%'
  },
  image: {
    bottom: 0,
    left: 0,
    marginTop: 'auto',
    marginRight: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    height: 'auto'
  }
})

export default ImageResponsive
