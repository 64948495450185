import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";
import Slider from "react-slick";

import Loading from "../../Components/Loading";
import Container from "../../Components/Container";
import Paginator from "../../Components/Paginator";
import UnderlinedText from "../../Components/UnderlinedText";
import ReportItem from "../../Components/ReportItem";
import ArrowSlider from "../ArrowSlider";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import rightArrow from "../../Images/icon-right-arrow.png";
import leftArrow from "../../Images/icon-left-arrow.png";

import { alba, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

var settings = {
  arrows: true,
  dots: false,
  infinite: false,
  swipe: false,
  swipeToSlide: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: "",
  prevArrow: "",
  adaptiveHeight: true
};

class ReportList extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.screen.w <= 768) alba.config.report.step = 1;
    const _this = this;
    const nextArrow = (
      <ArrowSlider
        class="slick-arrow slick-next"
        onPress={_this.onNext}
        source={rightArrow}
      />
    );
    const prevArrow = (
      <ArrowSlider
        class="slick-arrow slick-prev disabled"
        onPress={_this.onPrev}
        source={leftArrow}
      />
    );
    settings.nextArrow = nextArrow;
    settings.prevArrow = prevArrow;
    settings.beforeChange = function (current, next) {
      alba.config.report.page = next;
      _this.updateArrows();
    };
  }

  getListStyle = () => {
    if (this.props.screen.w <= 1024) return styles.listSm;
    else return {};
  };

  onPrev = () => {
    this.slider.slickPrev();
  };

  onNext = () => {
    this.slider.slickNext();
  };

  onPage = (page) => {
    const pageToGo = parseInt(page) * alba.config.report.step;
    this.slider.slickGoTo(pageToGo);
  };

  onSendEmail = (report) => {
    const mailtourl =
      "mailto:?subject=" + report.name + "&body=" + report.abstract;
    window.location.href = mailtourl;
    return false;
  };

  handleFavorite = (item) => {
    console.log(item);
  };

  updateArrows = () => {
    const total = Math.ceil(alba.reports.length / alba.config.report.step);
    const nextArrow = (
      <ArrowSlider
        class={`slick-arrow slick-next ${
          alba.config.report.page >= total - 1 ? "disabled" : ""
        }`}
        onPress={this.onNext}
        source={rightArrow}
      />
    );
    const prevArrow = (
      <ArrowSlider
        class={`slick-arrow slick-prev ${
          alba.config.report.page <= 0 ? "disabled" : ""
        }`}
        onPress={this.onPrev}
        source={leftArrow}
      />
    );
    settings.nextArrow = nextArrow;
    settings.prevArrow = prevArrow;
  };

  renderSlider = () => {
    const { langProps } = this.props;
    if (this.props.screen.w <= 768) {
      settings.slidesToShow = 1;
      settings.slidesToScroll = 1;
      settings.arrows = true;
    } else {
      settings.slidesToShow = 3;
      settings.slidesToScroll = 3;
      if (alba.reports.length <= 3) settings.arrows = false;
    }

    return (
      <Slider ref={(c) => (this.slider = c)} {...settings}>
        {alba.reports.map((item, index) => (
          <div key={index}>
            <ReportItem
              data={item}
              handleFavorite={this.handleFavorite}
              screen={this.props.screen}
              onSendEmail={this.onSendEmail}
              lang={langProps}
            />
          </div>
        ))}
      </Slider>
    );
  };

  renderContent = () => {
    const { langProps } = this.props;
    if (alba.reports.length > 0) {
      return (
        <View
          style={[styles.container, this.props.all ? { paddingTop: 76 } : {}]}
          id={`reportSection`}
        >
          <UnderlinedText
            upper
            center
            style={styles.titleText}
            text={langProps.reports || ""}
            place={1}
            color={Colors.text}
          />
          <Container
            style={this.props.all ? styles.listAll : this.getListStyle()}
          >
            {this.renderSlider()}
            <Paginator
              style={styles.paginator}
              page={alba.config.report.page}
              step={alba.config.report.step}
              onPrev={this.onPrev}
              onNext={this.onNext}
              onPage={this.onPage}
              items={alba.reports}
            />
          </Container>
          <MultiLang lang={home.language} />
        </View>
      );
    }

    return null;
  };

  render() {
    if (alba.reports.length <= 3) {
      settings.arrows = false;
    }

    if (alba.loadingReports) {
      return (
        <View style={styles.loader}>
          <Loading color={Colors.yellow} small />
        </View>
      );
    }

    return this.renderContent();
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.flow
  },
  loader: {
    minHeight: 528
  },
  title: {
    marginTop: 0
  },
  titleText: {
    ...Fonts.style.underline,
    fontSize: 26
  },
  subtitle: {
    ...Fonts.style.text,
    textAlign: "center",
    marginTop: 20
  },
  listSm: {
    paddingHorizontal: 45
  },
  listAll: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  paginator: {
    position: "absolute",
    bottom: 15,
    right: 15
  }
});

export default withLang(languagesHome)(observer(ReportList));
