import React from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "../Containers/Home";
import Dashboard from "../Containers/Dashboard";
import HomeAdmin from "../Containers/HomeAdmin";
import Keywords from "../Containers/Keywords";
import Reports from "../Containers/Reports";
import Management from "../Containers/Management";
import Callback from "../Containers/Callback";
import Feedback from "../Containers/Feedback";
import Upload from "../Containers/Upload";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/inicio",
    element: <HomeAdmin />
  },
  {
    path: "/keywords",
    element: <Keywords />
  },
  {
    path: "/reports",
    element: <Reports />
  },
  {
    path: "/management",
    element: <Management />
  },
  {
    path: "/callback",
    element: <Callback />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/feedback",
    element: <Feedback />
  },
  {
    path: "/inbox",
    element: <Feedback />
  },
  {
    path: "/upload",
    element: <Upload />
  }
]);

const RouterRoot = () => <RouterProvider router={router} />;

export default RouterRoot;
