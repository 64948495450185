import React, { Component } from 'react'
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native'

import Fonts from '../../Themes/Fonts'
import Colors from '../../Themes/Colors'

import prev from '../../Images/icon-left-white-arrow.png'
import next from '../../Images/icon-right-white-arrow.png'

var pages = []
var totalPages = 0
var startPage = 0
var finalPage = 0
var page = 0
const pageRange = 6
const offset = 3

class Paginator extends Component {
  updatePages = () => {
    pages = []
    page = parseInt(this.props.page)
    totalPages = Math.ceil(parseInt(this.props.items.length) / parseInt(this.props.step))

    startPage = offset > page ? 0 : page - offset

    if (startPage === 0) {
      finalPage = pageRange >= totalPages ? totalPages : pageRange
    } else {
      finalPage = pageRange >= totalPages ? totalPages : page + offset
      if (finalPage >= totalPages) finalPage = totalPages
    }

    for (var i = startPage; i < finalPage; i++) {
      pages.push(i)
    }
  }

  renderContent = () => {
    this.updatePages()

    const initPage = this.props.initPage && this.props.initPage >= 0 ? this.props.initPage : 0
    const nextOffset = initPage + 50
    const selectedColor = this.props.selectedColor ? this.props.selectedColor : Colors.purple
    const showNext = this.props.page !== parseInt(totalPages - 1)
    const showPrev = this.props.page !== 0
    const showFinal = this.props.page < totalPages - offset
    const showStart = this.props.page > offset

    if (this.props.items.length >= this.props.step) {
      return (
        <View style={styles.content}>
          {!showPrev &&
          nextOffset > 50 && (
            <TouchableOpacity style={styles.buttonText} onPress={this.props.loadLess}>
              <Text style={styles.textLoad}>Load less</Text>
            </TouchableOpacity>
          )}
          {showPrev && (
            <TouchableOpacity style={styles.button} onPress={this.props.onPrev}>
              <img src={prev} className="arrow-paginator" alt="prev"/>
            </TouchableOpacity>
          )}
          {showPrev &&
          showStart && (
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity style={styles.button} onPress={() => this.props.onPage(0)}>
                <Text style={styles.textLoad}>{initPage + 1}</Text>
              </TouchableOpacity>
              <Text style={styles.textStart}>...</Text>
            </View>
          )}
          {pages.map((pageItem) => (
            <TouchableOpacity
              key={pageItem}
              style={[styles.button, this.props.page === pageItem ? { backgroundColor: selectedColor } : {}]}
              onPress={() => this.props.onPage(pageItem)}
            >
              <Text style={styles.text}>{parseInt(pageItem) + 1 + initPage}</Text>
            </TouchableOpacity>
          ))}
          {showNext &&
          showFinal && (
            <View style={{ flexDirection: 'row', marginLeft: 10 }}>
              <Text style={styles.textFinal}>...</Text>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  this.props.onPage(totalPages - 1)
                }}
              >
                <Text style={styles.textLoad}>{totalPages + initPage}</Text>
              </TouchableOpacity>
            </View>
          )}
          {showNext && (
            <TouchableOpacity style={styles.button} onPress={this.props.onNext}>
              <img src={next} className="arrow-paginator" alt="next"/>
            </TouchableOpacity>
          )}
          {!showNext &&
          nextOffset < this.props.total && (
            <TouchableOpacity style={styles.buttonText} onPress={this.props.loadMore}>
              <Text style={styles.textLoad}>Load more</Text>
            </TouchableOpacity>
          )}
        </View>
      )
    }

    return null
  }

  render () {
    return <View style={[styles.container, this.props.style]}>{this.renderContent()}</View>
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    width: 24,
    height: 24,
    borderRadius: 50,
    backgroundColor: Colors.border,
    marginLeft: 10
  },
  buttonText: {
    paddingHorizontal: 10,
    borderRadius: 50,
    backgroundColor: Colors.border,
    marginLeft: 10
  },
  textLoad: {
    ...Fonts.style.text,
    color: Colors.white,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 24
  },
  textStart: {
    ...Fonts.style.text,
    color: Colors.border,
    textAlign: 'center',
    lineHeight: 24,
    fontSize: 20,
    marginLeft: 10
  },
  textFinal: {
    ...Fonts.style.text,
    color: Colors.border,
    textAlign: 'center',
    lineHeight: 24,
    fontSize: 20
  },
  active: {
    backgroundColor: Colors.purple
  },
  text: {
    width: 24,
    height: 24,
    ...Fonts.style.text,
    color: Colors.white,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 24
  }
})

export default Paginator
