import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react";

import ReportsTabList from "./ReportsTabList";
import { management } from "../../Model";
import Footer from "../Footer";

class ReportsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      offset: 0,
      pageCount: 0
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = () => {
    const data = management.loadReports(management.perPage, this.state.offset);
    this.setState({
      data: data.items,
      pageCount: Math.ceil(data.meta.total_count / data.meta.limit)
    });
  };

  onPage = (data) => {
    let selected = data.selected;
    let newOffset = selected * management.perPage;

    this.setState({ offset: newOffset }, () => {
      this.loadItems();
    });
  };

  render() {
    return (
      <View style={styles.container}>
        <ReportsTabList
          tabSelected={this.props.tabSelected}
          items={this.state.data}
          screen={this.props.screen}
        />

        <Footer
          style={styles.paginator}
          initPage={management.config.reports.initPage}
          page={management.config.reports.page}
          step={management.config.reports.step}
          onPage={this.onPage}
          items={management.config.reports.items}
          total={management.config.reports.total}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 0
  },
  paginator: {
    position: "fixed",
    bottom: 0,
    padding: 15,
    backgroundColor: "#fff",
    left: 0,
    right: 0,
    boxShadow: "-5px 0px 10px #999"
  }
});

export default observer(ReportsTab);
