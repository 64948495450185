import React, { Component } from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'

import Fonts from '../../Themes/Fonts'
import Colors from '../../Themes/Colors'

class Button extends Component {
  handleReturn = () => {
    return false
  }

  render () {
    const { disabled } = this.props
    return (
      <TouchableOpacity
        style={[
          styles.container,
          this.props.center ? { alignSelf: 'center' } : {},
          { backgroundColor: this.props.background },
          this.props.style,
          disabled && styles.disabled
        ]}
        className={this.props.className}
        onPress={disabled ? this.handleReturn : this.props.onPress}
      >
        <Text style={[styles.title, this.props.upper && styles.upper, this.props.fontStyle]}>{this.props.title}</Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    paddingHorizontal: 24,
    paddingVertical: 8,
    alignSelf: 'flex-start'
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default'
  },
  title: {
    ...Fonts.style.text,
    fontSize: 12,
    letterSpacing: 1,
    textAlign: 'center',
    color: Colors.white
  },
  upper: {
    textTransform: 'uppercase'
  }
})

export default Button
