import React, { Component } from "react";
import { Dimensions } from "react-native";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react";
import debounce from "lodash/debounce";

import { withRouter } from "../../Navigation/withRouter";

import Header from "../../Components/Header";
import Wrapper from "../../Components/Wrapper";
import HomeScreen from "../../Components/HomeScreen";
import HomeAbout from "../../Components/HomeAbout";
import SectionsListSimple from "../../Components/SectionsListSimple";
import HomeContact from "../../Components/HomeContact";

import { auth } from "../../Model";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: {
        w: 0,
        h: 0
      },
      sidebarOpen: false,
      isLogged: false
    };
  }

  updateDimensions = () => {
    const { height, width } = Dimensions.get("window");
    this.setState({ screen: { w: width, h: height } });
  };

  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", debounce(this.updateDimensions, 300));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  toggleSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  login = () => {
    auth.login();
  };

  render() {
    if (auth.loggedIn()) {
      return <Navigate replace to={{ pathname: "/inicio" }} />;
    }

    return (
      <Wrapper isLogged={auth.loggedIn()}>
        <Header
          screen={this.state.screen}
          showSidebar={this.toggleSidebar}
          onLogout={this.logout}
          onLogin={this.login}
        />
        <HomeScreen screen={this.state.screen} isLogged={auth.loggedIn()} />
        <HomeAbout screen={this.state.screen} />
        <SectionsListSimple screen={this.state.screen} />
        <HomeContact screen={this.state.screen} />
      </Wrapper>
    );
  }
}

export default withRouter(observer(HomeComponent));
