import React, { Component } from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'

import Colors from '../../Themes/Colors'

export class Loading extends Component {
  render () {
    const color = this.props.color ? this.props.color : Colors.purple
    return (
      <View style={[styles.container, this.props.small && styles.small, this.props.fluid && styles.fluid]}>
        <View style={styles.content}>
          <ActivityIndicator animating={true} color={color} size="large"/>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.loading,
    paddingTop: 76,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 99999
  },
  small: {
    flex: 1,
    position: 'relative'
  },
  fluid: {
    paddingTop: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.25)'
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default Loading
