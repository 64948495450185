import React from 'react'

import { View, Text, StyleSheet } from 'react-native'

import Cell from './Cell'

class Table extends React.Component {
  isOdd = (num) => num % 2

  render () {
    const list = this.props.data
    console.log(list)
    return (
      <View style={styles.box}>
        <View style={styles.title}>
          <Text style={styles.texTitle}>{this.props.textTitle}</Text>
        </View>
        <View style={styles.wrappingTable}>
          <View style={styles.table}>
            {list.map((row, i) => {
              return (
                <View style={styles.row}>
                  <Cell style={this.isOdd(i) ? styles.cellColor : {}} title={row['col 1']}/>
                  <Cell style={this.isOdd(i) ? styles.cellColor : {}} title={row['col 2']}/>
                </View>
              )
            })}
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  box: {
    margin: 15,
    flex: 1,
    borderWidth: 1,
    borderColor: '#99949E'
  },
  title: {
    padding: 10,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#99949E',
    backgroundColor: '#E4E0E8'
  },
  texTitle: {
    fontSize: 20,
    color: '#72687C'
  },
  wrappingTable: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row'
  },
  table: {
    flex: 1,
    borderWidth: 1,
    borderColor: '#99949E'
  },
  cellColor: {
    backgroundColor: '#E4E0E8'
  },
  row: {
    flexDirection: 'row'
  }
})

export default Table
