import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { alba } from "../../Model";

const chartStyles = {
  container: {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  },
  label: {
    ...Fonts.style.labelTitle,
    textAnchor: "middle"
  }
};

class AreaChartComponent extends Component {
  formatter = (decimal, fixed = 0) => {
    const data = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;
    const format = data.y_axis_format ? data.y_axis_format : false;
    return alba.axisFormatter(decimal, format);
  };

  toPercent = (decimal, fixed = 0) => {
    return `${(decimal * 100).toFixed(fixed)}%`;
  };

  getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return this.toPercent(ratio, 2);
  };

  toNumber = (decimal, fixed = 0) => decimal;

  renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);

    return (
      <View style={styles.tooltip} className="customized-tooltip-content">
        <Text
          style={styles.total}
          className="total"
        >{`${label} (Total: ${total})`}</Text>
        <View className="list">
          {payload.map((entry, index) => (
            <Text key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value} (${this.getPercent(
                entry.value,
                total
              )})`}
            </Text>
          ))}
        </View>
      </View>
    );
  };

  renderContent() {
    if (alba.loading) {
      return <View />;
    }

    const data = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;

    return (
      <ResponsiveContainer className="bar-chart-component">
        <AreaChart
          stackOffset={this.props.data.percentage ? "expand" : "none"}
          data={data.points_data.toJS()}
          margin={chartStyles.container}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="x_axis_val">
            <Label position="bottom" style={chartStyles.label}>
              {data.xaxislabel}
            </Label>
          </XAxis>
          <YAxis
            tick={{ fontSize: 12 }}
            tickFormatter={
              this.props.data.percentage ? this.toPercent : this.formatter
            }
          >
            <Label angle={270} position="left" style={chartStyles.label}>
              {data.yaxislabel}
            </Label>
          </YAxis>
          <Tooltip
            content={
              this.props.data.percentage ? this.renderTooltipContent : "none"
            }
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ zIndex: 100 }}
          />
          {data.points_config.map((area, index) => {
            return (
              <Area
                key={index}
                type="monotone"
                dataKey={area.key}
                stackId="1"
                stroke={area.color}
                fill={area.color}
              />
            );
          })}
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.subtitle}>{this.props.data.title}</Text>
        <View style={styles.content}>{this.renderContent()}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%"
  },
  content: {
    flex: 1,
    width: "100%",
    height: 306
  },
  subtitle: {
    ...Fonts.style.labelTitle,
    marginBottom: 45
  },
  tooltip: {
    backgroundColor: Colors.white,
    borderColor: "#999",
    borderWidth: 1,
    margin: 0,
    padding: 10,
    maxWidth: 200,
    zIndex: 9999
  },
  total: {
    alignItems: "center"
  }
});

export default observer(AreaChartComponent);
