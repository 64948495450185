import React, { Component } from 'react'
import { View, StyleSheet, Image } from 'react-native'
import marker from '../../Images/marker.png'

const K_WIDTH = 40
const K_HEIGHT = 40

class GreatPlace extends Component {
  render () {
    return (
      <View style={styles.container}>
        <Image source={marker} style={styles.marker} label={this.props.title}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2
  },
  marker: {
    width: K_WIDTH,
    height: K_HEIGHT
  }
})

export default GreatPlace
