import React from 'react'

import { View, Text, StyleSheet } from 'react-native'

class Cell extends React.Component {
  render () {
    return (
      <View style={[styles.cell, this.props.style]}>
        <Text style={styles.text}>{this.props.title}</Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  cell: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row'
  },
  text: {
    margin: 10,
    borderWidth: 1,
    borderColor: '#7E15E7'
  }
})

export default Cell
