import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import SectionsListItem from "../../Components/SectionsListItem";
import Colors from "../../Themes/Colors";

import iconInsight from "../../Images/icon-insights-purple.png";
import iconAlert from "../../Images/icon-alerts-purple.png";
import iconReports from "../../Images/icon-reports-purple.png";
import iconFlow from "../../Images/icon-flow-purple.png";

import { home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class SectionsListSimple extends Component {
  getContainerStyle = () => {
    if (this.props.screen.w <= 768) return styles.containerSm;
    else return {};
  };

  getListStyle = () => {
    if (this.props.screen.w <= 768) return styles.listSm;
    else return {};
  };

  getSeparatorStyle = () => {
    if (this.props.screen.w <= 768) return styles.separatorSm;
    else return {};
  };

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <View style={[styles.list, this.getListStyle()]} id="listContainer">
            <SectionsListItem
              screen={this.props.screen}
              text={langProps.insights || ""}
              underline={2}
              icon={iconInsight}
              color={Colors.purple}
            />
            <View style={[styles.separator, this.getSeparatorStyle()]} />
            <SectionsListItem
              screen={this.props.screen}
              text={langProps.alerts || ""}
              underline={1}
              icon={iconAlert}
              color={Colors.purple}
            />
            <View style={[styles.separator, this.getSeparatorStyle()]} />
            <SectionsListItem
              screen={this.props.screen}
              text={langProps.reports || ""}
              underline={1}
              icon={iconReports}
              color={Colors.purple}
            />
            <View style={[styles.separator, this.getSeparatorStyle()]} />
            <SectionsListItem
              screen={this.props.screen}
              text={langProps.feeds || ""}
              underline={1}
              icon={iconFlow}
              color={Colors.purple}
            />
          </View>
        </View>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minHeight: 390,
    flexDirection: "row",
    backgroundColor: Colors.sidebar
  },
  innerContainer: {
    maxWidth: 694,
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    alignItems: "center"
  },
  list: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  listSm: {
    flexDirection: "column"
  },
  separator: {
    width: 1,
    minHeight: 70,
    backgroundColor: Colors.purple
  },
  separatorSm: {
    width: 150,
    minHeight: 1
  }
});

export default withLang(languagesHome)(observer(SectionsListSimple));
