import React, { Component } from "react";
import { View } from "react-native";
import { observer } from "mobx-react";

import AlertSlider from "../../Components/AlertSlider";
import SectionsList from "../../Components/SectionsList";
import FlowList from "../../Components/FlowList";
import ReportList from "../../Components/ReportList";
import Insights from "../../Components/Insights";

class HomeSections extends Component {
  render() {
    return (
      <View>
        <SectionsList screen={this.props.screen} />
        <AlertSlider screen={this.props.screen} />
        <ReportList screen={this.props.screen} />
        <FlowList screen={this.props.screen} />
        <Insights screen={this.props.screen} />
      </View>
    );
  }
}

export default observer(HomeSections);
