import React, { Component } from "react";
import { View, StyleSheet } from "react-native";

class Wrapper extends Component {
  render() {
    return <View style={[styles.container]}>{this.props.children}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
    maxWidth: 1440,
    marginLeft: "auto",
    marginRight: "auto"
  }
});

export default Wrapper;
