import "react-day-picker/style.css";

import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity, Image } from "react-native";
import { DayPicker } from "react-day-picker";
import DatePickerSelector from "../DatePickerSelector";

import Colors from "../../Themes/Colors";
import close from "../../Images/icon-close-purple.png";

const now = new Date();
const currentYear = now.getFullYear();
const fromMonth = new Date(currentYear - 18, 0);
const toMonth = new Date(currentYear, now.getMonth());

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: toMonth
    };
  }

  UNSAFE_componentWillMount = () => {
    this.setState({ month: this.props.date });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ month: nextProps.date });
  }

  handleYearMonthChange = (month) => {
    this.setState({ month });
  };

  renderContent = () => {
    if (this.props.focused) {
      return (
        <View style={styles.pickerContainer} className="dayPickerContainer">
          <View style={styles.closeContainer}>
            <TouchableOpacity onPress={this.props.onClose}>
              <Image
                accessibilityLabel="Close"
                source={close}
                resizeMode="cover"
                style={styles.close}
              />
            </TouchableOpacity>
          </View>
          <DayPicker
            selectedDays={this.props.date}
            onDayClick={this.props.onDateChange}
            month={this.state.month}
            fromMonth={fromMonth}
            toMonth={toMonth}
            disabledDays={this.props.disabledDays}
            captionElement={({ date, localeUtils }) => (
              <DatePickerSelector
                fromMonth={fromMonth}
                toMonth={toMonth}
                date={date}
                localeUtils={localeUtils}
                onChange={this.handleYearMonthChange}
              />
            )}
          />
        </View>
      );
    }

    return null;
  };

  render() {
    return <View style={styles.container}>{this.renderContent()}</View>;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative"
  },
  pickerContainer: {
    position: "absolute",
    top: -15,
    left: 0,
    right: 0,
    zIndex: 99999
  },
  closeContainer: {
    paddingTop: 10,
    paddingRight: 5,
    backgroundColor: Colors.white,
    alignItems: "flex-end"
  },
  close: {
    width: 24,
    height: 24
  }
});

export default DatePicker;
