import { makeAutoObservable } from "mobx";
import { alba } from "../../Model";

export default class ManagementModel {
  loading = false;
  tabSelected = 0;
  alerts = [];
  reports = [];
  feeds = [];
  perPage = 3;

  config = {
    alerts: {
      initPage: 0,
      page: 0,
      step: this.perPage,
      items: [],
      total: 0
    },
    reports: {
      initPage: 0,
      page: 0,
      step: this.perPage,
      items: [],
      total: 0
    },
    feeds: {
      initPage: 0,
      page: 0,
      step: this.perPage,
      items: [],
      total: 0
    }
  };

  constructor() {
    makeAutoObservable(this);
  }

  resetConfigPages = () => {
    this.config.alerts.page = 0;
    this.config.reports.page = 0;
    this.config.feeds.page = 0;
  };

  resetLists = () => {
    this.config.alerts.items = [];
    this.config.reports.items = [];
    this.config.feeds.items = [];
    this.config.alerts.total = 0;
    this.config.reports.total = 0;
    this.config.feeds.total = 0;
  };

  send = () => {
    alert("not implemented");
  };

  delete = () => {
    alert("not implemented");
  };

  loadTabs = () => {
    this.resetLists();
    this.alerts = alba.alerts;
    this.reports = alba.reports;
    this.feeds = alba.feeds;
    this.loading = false;
    console.log(this.feeds);
  };

  getPaginatedItems(items, offset) {
    return items.slice(offset, offset + this.perPage);
  }

  loadAlerts = (perPage, offset) => {
    var items = this.alerts;
    var nextOffset = offset + this.perPage;
    var previousOffset = offset - this.perPage < 1 ? 0 : offset - this.perPage;

    var meta = {
      limit: this.perPage,
      next: () => {},
      offset: offset,
      previous: () => {},
      total_count: items.length
    };

    this.config.alerts.items = items;
    this.config.alerts.total = meta.total_count;
    return {
      meta: meta,
      items: this.getPaginatedItems(items, offset)
    };
  };

  loadReports = (perPage, offset) => {
    var items = this.reports;
    var nextOffset = offset + this.perPage;
    var previousOffset = offset - this.perPage < 1 ? 0 : offset - this.perPage;

    var meta = {
      limit: this.perPage,
      next: () => {},
      offset: offset,
      previous: () => {},
      total_count: items.length
    };

    return {
      meta: meta,
      items: this.getPaginatedItems(items, offset)
    };
  };

  loadFeeds = (perPage, offset) => {
    var items = this.feeds;

    var meta = {
      limit: this.perPage,
      next: () => {},
      offset: offset,
      previous: () => {},
      total_count: items.length
    };

    return {
      meta: meta,
      items: this.getPaginatedItems(items, offset)
    };
  };
}
