import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { observer } from "mobx-react";
import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from "recharts";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { alba } from "../../Model";

const chartStyles = {
  container: {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20
  },
  label: {
    ...Fonts.style.labelTitle,
    textAnchor: "middle"
  }
};

class BarChartTabsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "",
      data: {}
    };
  }

  componentDidMount() {
    const plotData = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;
    var data = {};
    data["title"] = this.props.data.title;
    data["tabs"] = [];
    plotData.points_data.map((plot, i) => {
      const key = plot.tab_title.replace(/ /g, "_");
      data["tabs"].push({ key, name: plot.tab_title });
      data[key] = plot.tab_data;
    });
    this.setState({ tab: data.tabs[0].key, data });
  }

  setTab = (tab) => {
    this.setState({ tab });
  };

  formatter = (decimal, fixed = 0) => {
    const data = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;
    const format = data.y_axis_format ? data.y_axis_format : false;
    return alba.axisFormatter(decimal, format);
  };

  renderContent() {
    if (alba.loading) {
      return <View />;
    }

    const data = this.props.data.plot_data[0]
      ? this.props.data.plot_data[0]
      : this.props.data.plot_data;

    return (
      <ResponsiveContainer className="bar-chart-component">
        <BarChart
          data={this.state.data[this.state.tab]}
          margin={chartStyles.container}
        >
          <XAxis tick={{ fontSize: 12 }} dataKey="x_axis_val">
            <Label position="bottom" style={chartStyles.label}>
              {data.xaxislabel}
            </Label>
          </XAxis>
          <YAxis tick={{ fontSize: 12 }} tickFormatter={this.formatter}>
            <Label angle={270} position="left" style={chartStyles.label}>
              {data.yaxislabel}
            </Label>
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ zIndex: 100 }}
          />
          <Legend align="right" verticalAlign="top" layout="vertical" />
          {data.points_config.map((bar, index) => (
            <Bar dataKey={bar.key} key={index} fill={bar.color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.subtitle}>{this.props.data.title}</Text>
        {this.state.data.tabs && this.state.data.tabs.length > 0 && (
          <View style={styles.tabs}>
            {this.state.data.tabs.map((tab, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => this.setTab(tab.key)}
                style={[
                  styles.tab,
                  this.state.tab === tab.key && styles.tabSelected
                ]}
              >
                <Text style={styles.tabText}>{tab.name.trim()}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
        <View style={styles.content}>{this.renderContent()}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%"
  },
  content: {
    flex: 1,
    height: 306
  },
  subtitle: {
    ...Fonts.style.labelTitle,
    marginBottom: 45
  },
  tooltip: {
    backgroundColor: Colors.white,
    borderColor: "#999",
    borderWidth: 1,
    margin: 0,
    padding: 10,
    maxWidth: 200,
    zIndex: 9999
  },
  tabs: {
    flexDirection: "row",
    marginTop: -34
  },
  tab: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    paddingVertical: 8,
    paddingHorizontal: 18,
    //backgroundColor: '#e2e2e2',
    borderBottomWidth: 1,
    borderBottomColor: "#ccc"
  },
  tabSelected: {
    borderWidth: 1,
    borderColor: "#ccc"
    //backgroundColor: '#aaa'
  },
  tabText: {
    color: "#000"
  }
});

export default observer(BarChartTabsComponent);
