import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { observer } from "mobx-react";

import Select from "../../Components/SelectCustom";
import Footer from "../../Components/Footer";
import AlertItem from "../AlertItem";
import Fonts from "../../Themes/Fonts";
import { feedback } from "../../Model";

class FeedbackTabItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      offset: 0,
      pageCount: 0
    };

    this.options = [
      { text: "All", value: "all" },
      { text: "True alert", value: true },
      { text: "False alert", value: false }
    ];
  }

  componentDidMount() {
    this.loadItems(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadItems(nextProps);
  }

  loadItems = (props) => {
    const { tabSelected } = props;
    const data = feedback.getAlerts(tabSelected);

    this.setState({ data });
  };

  onChangeOutcome = async (filter) => {
    feedback.loading = true;
    feedback.outcomeFilter = filter;
    await feedback.fetchAlerts("outcome", filter, null);
    feedback.loading = false;
    const data = feedback.getAlerts(3);
    this.setState({ data });
  };

  getRowStyle = (index) => {
    return this.isOdd(index)
      ? { backgroundColor: "#f2f2f2" }
      : { backgroundColor: "#fff" };
  };

  isOdd = (num) => {
    return num % 2;
  };

  onPage = (page) => {
    feedback.loadMoreAlerts(page);
  };

  renderOutcomeFilters = () => {
    if (this.props.tabSelected === 3 && this.state.data.length > 0) {
      return (
        <div style={{ display: "block" }}>
          <View style={styles.wrapInner}>
            <Select
              defaultText=" "
              value={feedback.outcomeFilter}
              options={this.options}
              onChange={(event) => this.onChangeOutcome(event.target.value)}
              styleContainer={{ width: 200, marginTop: 25 }}
            />
          </View>
        </div>
      );
    }

    return null;
  };

  render() {
    const config = feedback.getAlertsConfig(this.props.tabSelected);

    return (
      <View style={styles.container}>
        {this.renderOutcomeFilters()}

        <View style={styles.wrap}>
          {this.state.data.map((item, index) => (
            <View
              style={[{ display: "block" }, this.getRowStyle(index)]}
              key={index}
            >
              <View style={styles.wrapInner}>
                <AlertItem
                  data={item}
                  screen={this.props.screen}
                  giveFeedback={this.props.handleGiveFeedback}
                  lang={this.props.lang}
                  all
                  tabs
                />
              </View>
            </View>
          ))}
          {/* <TablesFeedback
            data={this.state.data}
            giveFeedback={this.props.handleGiveFeedback}
            viewAlert={this.props.viewAlert}
          /> */}
          {this.state.data.length === 0 && (
            <View>
              <Text style={styles.noAlerts}>
                No alerts found in this section
              </Text>
            </View>
          )}
        </View>

        {/*<Footer
              initPage={config.initPage}
              page={config.page}
              step={config.step}
              onPrev={this.onPrev}
              onNext={this.onNext}
              onPage={this.onPage}
              items={config.items}
              total={config.total}
          />*/}

        {/* <AlertsTabList tabSelected={this.props.tabSelected} items={this.state.data} screen={this.props.screen} /> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    margin: 0
  },
  wrapInner: {
    maxWidth: 1024,
    marginLeft: "auto",
    marginRight: "auto"
  },
  wrap: {
    flex: 1,
    flexDirection: "column",
    paddingBottom: 0
  },
  wrapMd: {
    maxWidth: 768
  },
  wrapSm: {
    maxWidth: "100%",
    flex: 1
  },
  noAlerts: {
    ...Fonts.style.title,
    paddingTop: 50,
    paddingBottom: 50,
    textAlign: "center"
  },
  paginator: {}
});

export default observer(FeedbackTabItem);
