import React, { Component } from "react";
import { View, StyleSheet, Text, TouchableOpacity, Image } from "react-native";
import decode from "decode-html";
import moment from "moment";
import Button from "../../Components/Button";
import ImageResponsive from "../../Components/ImageResponsive";
import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import dateFormat from "dateformat";
import AlertTables from "../../Components/AlertTable/Tables";
import userIcon from "../../Images/user-icon.jpg";

import { home } from "../../Model";

require("moment/min/locales.min");
moment.locale("es");

const textDescription = {
  ...Fonts.style.text,
  marginTop: 20,
  marginBottom: 30,
  textAlign: "justify",
  lineHeight: "18px"
};

class AlertItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  getColumsStyle = () => {
    if (this.props.screen.w <= 768) return styles.columnSm;
    else if (this.props.screen.w <= 992) return styles.columnMd;
    else return styles.column;
  };

  getContentStyle = () => {
    if (this.props.screen.w <= 768) return styles.contentSm;
    else return styles.content;
  };

  getShortHighlightedText(text, highlight) {
    const _text = text.length > 300 ? text.substring(0, 300) : text;

    if (highlight.length > 0) {
      let parts =
        _text && _text.length > 0
          ? _text.split(new RegExp(`(${highlight})`, "gi"))
          : [];
      return (
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === highlight.toLowerCase()
                  ? {
                      fontWeight: "bold",
                      color: Colors.yellow
                    }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      );
    }

    return _text;
  }

  getHighlightedText(text, highlight) {
    if (highlight.length > 0) {
      let parts =
        text && text.length > 0
          ? text.split(new RegExp(`(${highlight})`, "gi"))
          : [];
      return (
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === highlight.toLowerCase()
                  ? {
                      fontWeight: "bold",
                      color: Colors.yellow
                    }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      );
    }

    return text;
  }

  getHighlightedString(text, highlight) {
    if (highlight.length > 0) {
      let parts =
        text && text.length > 0
          ? text.split(new RegExp(`(${highlight})`, "gi"))
          : [];

      if (parts.length > 0) {
        let newText = "";
        parts.map((part, i) => {
          return (newText +=
            part.toLowerCase() === highlight.toLowerCase()
              ? `<span style='font-weight: bold; color: ${Colors.yellow}'>${part}</span>`
              : part);
        });

        return newText;
      }

      return text;
    }

    return text;
  }

  openSource = (url) => {
    window.open(url, "_blank");
  };

  renderStatusButton = (data) => {
    const { lang } = this.props;
    if (data.read && data.on_review) {
      return (
        <Button
          upper
          title={lang.on_review || ""}
          background={Colors.yellow}
          className="status-button-alert"
        />
      );
    }

    if (data.read && !data.on_review && data.outcome !== null && data.outcome) {
      return (
        <Button
          upper
          title={lang.opt_true_alert || ""}
          background={Colors.green}
          className="status-button-alert"
        />
      );
    }

    if (
      data.read &&
      !data.on_review &&
      data.outcome !== null &&
      !data.outcome
    ) {
      return (
        <Button
          upper
          title={lang.opt_false_alert || ""}
          background={Colors.red}
          className="status-button-alert"
        />
      );
    }

    return null;
  };

  render() {
    const { data, all, tabs, giveFeedback, lang } = this.props;
    const titleColor = this.props.feed ? Colors.white : Colors.purple;
    const hasUrl = data && data.url;

    return (
      <View style={[all && styles.containerAll, tabs && styles.containerTabs]}>
        <View
          style={[
            styles.content,
            this.getContentStyle(),
            !this.state.open && all && styles.contentAll
          ]}
        >
          <View style={[styles.column, this.getColumsStyle()]}>
            <Text style={[styles.title, { color: titleColor }]}>
              {this.getHighlightedText(data.title, home.search)}
            </Text>
            {data.author && (
              <Text style={[styles.pinkText, styles.bold, styles.spaceSmall]}>
                {data.author.trim()}
              </Text>
            )}
            <Text style={[styles.info]}>{`${lang.folio || ""}: ${
              data.doc_id
            }`}</Text>
            {!this.state.open && (
              <View>
                <TouchableOpacity
                  onPress={() => this.setState({ open: !this.state.open })}
                >
                  <div style={textDescription}>
                    {this.getShortHighlightedText(data.abstract, home.search)}
                    ...
                    <Text style={[styles.pinkText, styles.bold]}>[+]</Text>
                  </div>
                </TouchableOpacity>
              </View>
            )}

            {this.state.open && (
              <View style={{ marginTop: 15 }}>
                {data.status && data.status.length > 0 && (
                  <Text style={[styles.pinkText, styles.bold, styles.space]}>
                    {lang.alert || ""}:
                    <Text style={styles.info}> {data.status}</Text>
                  </Text>
                )}
                {data.date && (
                  <Text style={[styles.pinkText, styles.space]}>
                    {lang.date || ""}:
                    <Text style={styles.info}>
                      {" "}
                      {dateFormat(new Date(data.date), "yyyy-mm-dd")}
                    </Text>
                  </Text>
                )}
                {data.source_ && data.source_.length > 0 && (
                  <Text style={[styles.pinkText, styles.space]}>
                    {lang.source || ""}:
                    <Text style={styles.info}>
                      {" "}
                      {this.getHighlightedText(data.source_, home.search)}
                    </Text>
                  </Text>
                )}
                {data.status && data.status.length > 0 && (
                  <Text style={[styles.pinkText, styles.space]}>
                    {lang.status || ""}:
                    {data.status && (
                      <Text style={styles.info}> {data.status}</Text>
                    )}
                  </Text>
                )}
                {data.author && data.author.length > 0 && (
                  <Text style={[styles.pinkText, styles.space]}>
                    {lang.author || ""}:
                    <Text style={styles.info}> {data.author}</Text>
                  </Text>
                )}
              </View>
            )}

            <View style={styles.actions}>
              {!this.state.open && hasUrl && (
                <Button
                  upper
                  title={lang.source || ""}
                  background={Colors.pink}
                  onPress={() => this.openSource(data.url)}
                />
              )}
              <View style={{ width: 5 }} />
              {!this.state.open && (
                <Button
                  upper
                  title={lang.feedback || ""}
                  background={Colors.pink}
                  onPress={() =>
                    giveFeedback(
                      data.doc_id,
                      data.comment,
                      data.read,
                      data.on_review,
                      data.outcome,
                      data.amount
                    )
                  }
                />
              )}
              {!this.state.open && this.renderStatusButton(data)}
            </View>
          </View>
          <View
            style={[styles.column, this.getColumsStyle(), styles.columnImg]}
          >
            <ImageResponsive
              source={data.image}
              label={data.title}
              style={{ resizeMode: "contain" }}
            />
          </View>
        </View>
        {this.state.open && (
          <View
            style={[
              styles.fullData,
              this.props.all ? { paddingBottom: 0 } : {}
            ]}
          >
            {data.abstract && data.abstract.length > 0 && (
              <Text style={[styles.pinkText, styles.space]}>
                {lang.theme || ""}:
                <Text style={styles.info}>
                  {" "}
                  {this.getHighlightedText(data.abstract, home.search)}
                </Text>
              </Text>
            )}
            {data.text && data.text.length > 0 && (
              <View>
                <Text style={[styles.pinkText, styles.space]}>
                  {lang.body || ""}:
                </Text>
                <div
                  className="htmlText"
                  dangerouslySetInnerHTML={{
                    __html: decode(
                      this.getHighlightedString(data.text, home.search)
                    )
                  }}
                />
              </View>
            )}
            {data.reforms && data.reforms.length > 0 && (
              <Text style={[styles.pinkText, styles.space]}>
                {lang.reforms || ""}:
                <Text style={styles.info}> {data.reforms}</Text>
              </Text>
            )}
            {data.url && data.url.length > 0 && (
              <Text style={[styles.pinkText, styles.space]}>
                {lang.links || ""}:
                <TouchableOpacity onPress={() => this.openSource(data.url)}>
                  <Text style={styles.info}> {data.url}</Text>
                </TouchableOpacity>
              </Text>
            )}
            {data.feedbacks && data.feedbacks.length > 0 && (
              <View>
                <Text style={[styles.pinkText, styles.space]}>
                  {lang.comments || ""}:
                </Text>
                {data.feedbacks.map((fback, i) => {
                  return (
                    <View key={i}>
                      <View style={styles.commentItem}>
                        <Image
                          accessibilityLabel={fback.sumbitter}
                          source={userIcon}
                          resizeMode="contain"
                          style={styles.user}
                        />
                        <View>
                          <Text style={[styles.info, styles.bold]}>
                            {fback.sumbitter}
                          </Text>
                          <Text style={styles.info}>
                            {moment(fback.timestamp * 1000).format(
                              "MMMM DD, YYYY [a las] h:mm a"
                            )}
                          </Text>
                        </View>
                      </View>
                      <View style={styles.commentText}>
                        <Text style={styles.info}>{fback.comment}</Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            )}
            <TouchableOpacity
              style={styles.space}
              onPress={() => this.setState({ open: !this.state.open })}
            >
              <Text style={[styles.pinkText, styles.bold]}>[-]</Text>
            </TouchableOpacity>

            {data.tables && (
              <AlertTables
                data={data.tables}
                title={lang.tables || ""}
                subTitle=""
              />
            )}

            <View style={[styles.actions, styles.spaceBtn]}>
              {hasUrl && (
                <Button
                  upper
                  title={lang.source || ""}
                  background={Colors.pink}
                  onPress={() => this.openSource(data.url)}
                />
              )}
              {hasUrl && <View style={{ width: 5 }} />}
              <Button
                upper
                title={lang.feedback || ""}
                background={Colors.pink}
                onPress={() =>
                  giveFeedback(
                    data.doc_id,
                    data.comment,
                    data.read,
                    data.on_review,
                    data.outcome,
                    data.amount
                  )
                }
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 75,
    paddingBottom: 60
  },
  contentAll: {
    paddingTop: 20,
    paddingBottom: 20
  },
  containerAll: {
    borderBottomColor: "#ccc",
    borderStyle: "solid",
    borderBottomWidth: 1
  },
  containerTabs: {
    borderBottomWidth: 0
  },
  contentSm: {
    flexDirection: "column",
    paddingHorizontal: 30
  },
  column: {
    flex: 0.48,
    marginBottom: 15
  },
  columnImg: {
    marginBottom: 0
  },
  columnMd: {
    flex: 0.5
  },
  columnSm: {
    flex: 1
  },
  alert: {
    ...Fonts.style.underline,
    fontSize: 18
  },
  title: {
    ...Fonts.style.subtitle,
    color: Colors.purple,
    minHeight: 44
  },
  description: {
    ...Fonts.style.text,
    marginTop: 20,
    marginBottom: 30,
    textAlign: "justify"
  },
  pinkText: {
    ...Fonts.style.textPink,
    textTransform: "capitalize"
  },
  info: {
    ...Fonts.style.text
  },
  space: {
    marginBottom: 15
  },
  spaceSmall: {
    marginBottom: 5
  },
  bold: {
    fontWeight: "700"
  },
  normal: {
    fontWeight: "400"
  },
  fullData: {
    marginTop: -60,
    paddingHorizontal: 0,
    paddingBottom: 45
  },
  spaceBtn: {
    marginBottom: 80
  },
  actions: {
    flexDirection: "row"
  },
  user: {
    width: 40,
    height: 40,
    borderRadius: 50,
    marginRight: 15
  },
  commentItem: {
    flex: 1,
    flexDirection: "row"
  },
  commentText: {
    maxWidth: 300,
    borderWidth: 1,
    borderColor: "#ddd",
    borderStyle: "solid",
    borderRadius: 3,
    padding: 15,
    marginVertical: 10
  }
});

export default AlertItem;
