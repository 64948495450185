import React, { Component } from "react";
import Popup from "reactjs-popup";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";
import _ from "lodash";

import Loading from "../Loading";
import Button from "../../Components/Button";
import Checkbox from "../../Components/Checkbox";
import Select from "../../Components/SelectCustom";
import UnderlinedText from "../../Components/UnderlinedText";
import FeedbackTab from "../../Components/Feedback/tab";
import AlertItem from "../../Components/AlertItem";
import Footer from "../../Components/Footer";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { feedback, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

class FeedbackComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      docId: "",
      status: "",
      comment: "",
      showPopup: false,
      alertView: null,
      showPopupAlert: false,
      amount: 0,
      estimate: true
    };
  }

  getSelectedTabStyle = (index) => {
    if (feedback.tabSelected === index) return styles.tabButtonSelected;
    return null;
  };

  setSelectesTab = (index) => {
    feedback.tabSelected = index;
  };

  handleGiveFeedbackPopup = async () => {
    feedback.processing = true;
    await feedback.giveFeedback({
      doc_id: this.state.docId,
      status_change: this.state.status.replace(" ", "_"),
      comment: this.state.comment,
      saved_amount: this.state.estimate ? this.state.amount : -1
    });
    this.setState({ docId: "", status: "", comment: "", showPopup: false });
    this.props.reloadTabs();
  };

  handleGiveFeedback = (doc_id, comment, read, on_review, outcome, amount) => {
    let status = "";
    if (read) {
      if (on_review) {
        status = "on review";
      } else {
        if (outcome) {
          status = "yes";
        } else {
          status = "no";
        }
      }
    }
    this.setState({ showPopup: true, docId: doc_id, status, amount });
  };

  closeModal = () => {
    this.setState({ showPopup: false });
  };

  viewAlert = (doc_id) => {
    const alerts = feedback.getAllAlerts();
    const alertView = _.find(alerts, { doc_id });

    this.setState({ alertView }, () => {
      this.setState({ showPopupAlert: true });
    });
  };

  closeModalAlert = () => {
    this.setState({ showPopupAlert: false });
  };

  onPage = (page) => {
    feedback.loadMoreAlerts(page);
  };

  renderTabContent = () => {
    if (feedback.loading)
      return (
        <View>
          <Loading />
          <Footer simple />
        </View>
      );

    const config = feedback.getAlertsConfig(feedback.tabSelected);
    return (
      <View>
        <FeedbackTab
          tabSelected={feedback.tabSelected}
          screen={this.props.screen}
          handleGiveFeedback={this.handleGiveFeedback}
          viewAlert={this.viewAlert}
          lang={this.props.langProps}
        />
        <Footer
          initPage={config.initPage}
          page={config.page}
          step={config.step}
          onPrev={this.onPrev}
          onNext={this.onNext}
          onPage={this.onPage}
          items={config.items}
          total={config.total}
        />
      </View>
    );
  };

  render() {
    const { langProps } = this.props;
    const options = [
      { text: langProps.opt_true_alert || "", value: "yes" },
      { text: langProps.opt_false_alert || "", value: "no" },
      { text: langProps.opt_further_review || "", value: "on review" }
    ];
    const { status, amount } = this.state;
    const disableFeedback =
      (status === "" || status === "yes") &&
      (parseFloat(amount) === 0 || amount === "" || amount === null);
    return (
      <View style={styles.managementContainer}>
        <Popup
          contentStyle={{ width: 768, borderRadius: 5, padding: 15 }}
          open={this.state.showPopupAlert}
          closeOnDocumentClick
          onClose={this.closeModalAlert}
        >
          <View style={styles.alertModal}>
            {this.state.alertView && (
              <AlertItem
                data={this.state.alertView}
                screen={this.props.screen}
                giveFeedback={this.handleGiveFeedback}
                lang={langProps}
              />
            )}
          </View>
        </Popup>
        <Popup
          contentStyle={{ width: 380, borderRadius: 5, padding: 15 }}
          open={this.state.showPopup}
          closeOnDocumentClick
          onClose={this.closeModal}
        >
          <div>
            <Text style={styles.labelBold}>{langProps.status || ""}</Text>
            <Select
              defaultText=" "
              value={this.state.status}
              options={options}
              onChange={(event) =>
                this.setState({ status: event.target.value })
              }
            />
            <Text style={styles.labelBold}>{langProps.comment || ""}</Text>
            <textarea
              className="feedback-textarea"
              value={this.state.comment}
              onChange={(event) =>
                this.setState({ comment: event.target.value })
              }
              style={StyleSheet.flatten(styles.textarea)}
            />
            <View style={styles.estimateContainer}>
              <View style={styles.checkboxContainer}>
                <Checkbox
                  label=""
                  className="purple"
                  checked={this.state.estimate}
                  onChange={(event) =>
                    this.setState({ estimate: !this.state.estimate })
                  }
                />
              </View>
              <Text style={styles.labelBold}>
                {langProps.can_estimate || ""}
              </Text>
            </View>
            <Text style={styles.labelBold}>
              {langProps.saving_amount || ""}{" "}
              {status === "yes" && (
                <Text style={styles.labelSmall}>
                  ({langProps.required || ""})
                </Text>
              )}
            </Text>
            <input
              type="number"
              value={this.state.amount}
              onChange={(event) =>
                this.setState({ amount: event.target.value })
              }
              style={StyleSheet.flatten(styles.number)}
              disabled={!this.state.estimate}
            />
            <View style={styles.feedbackActions}>
              <Button
                upper
                title={langProps.cancel || ""}
                background={Colors.yellow}
                onPress={this.closeModal}
              />
              <View style={{ width: 5 }} />
              <Button
                upper
                title={langProps.give_feedback || ""}
                background={Colors.purple}
                onPress={this.handleGiveFeedbackPopup}
                disabled={disableFeedback}
              />
              {feedback.processing && (
                <ActivityIndicator
                  animating={true}
                  color={Colors.purple}
                  size="small"
                />
              )}
            </View>
          </div>
        </Popup>
        <View style={styles.managementContent}>
          {/** Green Box above taps, no function**/}
          <View style={[styles.bgGreen, styles.actionsContainer]} />
          {/**tabs in green box**/}
          <View
            style={[styles.tabs, styles.bgGreen]}
            className="tabsManagement"
          >
            <TouchableOpacity
              className="tabButtonManagement"
              style={[styles.tabButton, this.getSelectedTabStyle(0)]}
              onPress={() => this.setSelectesTab(0)}
            >
              <UnderlinedText
                center
                upper
                style={styles.tabTitle}
                text={langProps.unread || ""}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
              <Text style={styles.tabButtonCounter}>
                ({feedback.unreadAlertsTotal})
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
              className="tabButtonManagement"
              style={[styles.tabButton, this.getSelectedTabStyle(1)]}
              onPress={() => this.setSelectesTab(1)}
            >
              <UnderlinedText
                center
                style={styles.tabTitle}
                text={`READ`}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
            </TouchableOpacity> */}
            <TouchableOpacity
              className="tabButtonManagement"
              style={[styles.tabButton, this.getSelectedTabStyle(2)]}
              onPress={() => this.setSelectesTab(2)}
            >
              <UnderlinedText
                center
                upper
                style={styles.tabTitle}
                text={langProps.further_review || ""}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
              <Text style={styles.tabButtonCounter}>
                ({feedback.onReviewAlertsTotal})
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              className="tabButtonManagement"
              style={[styles.tabButton, this.getSelectedTabStyle(3)]}
              onPress={() => this.setSelectesTab(3)}
            >
              <UnderlinedText
                center
                upper
                style={styles.tabTitle}
                text={langProps.outcome || ""}
                place={1}
                color={Colors.white}
                classFontName="underlineManagement"
              />
              <Text style={styles.tabButtonCounter}>
                ({feedback.outcomeAlertsTotal})
              </Text>
            </TouchableOpacity>
          </View>
          {/** main content **/}
          <View style={styles.tabContent}>{this.renderTabContent()}</View>
        </View>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  managementContainer: {
    paddingTop: 76
  },
  managementContent: {
    backgroundColor: Colors.white
  },
  bgGreen: {
    backgroundColor: Colors.green
  },
  magementText: {
    ...Fonts.style.text,
    color: Colors.white,
    fontSize: 18,
    marginRight: 30
  },
  tabs: {
    flex: 1,
    flexDirection: "row",
    paddingHorizontal: 50
  },
  tabButton: {
    flexDirection: "row",
    backgroundColor: Colors.purpleTrans,
    paddingHorizontal: 30,
    paddingVertical: 15,
    minWidth: 172
  },
  tabButtonSelected: {
    backgroundColor: Colors.purple,
    boxShadow: "2px 0 10px -2px #1d0c33 ",
    marginTop: -1,
    zIndex: 999
  },
  tabButtonCounter: {
    color: Colors.placeholder,
    marginLeft: 10
  },
  tabTitle: {
    ...Fonts.style.underline,
    color: Colors.white,
    fontSize: 20
  },
  actionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: 60,
    paddingHorizontal: 30
  },
  actions: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  textarea: {
    height: 90,
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: 90,
    maxHeight: 180,
    boxSizing: "border-box",
    color: "#3d2e56",
    borderStyle: "solid",
    borderColor: "#3d2e56",
    borderWidth: 1,
    padding: 15,
    fontFamily: "Montserrat",
    fontSize: 14,
    marginBottom: 15
  },
  number: {
    height: 34,
    minWidth: "100%",
    maxWidth: "100%",
    boxSizing: "border-box",
    color: "#3d2e56",
    borderStyle: "solid",
    borderColor: "#3d2e56",
    borderWidth: 1,
    paddingLeft: 15,
    paddingRight: 15,
    fontFamily: "Montserrat",
    fontSize: 14,
    marginBottom: 15
  },
  feedbackActions: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  labelBold: {
    ...Fonts.style.labelTitle,
    marginBottom: 3
  },
  labelSmall: {
    ...Fonts.style.labelTitle,
    marginBottom: 3,
    fontSize: 12,
    fontWeight: "400",
    color: Colors.pink
  },
  alertModal: {
    overflowY: "auto",
    maxHeight: 700
  },
  estimateContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10
  },
  checkboxContainer: {
    marginRight: 10
  }
});

export default withLang(languagesHome)(observer(FeedbackComponent));
