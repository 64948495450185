import React, { Component } from "react";
import { View, StyleSheet, TouchableOpacity, Image } from "react-native";
import { withLang } from "react-multi-language";
import { runInAction } from "mobx";
import PaginatorInbox from "../../Components/PaginatorInbox";

import HeaderDropdown from "../HeaderDropdown";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import upload from "../../Images/icon-upload.png";
import settings from "../../Images/icon-settings.png";
import { auth, home, operation } from "../../Model";
import languagesHome from "../../Languages/languagesHome";
import { observer } from "mobx-react";
import inbox from "../../Images/icon-inbox.png";
import { withRouter } from "../../Navigation/withRouter";
import ReactResizeDetector from "react-resize-detector";

/**
 *
 * Footer visible if Inbox (view with Tabs) is shown
 *  Contains the menu options for the current "topic"
 *  Allows the user to click through the pages of the current tab
 *
 */
class Footer extends Component {
  componentDidMount() {
    runInAction(() => {
      home.menuDrop = false;
      home.subtopicDrop = false;
    });
  }

  onResize = (w, h) => {
    if (h > 70) {
      this.setState({ isMobile: false });
    }
  };

  renderContent = () => {
    const { langProps } = this.props;
    const isLogged = auth.loggedIn();
    const menuSettings = home.menus.map((men) => ({
      ...men,
      text: langProps[men.text] || ""
    }));
    const menuSubtopics = home.subtopics.map((men) => ({
      ...men,
      text: langProps[men.text] || ""
    }));

    return (
      <View style={[styles.container, this.props.style]}>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.onResize}
        />
        <View style={styles.content}>
          {isLogged && operation.startOn !== "inbox" && (
            <View style={[styles.navItem]} id={"subtopicdropdown"}>
              <TouchableOpacity
                onPress={() => {
                  home.subtopicDrop = !home.subtopicDrop;
                }}
              >
                <Image
                  accessibilityLabel="subtopic"
                  source={inbox}
                  resizeMode="contain"
                  style={styles.upload}
                />
              </TouchableOpacity>
              <HeaderDropdown
                isMobile={false}
                show={home.subtopicDrop}
                items={menuSubtopics}
                list
              />
            </View>
          )}
          {isLogged && (
            <View style={styles.navItem}>
              <TouchableOpacity onPress={() => this.props.navigate("/upload")}>
                <Image
                  accessibilityLabel="Upload file"
                  source={upload}
                  resizeMode="contain"
                  style={styles.upload}
                />
              </TouchableOpacity>
            </View>
          )}
          {isLogged && (
            <View style={styles.navItem} id="dropdown">
              <TouchableOpacity
                onPress={() => {
                  home.menuDrop = !home.menuDrop;
                }}
              >
                <Image
                  accessibilityLabel="Go to options"
                  source={settings}
                  resizeMode="contain"
                  style={styles.settings}
                />
              </TouchableOpacity>
              <HeaderDropdown
                isMobile={false}
                show={home.menuDrop}
                items={menuSettings}
                list
              />
            </View>
          )}
        </View>
        <View style={styles.paginator}>
          {!this.props.simple && (
            <PaginatorInbox
              initPage={this.props.initPage}
              page={this.props.page}
              step={this.props.step}
              onPage={this.props.onPage}
              items={this.props.items}
              total={this.props.total}
            />
          )}
        </View>
      </View>
    );
  };

  render() {
    return this.renderContent();
  }
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 1440,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    justifyContent: "space-between",
    position: "fixed",
    bottom: 0,
    padding: 15,
    paddingBottom: 0,
    backgroundColor: "#fff",
    left: 0,
    right: 0,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.6);"
  },
  content: {
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    width: "fit-content",
    marginBottom: "15px",
    zIndex: 2
  },
  paginator: {
    width: "fit-content",
    paddingBottom: "15px",
    zIndex: 1
  },
  navItem: {
    marginLeft: 20,
    marginRight: 15,
    position: "relative"
  },
  upload: {
    width: 26,
    height: 26
  },
  settings: {
    width: 30,
    height: 30
  },
  button: {
    width: 24,
    height: 24,
    borderRadius: 50,
    backgroundColor: Colors.border,
    marginLeft: 10
  },
  buttonText: {
    paddingHorizontal: 10,
    borderRadius: 50,
    backgroundColor: Colors.border,
    marginLeft: 10
  },
  textLoad: {
    ...Fonts.style.text,
    color: Colors.white,
    textAlign: "center",
    fontSize: 10,
    lineHeight: 24
  },
  textStart: {
    ...Fonts.style.text,
    color: Colors.border,
    textAlign: "center",
    lineHeight: 24,
    fontSize: 20,
    marginLeft: 10
  },
  textFinal: {
    ...Fonts.style.text,
    color: Colors.border,
    textAlign: "center",
    lineHeight: 24,
    fontSize: 20
  },
  active: {
    backgroundColor: Colors.purple
  },
  text: {
    width: 24,
    height: 24,
    ...Fonts.style.text,
    color: Colors.white,
    textAlign: "center",
    fontSize: 10,
    lineHeight: 24
  }
});

export default withRouter(withLang(languagesHome)(observer(Footer)));
