import React, { Component } from "react";
import { View, Text, StyleSheet, TextInput } from "react-native";
import { observer } from "mobx-react";

import Checkbox from "../Checkbox";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

class FeedsTabList extends Component {
  getRowStyle = (index) => {
    return this.isOdd(index)
      ? { backgroundColor: Colors.purpleTrans }
      : { backgroundColor: Colors.purple };
  };

  isOdd = (num) => {
    return num % 2;
  };

  render() {
    const flex01 =
      this.props.screen.w > 768 ? styles.flex01 : styles.mobileFlex;
    const flex02 =
      this.props.screen.w > 768 ? styles.flex02 : styles.mobileFlex;
    const flex03 =
      this.props.screen.w > 768 ? styles.flex03 : styles.mobileFlex;
    const flex04 =
      this.props.screen.w > 768 ? styles.flex04 : styles.mobileFlex;
    const flex10 =
      this.props.screen.w > 768 ? styles.flex10 : styles.mobileFlex;

    return (
      <View>
        {this.props.items.map((item, index) => (
          <View key={index} style={[styles.row, this.getRowStyle(index)]}>
            <View style={styles.content}>
              <View style={styles.checkboxContainer}>
                <Checkbox
                  label=""
                  checked={false}
                  onChange={(event) => console.log(event)}
                />
              </View>
              <View style={styles.formContainer}>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>fecha</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex02}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>fuente</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex02}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>tema</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>tipo</Text>
                    <TextInput style={styles.input} value={item.kind} />
                  </View>
                </View>
                <View style={flex02}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>estatus</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex02}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>ponentes</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex03}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>título</Text>
                    <TextInput style={styles.input} value={item.title} />
                  </View>
                </View>
                <View style={[styles.formGroup, flex03]}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>subtítulo</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={[styles.formGroup, flex04]}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>tema (descripción)</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={[styles.formGroup, flex10]}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>cuerpo</Text>
                    <textarea
                      value={item.text}
                      className={`management-textarea`}
                    />
                  </View>
                </View>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>reformas</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>vínculo</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>feeling</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex02}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>actores reelevantes</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>género</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex01}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>periodista</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
                <View style={flex03}>
                  <View style={styles.inner}>
                    <Text style={styles.label}>argumento</Text>
                    <TextInput style={styles.input} />
                  </View>
                </View>
              </View>
            </View>
          </View>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  row: {
    paddingHorizontal: 50,
    paddingTop: 50,
    paddingBottom: 30
  },
  inner: {
    paddingHorizontal: 10
  },
  formGroup: {
    /*paddingHorizontal: 10*/
  },
  flex01: {
    width: 129.5
  },
  flex02: {
    width: 259
  },
  flex03: {
    width: 388.5
  },
  flex04: {
    width: 518
  },
  flex10: {
    width: "99.75%"
  },
  mobileFlex: {
    width: "100%"
  },
  label: {
    ...Fonts.style.labelTitle,
    color: Colors.white
  },
  input: {
    ...Fonts.style.input,
    fontSize: 12,
    color: Colors.white,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: Colors.white,
    height: 34,
    marginBottom: 10,
    paddingHorizontal: 15
  },
  formContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap"
  },
  checkboxContainer: {
    width: 37.5,
    justifyContent: "center",
    alignItems: "flex-start"
  }
});

export default observer(FeedsTabList);
