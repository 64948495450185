import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable, hydrateStore } from "mobx-persist-store";
import { api, auth, endpoints } from "../../Model";

export default class KeywordsModel {
  showConfirm = false;
  loading = false;
  id = 0;
  value = "";
  strict = "";
  similar = "";
  distance = "";
  noContain = "";
  sendAlert = false;
  alert = "";
  priority = "";
  startOn = "";

  advanced = false;
  yourKeywords = true;
  manyWords = false;
  loadingAutocomplete = false;
  requestTimer = null;

  keywords = [];
  ddls = {};
  topics = [];
  topic = "";
  ordersDdl = {};
  showFiltersDdl = {};

  similars = [];
  distances = ["same sentences", "same paragraph"];
  alerts = ["sms", "push", "mail"];
  priorities = ["high", "medium", "low"];

  keywordSelected = [];
  keywordSuggestion = [];

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "KeywordsModel",
      properties: ["keywords"],
      storage: localStorage
    }).then(() => {
      return hydrateStore(this);
    });
  }

  get hasKeyword() {
    return this.value.length > 0 && this.strict !== "";
  }

  getData = () => {
    try {
      const currentTopic = localStorage.getItem("Topic");
      let response = true;
      this.loading = true;

      return new Promise(async (resolve) => {
        const dataKeywords = await endpoints.getKeywords();
        if (
          dataKeywords &&
          (dataKeywords.statusCode === "400" ||
            dataKeywords.statusCode === "403" ||
            dataKeywords.data.statusCode === "403")
        ) {
          response = false;
        } else {
          this.topics = dataKeywords.data.body.topics.sort();
          this.topic =
            currentTopic &&
            currentTopic.length > 0 &&
            this.topics.includes(currentTopic)
              ? currentTopic
              : this.topics[0];
          localStorage.setItem("Topic", this.topic);
          this.startOn = dataKeywords.data.body.start_on;
          this.ordersDdl = dataKeywords.data.body.order_ddl;
          this.showFiltersDdl = dataKeywords.data.body.show_filters_ddl;
          //this.keywords = this.generateKeywords(data.body.topics)
          this.ddls = this.generateDdls(dataKeywords.data.body);
          this.loading = false;
        }

        resolve(response);
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  getSimilars = async (keyword) => {
    try {
      this.loading = true;
      const { data } = await api.getSimilars(keyword);
      runInAction(() => {
        this.similars = data.body.similar_keywords;
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  generateKeywords = (topics) => {
    var list = [];
    for (var i in topics) {
      const keywords = topics[i];
      for (var j in keywords) {
        const data = keywords[j];
        list.push({
          value: data.keyword,
          settings: {
            strict: data.search_type,
            distance: data.distance,
            noContain: data.should_not
              ? this.getStrByArrValues(data.should_not)
              : "",
            similar: data.similar_kw ? data.similar_kw[0] : "",
            sendAlert: data.alert.length > 0 ? true : false,
            alert: this.getStrByArrValues(data.alert),
            priority: data.priority
          }
        });
        this.keywordSuggestion.push({
          id: parseInt(j) + 1,
          name: data.keyword,
          disabled: false,
          topic: i
        });
      }
    }

    return list;
  };

  get getKeywordSuggestion() {
    return this.keywordSuggestion.filter(
      (keyword) =>
        keyword.topic === this.topic && !this.isKeywordSelected(keyword.id)
    );
  }

  get getKeywordSelected() {
    return this.keywordSelected.filter((keyword) => true);
  }

  isKeywordSelected = (id) => {
    let validate = false;
    this.keywordSelected.map((keyword, i) => {
      if (keyword.id === id) validate = true;
    });

    return validate;
  };

  generateDdls = (data) => {
    return {
      authors: data.author_ddl,
      sources: data.source_ddl,
      impacts: data.impact_ddl
    };
  };

  getDdlByTypeAndTopic = (type, topic) => {
    return this.ddls[type][topic];
  };

  delete = () => {
    this.deleteKeyword(this.keywords[this.id].value);
    this.keywords.splice(this.id, 1);
  };

  add = () => {
    var newKeyword = this.getDefaultSettings();
    newKeyword.value = this.value;
    newKeyword.settings.strict = this.strict;

    this.insertKeyword(newKeyword);

    this.yourKeywords = true;
    this.keywords.push(newKeyword);
    this.reset();
  };

  insertKeyword = async (keyword) => {
    try {
      this.loading = true;
      await api.setKeyword({
        Authorization: `${auth.getToken()}`,
        action: "insert",
        keyword: keyword.value,
        search_type: keyword.settings.strict,
        topic: this.topic,
        user_info: auth.getProfile()
      });
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  deleteKeyword = async (keyword) => {
    try {
      this.loading = true;
      const payload = {
        Authorization: `${auth.getToken()}`,
        action: "delete",
        keyword: keyword,
        topic: this.topic
      };
      await api.setKeyword(payload);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  addAdvanced = () => {
    var newKeyword = this.getDefaultSettings();
    newKeyword.value = this.value;
    newKeyword.settings.strict = this.strict;

    newKeyword.settings.distance = this.distance;
    newKeyword.settings.noContain = this.noContain;
    newKeyword.settings.similar = this.similar;
    newKeyword.settings.sendAlert = this.sendAlert;
    newKeyword.settings.alert = this.alert;
    newKeyword.settings.priority = this.priority;

    this.insertKeywordAdvanced(newKeyword);

    this.yourKeywords = true;
    this.keywords.push(newKeyword);
    this.reset();
  };

  insertKeywordAdvanced = async (keyword) => {
    try {
      this.loading = true;

      var payload = {
        Authorization: `${auth.getToken()}`,
        action: "insert",
        keyword: keyword.value,
        search_type: keyword.settings.strict,
        user_info: auth.getProfile(),
        topic: this.topic
      };

      if (keyword.settings.noContain.length > 0)
        payload["should_not"] = this.splitStrBySpace(
          keyword.settings.noContain
        );
      if (keyword.settings.distance.length > 0)
        payload["distance"] = keyword.settings.distance;
      if (keyword.settings.sendAlert)
        payload["alert"] = [keyword.settings.alert];
      if (keyword.settings.similar.length > 0)
        payload["similar_kw"] = [keyword.settings.similar];
      if (keyword.settings.priority.length > 0)
        payload["priority"] = keyword.settings.priority;

      await api.setKeyword(payload);
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  };

  splitStrBySpace = (str) => {
    return str.split(" ");
  };

  getStrByArrValues = (arr) => {
    var str = "";
    for (var i in arr) {
      str += " ";
      str += arr[i];
    }

    return str;
  };

  setValue = (word) => {
    this.value = word;
    this.manyWords = this.value.includes(" ") ? true : false;
    this.getSimilars(this.value);
  };

  setStrict = (type) => {
    this.strict = type;
  };

  setPriority = (type) => {
    this.priority = type;
  };

  reset = () => {
    this.value = "";
    this.strict = "";

    if (this.advanced) {
      this.similar = "";
      this.distance = "";
      this.noContain = "";
      this.sendAlert = false;
      this.alert = "";
      this.priority = "";
      this.manyWords = false;
    }
  };

  getDefaultSettings = () => {
    return {
      value: "",
      settings: {
        strict: "",
        distance: "same sentences",
        noContain: "",
        similar: "",
        sendAlert: false,
        alert: "",
        priority: ""
      }
    };
  };
}
