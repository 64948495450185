import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";
import Slider from "react-slick";

import Button from "../../Components/Button";
import Loading from "../../Components/Loading";
import Container from "../../Components/Container";
import Paginator from "../../Components/Paginator";
import UnderlinedText from "../../Components/UnderlinedText";
import AlertItem from "../../Components/AlertItem";
import ArrowSlider from "../ArrowSlider";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import rightArrow from "../../Images/arrow-right-white.png";
import leftArrow from "../../Images/arrow-left-white.png";
import { alba, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";
import { withRouter } from "../../Navigation/withRouter";

var settings = {
  customPaging: function (i) {
    return <button>{i}</button>;
  },
  arrows: true,
  dots: false,
  infinite: false,
  swipeToSlide: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: "",
  prevArrow: "",
  adaptiveHeight: true
};

class FlowList extends Component {
  UNSAFE_componentWillMount() {
    const _this = this;
    const nextArrow = (
      <ArrowSlider
        class="slick-arrow slick-next"
        onPress={_this.onNext}
        source={rightArrow}
      />
    );
    const prevArrow = (
      <ArrowSlider
        class="slick-arrow slick-prev disabled"
        onPress={_this.onPrev}
        source={leftArrow}
      />
    );
    settings.nextArrow = nextArrow;
    settings.prevArrow = prevArrow;
    settings.beforeChange = function (current, next) {
      alba.config.feed.page = next;
      _this.updateArrows();
    };
  }

  getWraptStyle = () => {
    if (this.props.screen.w <= 768) return styles.wrapSm;
    else if (this.props.screen.w <= 1024) return styles.wrapMd;
    else return {};
  };
  onPrev = () => {
    this.slider.slickPrev();
  };

  onNext = () => {
    this.slider.slickNext();
  };

  onPage = (page) => {
    this.slider.slickGoTo(page);
  };

  onLoadFeeds = (isMore) => {
    alba.loadMoreFeeds(isMore);
  };

  updateArrows = () => {
    const nextArrow = (
      <ArrowSlider
        class={`slick-arrow slick-next ${
          alba.config.feed.page >= alba.feeds.length - 1 ? "disabled" : ""
        }`}
        onPress={this.onNext}
        source={rightArrow}
      />
    );
    const prevArrow = (
      <ArrowSlider
        class={`slick-arrow slick-prev ${
          alba.config.feed.page <= 0 ? "disabled" : ""
        }`}
        onPress={this.onPrev}
        source={leftArrow}
      />
    );
    settings.nextArrow = nextArrow;
    settings.prevArrow = prevArrow;
  };

  renderContent = () => {
    const { langProps } = this.props;
    if (alba.feeds.length > 0) {
      return (
        <View style={styles.container} id={`feedSection`}>
          <UnderlinedText
            upper
            center
            style={styles.title}
            text={langProps.feeds || ""}
            place={1}
            color={Colors.text}
          />
          <Container style={this.getWraptStyle()}>
            {alba.feeds.length > 0 && (
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                {alba.feeds.map((item, index) => (
                  <div key={index}>
                    <AlertItem
                      feed
                      data={item}
                      screen={this.props.screen}
                      lang={langProps}
                    />
                  </div>
                ))}
              </Slider>
            )}
            <Paginator
              style={styles.paginator}
              initPage={alba.config.feed.initPage}
              page={alba.config.feed.page}
              step={alba.config.feed.step}
              onPrev={this.onPrev}
              onNext={this.onNext}
              onPage={this.onPage}
              items={alba.feeds}
              selectedColor={Colors.pink}
              total={alba.totalFeeds}
              loadMore={() => this.onLoadFeeds(true)}
              loadLess={() => this.onLoadFeeds(false)}
            />
          </Container>
          <View style={styles.btnContainer}>
            <View style={styles.btnContent}>
              <Button
                upper
                title={langProps.view_all || ""}
                background={Colors.pink}
                onPress={() => this.props.navigate("/list/feeds")}
              />
            </View>
          </View>
          <MultiLang lang={home.language} />
        </View>
      );
    }

    return null;
  };

  render() {
    if (alba.loadingFeeds) {
      return (
        <View style={styles.loader}>
          <Loading color={Colors.yellow} small />
        </View>
      );
    }

    return this.renderContent();
  }
}

const styles = StyleSheet.create({
  loader: {
    minHeight: 528
  },
  container: {
    backgroundColor: Colors.purple
  },
  wrapMd: {
    paddingHorizontal: 45
  },
  wrapSm: {
    paddingHorizontal: 15
  },
  title: {
    ...Fonts.style.underline,
    fontSize: 26
  },
  paginator: {
    position: "absolute",
    bottom: 15,
    right: 15
  },
  btnContainer: {
    display: "block"
  },
  btnContent: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1024,
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15
  }
});

export default withRouter(withLang(languagesHome)(observer(FlowList)));
