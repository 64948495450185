import React, { Component } from "react";
import {
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity
} from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import DatePicker from "../../Components/DatePicker";
import Button from "../../Components/Button";
import Select from "../../Components/Select";
import Checkbox from "../../Components/Checkbox";
import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { operation, home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

let dateFormat = require("dateformat");
const nowDate = new Date();

class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedFrom: false,
      dateFrom: null,
      focusedTo: false,
      dateTo: null,
      sidebarOpen: false
    };
  }

  onDateFromChange = (day, { disabled }) => {
    if (!disabled) {
      home.dateFrom = day;
      home.dateTo = day;
      this.setState({ focusedFrom: false, focusedTo: true });
    }
  };

  onDateToChange = (day, { disabled }) => {
    if (!disabled) {
      home.dateTo = day;
      this.setState({ focusedFrom: false, focusedTo: false });
    }
  };

  onFocusedDate = (type) => {
    if (type === "from")
      this.setState({ focusedFrom: !this.state.focusedFrom, focusedTo: false });
    else
      this.setState({ focusedFrom: false, focusedTo: !this.state.focusedTo });
  };

  onApply = () => {
    if (this.props.onApply) {
      this.props.onApply();
    } else {
      home.apply();
    }
  };

  deleteKeyword = (index) => {
    operation.keywordSelected.splice(index, 1);
    operation.keywordSelected = operation.keywordSelected.slice();
  };

  addKeyword = (keyword) => {
    if (!operation.isKeywordSelected(keyword.id)) {
      operation.keywordSelected.push(keyword);
      operation.keywordSelected = operation.keywordSelected.slice();
    }
  };

  reset = () => {
    this.setState({ keywords: [] }, () => {
      home.reset();
    });
  };

  downloadExcel = () => {
    if (this.props.onExportExcel) {
      this.props.onExportExcel();
    } else {
      window.open(home.urlExcel, "_blank");
    }
  };

  exportAllExcel = () => {
    home.exportAllExcel();
  };

  setSearch = (value) => {
    home.search = value;
    home.searched = false;
  };

  showFilter = (filter) => {
    if (home.getShowFilters && home.getShowFilters.length > 0) {
      const isAll = home.getShowFilters.includes("all");
      return isAll ? true : home.getShowFilters.includes(filter);
    }
    return false;
  };

  render() {
    const { langProps } = this.props;
    const authors = operation.ddls.authors
      ? operation.getDdlByTypeAndTopic("authors", operation.topic)
      : [];
    const impacts = operation.ddls.impacts
      ? operation.getDdlByTypeAndTopic("impacts", operation.topic)
      : [];
    const sources = operation.ddls.sources
      ? operation.getDdlByTypeAndTopic("sources", operation.topic)
      : [];

    return (
      <View
        style={[styles.wrap, this.state.sidebarOpen ? styles.wrapOpen : {}]}
      >
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.filter}
            className={`filterBtn`}
            onPress={() =>
              this.setState({ sidebarOpen: !this.state.sidebarOpen })
            }
          >
            <Text style={styles.filterText}>{langProps.filters || ""}</Text>
          </TouchableOpacity>
          <View style={styles.scroll}>
            <View style={styles.btnContainer}>
              <Button
                upper
                title={langProps.clear || ""}
                background={Colors.yellow}
                onPress={this.reset}
              />
            </View>
            <View className="layout-back">
              <Text style={styles.labelBold}>{langProps.order_by || ""}</Text>
              <Select
                defaultText={`none`}
                value={home.orderBy}
                options={home.getOrders}
                onChange={(event) => (home.orderBy = event.target.value)}
              />
            </View>
            <View className="layout-back">
              <Text style={styles.labelBold}>{langProps.search || ""}</Text>
              <TextInput
                style={styles.input}
                value={home.search}
                onChangeText={(value) => this.setSearch(value)}
              />
            </View>

            <View className="layout-back">
              <View style={styles.filterByDate}>
                <View style={styles.checkboxContainer}>
                  <Checkbox
                    label=""
                    className="purple"
                    checked={home.independents}
                    onChange={(event) =>
                      (home.independents = !home.independents)
                    }
                  />
                </View>
                <Text style={styles.labelBold}>
                  {langProps.independent_words || ""}
                </Text>
              </View>
            </View>

            <View className="layout-back">
              {this.showFilter("date") && (
                <View style={styles.filterByDate}>
                  <View style={styles.checkboxContainer}>
                    <Checkbox
                      label=""
                      className="purple"
                      checked={home.filterByDate}
                      onChange={(event) =>
                        (home.filterByDate = !home.filterByDate)
                      }
                    />
                  </View>
                  <Text style={styles.labelBold}>
                    {langProps.filter_by_date || ""}
                  </Text>
                </View>
              )}
              {home.filterByDate && (
                <View>
                  <Text style={styles.label}>{langProps.from || ""}</Text>
                  <TextInput
                    style={styles.input}
                    value={dateFormat(home.dateFrom, "yyyy-mm-dd")}
                    onFocus={() => this.onFocusedDate("from")}
                  />
                </View>
              )}
            </View>

            {home.filterByDate && (
              <View>
                <View>
                  <DatePicker
                    date={home.dateFrom}
                    focused={this.state.focusedFrom}
                    onDateChange={this.onDateFromChange}
                    onClose={() =>
                      this.setState({ focusedFrom: !this.state.focusedFrom })
                    }
                    disabledDays={[{ after: nowDate }]}
                  />
                </View>
                <View className="layout-back">
                  <Text style={styles.label}>{langProps.to || ""}</Text>
                  <TextInput
                    style={styles.input}
                    value={dateFormat(home.dateTo, "yyyy-mm-dd")}
                    onFocus={() => this.onFocusedDate("to")}
                  />
                </View>
                <View>
                  <DatePicker
                    date={home.dateTo}
                    focused={this.state.focusedTo}
                    onDateChange={this.onDateToChange}
                    onClose={() =>
                      this.setState({ focusedTo: !this.state.focusedTo })
                    }
                    disabledDays={[{ before: home.dateTo, after: nowDate }]}
                  />
                </View>
              </View>
            )}

            <View className="layout-back">
              {this.showFilter("amount") && (
                <View style={styles.filterByDate}>
                  <View style={styles.checkboxContainer}>
                    <Checkbox
                      label=""
                      className="purple"
                      checked={home.filterByAmount}
                      onChange={(event) =>
                        (home.filterByAmount = !home.filterByAmount)
                      }
                    />
                  </View>
                  <Text style={styles.labelBold}>
                    {langProps.filter_by_amount || ""}
                  </Text>
                </View>
              )}
              {home.filterByAmount && (
                <View>
                  <View className="layout-back">
                    <Text style={styles.label}>{langProps.from || ""}</Text>
                    <TextInput
                      style={styles.input}
                      value={home.amountFrom}
                      onChangeText={(value) => (home.amountFrom = value)}
                    />
                  </View>
                  <View className="layout-back">
                    <Text style={styles.label}>{langProps.to || ""}</Text>
                    <TextInput
                      style={styles.input}
                      value={home.amountTo}
                      onChangeText={(value) => (home.amountTo = value)}
                    />
                  </View>
                </View>
              )}
            </View>

            {/* {this.showFilter('keywords') && (
              <View className="layout-back">
                <Text style={styles.labelBold}>filter by keywords</Text>
              </View>
            )}
            {this.showFilter('keywords') && (
              <View className="keywords-tag layout-back-minus">
                {operation.keywordSuggestion !== undefined && (
                  <ReactTags
                    autofocus={false}
                    placeholder="search keywords"
                    minQueryLength={0}
                    tags={operation.getKeywordSelected}
                    suggestions={operation.getKeywordSuggestion}
                    handleDelete={this.deleteKeyword}
                    handleAddition={this.addKeyword}
                  />
                )}
              </View>
            )} */}

            {this.showFilter("impact") && (
              <View className="layout-back">
                <Text style={styles.labelBold}>
                  {langProps.filter_by_impact || ""}
                </Text>
                <Select
                  defaultText={langProps.all_impacts || ""}
                  value={home.impact}
                  options={impacts}
                  onChange={(event) => (home.impact = event.target.value)}
                />
              </View>
            )}

            {this.showFilter("source") && (
              <View className="layout-back">
                <Text style={styles.labelBold}>
                  {langProps.filter_by_source || ""}
                </Text>
                <Select
                  defaultText={langProps.all_sources || ""}
                  value={home.source}
                  options={sources}
                  onChange={(event) => (home.source = event.target.value)}
                />
              </View>
            )}

            {this.showFilter("author") && (
              <View className="layout-back">
                <Text style={styles.labelBold}>
                  {langProps.filter_by_author || ""}
                </Text>
                <Select
                  defaultText={langProps.all_authors || ""}
                  value={home.author}
                  options={authors}
                  onChange={(event) => (home.author = event.target.value)}
                />
              </View>
            )}

            <View style={styles.btnContainer} className="layout-back">
              <Button
                upper
                title={langProps.apply || ""}
                background={Colors.pink}
                onPress={() => this.onApply()}
              />
            </View>
            {home.showExport && (
              <View style={styles.btnContainer}>
                <Button
                  upper
                  title={langProps.export_to_excel || ""}
                  background={Colors.green}
                  onPress={() => this.downloadExcel()}
                />
              </View>
            )}
            <View style={styles.btnContainer}>
              <Button
                upper
                title={langProps.export_all_to_excel || ""}
                background={Colors.green}
                onPress={() => this.exportAllExcel()}
              />
            </View>
          </View>
        </View>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrap: {
    position: "fixed",
    top: 76,
    height: "100%",
    right: -400,
    boxShadow: "5px 0px 0px rgba(0,0,0,0.6)"
  },
  wrapOpen: {
    right: 0,
    boxShadow: "5px 0px 10px rgba(0,0,0,0.6)"
  },
  container: {
    flex: 1,
    flexDirection: "column",
    width: 400,
    backgroundColor: Colors.sidebar,
    height: "100%",
    position: "relative"
  },
  scroll: {
    height: "100%",
    padding: 25,
    paddingBottom: 76,
    overflowY: "auto"
  },
  btnContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 10
  },
  labelBold: {
    ...Fonts.style.labelTitle,
    marginBottom: 3
  },
  label: {
    ...Fonts.style.label,
    marginBottom: 3
  },
  input: {
    ...Fonts.style.input,
    borderWidth: 1,
    borderColor: Colors.purple,
    height: 34,
    marginBottom: 15,
    paddingHorizontal: 15
  },
  filter: {
    position: "absolute",
    zIndex: 99999,
    top: 39,
    left: -84,
    backgroundColor: Colors.yellow,
    transform: [{ rotate: "-90deg" }],
    paddingTop: 15,
    paddingBottom: 12,
    paddingHorizontal: 22
  },
  filterText: {
    ...Fonts.style.text,
    color: Colors.white,
    letterSpacing: 3,
    fontWeight: "300",
    textTransform: "uppercase"
  },
  filterByDate: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 10
  },
  checkboxContainer: {
    marginRight: 10
  }
});

export default withLang(languagesHome)(observer(SidebarContent));
