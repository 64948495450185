import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'

class Container extends Component {
  render () {
    const fullStyle = this.props.full ? { maxWidth: '100%' } : {}
    return <View style={[styles.container, this.props.style, fullStyle]}>{this.props.children}</View>
  }
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 1024,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingHorizontal: 15
  }
})

export default Container
