import React, { Component } from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { observer } from "mobx-react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip
} from "recharts";

import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";

import { alba } from "../../Model";

class BarChartComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "day",
      data: {}
    };
  }

  componentDidMount() {
    var data = {};
    data["title"] = this.props.data.title;
    data["tabs"] = [];
    this.props.data.plot_data.map((plot, i) => {
      const key = plot.tab_title.replace(/ /g, "_");
      data["tabs"].push({ key, name: plot.tab_title });
      data[key] = [];
      plot.tab_data.map((_data, j) => {
        let __data = _data;
        __data["day"] = j + 1;
        data[key].push(__data);
      });
    });
    this.setState({ tab: data.tabs[0].key, data });
  }

  setTab = (tab) => {
    this.setState({ tab });
  };

  renderTooltip(props) {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <View style={styles.tooltip}>
          <Text style={[{ ...Fonts.style.smallText }, { marginVertical: 10 }]}>
            Llamada: {data.day}
          </Text>
          <Text style={{ ...Fonts.style.smallBoldText }}>
            Palabras sensibles: {data.call_num}
          </Text>
        </View>
      );
    }

    return null;
  }

  renderContent = () => {
    if (alba.loading) {
      return <View />;
    }

    const fills = [
      Colors.purple,
      Colors.pink,
      Colors.yellow,
      Colors.blue,
      Colors.green,
      Colors.red,
      Colors.text
    ];

    return (
      <ResponsiveContainer className="bar-chart-component">
        <BarChart data={this.state.data[this.state.tab]}>
          <XAxis dataKey="day" />
          <YAxis
            label={{
              value: "no. de palabras sensibles",
              angle: -90,
              position: "insideBottomLeft"
            }}
          />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ zIndex: 100 }}
            content={this.renderTooltip}
          />
          <Bar
            onClick={this.props.onClickAlert}
            dataKey="call_num"
            fill="#3f2e56"
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        {this.state.data.tabs && this.state.data.tabs.length > 0 && (
          <View style={styles.tabs}>
            {this.state.data.tabs.map((tab, index) => (
              <TouchableOpacity
                key={index}
                onPress={() => this.setTab(tab.key)}
                style={[
                  styles.tab,
                  this.state.tab === tab.key && styles.tabSelected
                ]}
              >
                <Text style={styles.tabText}>{tab.name.trim()}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
        <Text style={styles.subtitle}>{this.state.data.title}</Text>
        <View style={styles.content}>{this.renderContent()}</View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    height: 306
  },
  tabs: {
    flexDirection: "row",
    marginBottom: 15
  },
  tab: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    padding: 15,
    //backgroundColor: '#e2e2e2',
    borderBottomWidth: 1,
    borderBottomColor: "#ccc"
  },
  tabSelected: {
    borderWidth: 1,
    borderColor: "#ccc"
    //backgroundColor: '#aaa'
  },
  tabText: {
    color: "#000"
  },
  subtitle: {
    ...Fonts.style.labelTitle,
    marginBottom: 45
  },
  tooltip: {
    backgroundColor: Colors.white,
    borderColor: "#999",
    borderWidth: 1,
    margin: 0,
    padding: 10,
    maxWidth: 200,
    zIndex: 9999
  }
});

export default observer(BarChartComponent);
