import React, { Component } from 'react'
import { StyleSheet, View, Text } from 'react-native'

import Fonts from '../../Themes/Fonts'

class UnderlinedText extends Component {
  generateText = (place, text) => {
    return {
      first: text.slice(0, place),
      second: text.slice(place, text.length)
    }
  }

  render () {
    const { text, place, center, color, classFontName, style, upper } = this.props
    const _text = this.generateText(place, text)
    return (
      <View style={[styles.container, center ? { justifyContent: 'center' } : {}]}>
        <View style={[styles.underlined, { borderBottomColor: color }]}>
          <Text className={classFontName} style={[styles.text, upper && styles.uppercase, style]}>
            {_text.first}
          </Text>
        </View>
        <View>
          <Text className={classFontName} style={[styles.text, upper && styles.uppercase, style]}>
            {_text.second}
          </Text>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row'
  },
  text: {
    ...Fonts.style.underline
  },
  underlined: {
    borderBottomWidth: 2,
    borderBottomColor: 'transparent'
  },
  uppercase: {
    textTransform: 'uppercase'
  }
})

export default UnderlinedText
