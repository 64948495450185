import React, { Component } from "react";
import { StyleSheet, View, Text } from "react-native";
import { observer } from "mobx-react";
import { MultiLang, withLang } from "react-multi-language";

import ImageResponsive from "../../Components/ImageResponsive";
import Container from "../../Components/Container";
import UnderlinedText from "../../Components/UnderlinedText";
import Fonts from "../../Themes/Fonts";
import Colors from "../../Themes/Colors";
import background from "../../Images/home-screen.jpg";

import { home } from "../../Model";
import languagesHome from "../../Languages/languagesHome";

/**
 * This component represents the welcome screen shown at the top of the login page.
 */

class HomeScreen extends Component {
  getBackgroundStyle = () => {
    if (this.props.screen.w <= 768) return styles.backgroundSm;
    else return {};
  };

  reduceFontText = () => {
    if (this.props.screen.w <= 768) return styles.textSm;
    else return {};
  };

  reduceFontWelcome = () => {
    if (this.props.screen.w <= 768) return styles.welcomeSm;
    else return {};
  };

  render() {
    const { langProps } = this.props;
    return (
      <View style={styles.container}>
        <ImageResponsive
          source={background}
          label={langProps.welcome || ""}
          style={{
            container: this.getBackgroundStyle(),
            mask: { paddingBottom: "44.45%" },
            className: "homescreen-bg",
            resizeMode: "cover"
          }}
        />
        <View style={styles.innerContainer}>
          <Container>
            {/* <Text style={[styles.welcome, this.reduceFontText()]}>BIENVENIDO</Text> */}
            <View style={styles.space} />
            <UnderlinedText
              upper
              style={[styles.welcomePurple, this.reduceFontWelcome()]}
              text={langProps.welcome || ""}
              place={1}
              color={Colors.purple}
            />
            <View style={styles.space} />
            <View style={styles.space} />
            <Text style={[styles.text, this.reduceFontText()]}>
              {langProps.alba_provides || ""}
            </Text>
            <View style={styles.space} />
          </Container>
        </View>
        <MultiLang lang={home.language} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    marginTop: 76,
    height: "auto"
  },
  background: {
    minHeight: 640
  },
  backgroundSm: {
    minHeight: 480
  },
  innerContainer: {
    position: "absolute",
    flex: 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "transparent",
    justifyContent: "center"
  },
  space: {
    height: 25
  },
  welcome: {
    ...Fonts.style.navBar,
    color: Colors.text,
    fontSize: 24
  },
  welcomeSm: {
    fontSize: 30
  },
  text: {
    ...Fonts.style.text
  },
  textSm: {
    fontSize: 14
  },
  welcomePurple: {
    fontSize: 52,
    color: Colors.purple
  }
});

export default withLang(languagesHome)(observer(HomeScreen));
